import { readContract } from '@wagmi/core';
import { ethers } from 'ethers';
import { formatUnits } from 'viem';
import { useBalance } from 'wagmi';
import { contractLRTAbi } from '@/utils/contractLRTAbi';
import { contractUSDTAbi } from '../contractUSDTAbi';
import { contractLRTToken, contractUSDC, contractUSDT } from '../main_contracts';

interface IBalanceLRT {
  address: string;
}

// Define the interface for the function props
interface IBalanceMATIC {
  address: string;
}

export const BalanceLRT = async (props: IBalanceLRT) => {
  const clientConfig = await import('@/utils/WagmiConfig').then((mod) => mod.clientConfig);

  const { address } = props;
  return readContract(clientConfig, {
    address: contractLRTToken,
    abi: contractLRTAbi,
    functionName: 'balanceOf',
    args: [address],
  }).then((res: bigint) => formatUnits(res, 18));
};
export const BalanceUSDC = async (props: IBalanceLRT) => {
  const clientConfig = await import('@/utils/WagmiConfig').then((mod) => mod.clientConfig);

  const { address } = props;
  return readContract(clientConfig, {
    address: contractUSDC,
    abi: contractUSDTAbi,
    functionName: 'balanceOf',
    args: [address],
  }).then((res: bigint) => formatUnits(res, 6));
};
export const BalanceUSDT = async (props: IBalanceLRT) => {
  const clientConfig = await import('@/utils/WagmiConfig').then((mod) => mod.clientConfig);

  const { address } = props;
  return readContract(clientConfig, {
    address: contractUSDT,
    abi: contractUSDTAbi,
    functionName: 'balanceOf',
    args: [address],
  }).then((res: bigint) => formatUnits(res, 6));
};

export const BalanceMATIC = async (props: IBalanceMATIC) => {
  const { address } = props;
  // const provider = new ethers.providers.JsonRpcProvider('https://polygon-rpc.com');
  //   const { data, error, isLoading } = useBalance({
  //     addressOrName: address,
  //     formatUnits: 'ether', // Default is 18 decimals
  //     chainId: 137, // Polygon Mainnet chain ID
  //   });
  //  console.log('data?.formatted',data?.formatted);

  // try {
  //   const balance = await provider.getBalance(address);
  //   return ethers.utils.formatEther(balance); // MATIC uses 18 decimals, same as ETH
  // } catch (error) {
  //   console.error('Error fetching MATIC balance:', error);
  //   throw error;
  // }
};
// export const BalanceMATIC = async (props: IBalanceMATIC) => {
//   // const { address } = props;
//   // const provider = new ethers.providers.JsonRpcProvider('https://polygon-rpc.com');

//   // try {
//   //   const balance = await provider.getBalance(address);
//   //   return ethers.utils.formatEther(balance); // MATIC uses 18 decimals, same as ETH
//   // } catch (error) {
//   //   console.error('Error fetching MATIC balance:', error);
//   //   throw error;
//   // }
// };
