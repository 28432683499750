import React, { FC } from 'react';
import { Modal } from '@mantine/core';
import cn from 'classnames';
import { Button, Typography } from '@/components/Atoms';
import CrossSvg from '@/components/Svg/CrossSvg';
import { useStore } from '@/zustand/store/store';
import styles from './ConfirmModal.module.scss';

type ConfirmTypes = 'success' | 'warning' | 'error';

interface ComponentProps {
  title: string;
  confirmTypes: ConfirmTypes;
  description?: string;
  opened: boolean;
  isLoading?: boolean;
  closeAction: () => void;
  closeButtonText: string;
  cta?: () => void | null | undefined;
  ctaButtonText: string;
  headerCloseButton?: boolean;
}
const statusColors = {
  warning: 'bg-yellow-500',
  error: '#FB3C00',
  success: 'green',
};

const ConfirmModal: FC<ComponentProps> = ({
  closeAction,
  closeButtonText,
  opened,
  title,
  confirmTypes,
  description,
  cta,
  ctaButtonText,
  isLoading = false,
  headerCloseButton = false,
}) => {
  const { setCurrentClaimModal, currentClaimModal } = useStore();

  return (
    <Modal
      centered
      withCloseButton={false}
      styles={{
        body: { backgroundColor: '#1C1D1E', maxHeight: '90%!important', borderRadius: '14px', border: '1px solid #464646' },
        // @ts-ignore
        content: { backgroundColor: 'transparent', overflowY: 'visible!important' },
        overlay: { zIndex: 86 },
        inner: { zIndex: 87 },
      }}
      opened={opened}
      onClose={closeAction}
      size={'auto'}
      overlayProps={{
        opacity: 0.55,
        blur: 3,
      }}
      classNames={{ body: 'max-w-[648px] relative' }}
      className='relative'
    >
      <div className={styles.wrapper}>
        {headerCloseButton && (
          <div className={styles.headerCloseButton}>
            <div onClick={closeAction} className={styles.closeButton}>
              <CrossSvg />
            </div>
          </div>
        )}
        <div className={styles.modalContent}>
          <Typography
            className={cn(styles.title, {
              'text-[#FB3C00]': confirmTypes === 'error',
              'text-orange-600': confirmTypes === 'warning',
              'text-green-600': confirmTypes === 'success',
            })}
          >
            {title}
          </Typography>
          <Typography className={styles.description}>{description}</Typography>
          <div className={styles.buttonContainer}>
            {closeAction && (
              <Button
                // isLoading={isLoading}
                onClick={closeAction}
                label={closeButtonText}
                className={`${styles.button} bg-[#a8a8a825] border border-[c2c2c2] `}
              />
            )}
            {cta && (
              <Button
                isLoading={isLoading}
                onClick={cta}
                label={ctaButtonText}
                className={cn(styles.button, {
                  'bg-[#fb3b002a] bnorder border-[#FB3C00] ': confirmTypes === 'error',
                  'bg-orange-600 bnorder border-[#FB9700] ': confirmTypes === 'warning',
                  'bg-green-600 bnorder border-[#19FB00] ': confirmTypes === 'success',
                })}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
