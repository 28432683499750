/**
 * Checks if WebGL 1 is supported.
 * @returns {boolean} True if WebGL 1 is supported, else false.
 */
const isWebGL1Supported = (): boolean => {
  try {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
  } catch (e) {
    return false;
  }
};

/**
 * Checks if WebGL 2 is supported.
 * @returns {boolean} True if WebGL 2 is supported, else false.
 */
const isWebGL2Supported = (): boolean => {
  try {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    return !!(canvas.getContext('webgl2') || canvas.getContext('experimental-webgl2'));
  } catch (e) {
    return false;
  }
};

/**
 * Checks for WebGL 1 and WebGL 2 support.
 * @returns { { webgl1: boolean; webgl2: boolean; } } An object indicating support for WebGL1 and WebGL2.
 */
export const detectWebGLSupport = (): { webgl1: boolean; webgl2: boolean } => ({ webgl1: isWebGL1Supported(), webgl2: isWebGL2Supported() });
