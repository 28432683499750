import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { IGetOverallItem, IUserAccount } from '@/types/DTO/miniGame';
import { IUniverseData, IUserMaterials } from '@/types/lite-game';
import { I2DGameSlice } from './slices/Interface';
import { ICoordinateData, IPlanetData, IRoverData, IStartMiningData } from './slices/lite-game/Interface';

export const useStore = create<I2DGameSlice>()(
  persist(
    (set) => ({
      step: 0,
      selectedUniverse: null,
      selectedPlanet: null,
      selectedCoordinate: null,
      selectedRover: null,
      isStartMiningBoxVisible: false,
      isOpenFuelModal: false,
      userData: null,
      startMiningData: null,
      finishMiningData: null,
      isProcessing: false,
      materialsData: [],
      userStats: null,
      widthPercent: 0,
      //temp code
      collectFuelItems: [],
      setCollectFuelItems: (arr: any) => {
        set({ collectFuelItems: arr });
      },
      setStep: (step) => {
        set({ step: step });
      },
      setSelectedUniverse: (data: IUniverseData) => {
        set({ selectedUniverse: data, selectedPlanet: null, selectedCoordinate: null, selectedRover: null });
      },
      setSelectedPlanet: (data: IPlanetData) => {
        set({ selectedPlanet: data, selectedCoordinate: null, selectedRover: null });
      },
      setSelectedCoordinate: (data: ICoordinateData) => {
        set({ selectedCoordinate: data, selectedRover: null });
      },
      setSelectedRover: (data: IRoverData) => {
        set({ selectedRover: data });
      },
      setIsOpenFuelModal: (data: boolean) => {
        set({ isOpenFuelModal: data });
      },
      setUserData: (data: IUserAccount) => {
        set({ userData: data });
      },
      setStartMiningData: (data: IStartMiningData) => {
        set({ startMiningData: data });
      },
      setFinishMiningData: (data: IStartMiningData) => {
        set({ finishMiningData: data });
      },
      setIsProcessing: (data: boolean) => {
        set({ isProcessing: data });
      },
      setMaterialsData: (data: IUserMaterials[]) => {
        set({ materialsData: data });
      },
      setUserStats: (data: IGetOverallItem) => {
        set({ userStats: data });
      },
      setWidthPercent: (data: number) => {
        set({ widthPercent: data });
      },
      clearData: () => {
        set({
          step: 0,
          selectedUniverse: null,
          selectedPlanet: null,
          selectedCoordinate: null,
          selectedRover: null,
          isStartMiningBoxVisible: null,
          isOpenFuelModal: null,
          isProcessing: false,
        });
      },
    }),
    {
      name: '2dGame',
    },
  ),
);
