import React, { ComponentProps } from 'react';
import { Skeleton } from '@mantine/core';
import styles from './CustomSkeleton.module.scss';

const CustomSkeleton = (props: ComponentProps<typeof Skeleton>) => (
  <div className={styles.skeleton}>
    <Skeleton {...props} />
  </div>
);

export default CustomSkeleton;
