import React, { ReactNode } from 'react';
import classNames from 'classnames';
import LoadingBalls from '@/components/Common/LoadingBalls';
import styles from './button.module.scss';

interface ButtonProps {
  /**
   * Optional Button contents
   */
  label?: string;
  /**
   * Optional click handler
   */
  onClick?: any;
  /**
   * Optional className
   */
  className?: string;
  /**
   * Optional className
   */
  children?: ReactNode;
  /**
   * Optional
   */
  disabled?: boolean;
  /**
   * Optional
   */
  variant?: 'primary';
  /**
   * Optional
   */
  isLoading?: boolean;
  /**
   * Optional
   */
  style?: any;
  /**
   * Optional
   */
  type?: 'submit' | 'reset' | 'button';
}

/**
 * Primary UI component for user interaction
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ label, className = '', children, disabled = false, variant, isLoading, onClick, style, type = 'button', ...props }, ref) => {
    const ButtonclassNames = classNames(styles.base, {
      [className]: className,
      [styles.base_disabled]: disabled,
      [styles.base_Primary]: variant == 'primary',
    });

    return (
      <button ref={ref} type={type} className={ButtonclassNames} onClick={disabled || isLoading ? () => {} : onClick} style={style} {...props}>
        {isLoading ? <LoadingBalls /> : children ? children : label}
      </button>
    );
  },
);
Button.displayName = 'Button';

export default Button;
