// export const numberSeparator = (num: number | string, seperator: string) =>
//   num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, seperator);

export const numberSeparator = (num?: number | string, seperator?: string) => {
  if(!num){
    return 0;
  }
  const numberString = num?.toString();

  // Split the number into integer and decimal parts (if any)
  const [integerPart, decimalPart] = numberString?.split('.');

  // Add commas as thousands separators to the integer part
  const formattedIntegerPart = integerPart?.replace(/\B(?=(\d{3})+(?!\d))/g, seperator || ',');

  // Concatenate the integer part and decimal part (if any)
  if (decimalPart) {
    return `${formattedIntegerPart}.${decimalPart}`;
  } else {
    return formattedIntegerPart;
  }
};

export const toEnNumber = (value: number | string): string => {
  const persianNumbers = ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰'];
  const arabicNumbers = ['١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '٠'];
  const englishNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  const str = value.toString();
  return str
    .split('')
    .map((c) => englishNumbers[persianNumbers.indexOf(c)] || englishNumbers[arabicNumbers.indexOf(c)] || c)
    .join('')
    .trim();
};

export const formatNumberDecimals = (number: number | null | undefined, decimals: number = 2): number => {
  if (number === null || number === undefined || isNaN(number)) {
    return 0;
  }

  if (Number.isInteger(number)) {
    return number;
  }

  return parseFloat(number.toFixed(decimals));
};
