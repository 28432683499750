export const contractAddress = '0x843840d993f5B6c65350a20990F2e304046454Bb';
export const contractAccessRestriction = '0x79e5886ba9815159F8ca1FC91265800A750Fc8bf';
export const contractLRTToken = '0xfb7f8A2C0526D01BFB00192781B7a7761841B16C';
export const contractLRTDistributor = '0xDb51f1C32E36d599C884F8A2D79b382d71129f63';
export const contractLRTVesting = '0x465a3D5650C127b35d0B6327879CF0CC573726e1';
export const contractLRTPreSale = '0xa6688bAAe5fb0e18E861e05ac6041E347B11Ac27';
export const contractLandRocker = '0xd2373b09402f21b7175Ebf2A0D80ca1d0807f84E';
export const contractLandRockerERC721 = '0xba311d3dfC5B0da78E06a917E3719B3eA6cC6467';
export const contractLandRockerERC1155 = '0xfbf61311C49564eb8711b62c9e954f82Cb011883';
export const contractNonMinted1155Marketplace = '0x4155f2f416641D227b67e49B931bCBfA32c520d6';
export const contractMinted1155Marketplace = '0x58419BEBCC591C74ad007423d462703963F47b97';
export const contractPlanetStake = '0x84dF18DB2De13962F45FFC78D0d717B8D64402b3';
export const aggregatorContract = '0xAB594600376Ec9fD91F8e885dADF0CE036862dE0';
export const aggregatorContractETH = '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419';
// Stable coins
export const contractDai = '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063';
export const contractUSDC = '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359';
export const contractUSDT = '0xc2132D05D31c914a87C6611C10748AEb04B58e8F';
// ERC20
export const contractWRTH = '0xbB19D0b00CF7cb7C473400d58133CEAFAC64282C';
export const contractWBTC = '0x80De98e7C333372d7588FD77a1D40856d619e5bb';
export const contractWMATIC = '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270';
// UniSwap
export const contractUniSwapRouter = '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff';
// Marketplace
export const contractAssetMarketplace = '0x113c86b9B2e43002c4Cc2942E427Ee7E3dee7B08';
export const contractNonMinted1155MarketplaceTwo = '0x2B6253F3cb9978B4047f93E59949a0075318B8F5';
export const contractNonMinted721MarketplaceTwo = '0xDCB498eEB98Ad163771416e0DB179e1350475344';
// Craft
export const contractPlanetCraft = '0xe05743AD21de241B6D25FCA0C8174e0CF1705D24';
// ETH Transaction
export const ETHTransactionAddress = '0xa324a071eeBb3951d5C4C5cd7552cE951dAd8f43';
// lootBox
export const contractLootBoxAddress = '0x36FBd9Ed5D7A23981F846f89f9733c934fc924a3';
// stake lrt to lrt
export const contractStakeLrtToLrtAddress = '0xd85049394e4950225cF16f66b4Cd821ACA225b83';
// stake lrt to fuel
export const contractStakeLrtToFuelAddress = '0x6fB1317614a4751636baEf31853d567331AE2eDa';
// stake lrt to nft
export const contractStakeLrtToNFTAddress = '0x2E5d7AF1d71812f1f453B4F2e9a246EA14325e5e';
// game season zero
export const contractGameZeroBountyAddress = '0xC24dCCAdE6D6529A3F693B920eA8fb649F89a9c9';
// vesting team
export const contractVestingTeamAddress = '0x77A824c4d7a6A074305e2CdE34a169090155101d';
//launchPad 1155
export const contractLaunchPad1155 = '0x9ADC2F2fe51df707b563F21899BA079eC4910DE4';
//launchPad 1155
export const contractAssetMarketplaceV2 = '0x113c86b9B2e43002c4Cc2942E427Ee7E3dee7B08';
// export const contractAssetMarketplaceV2 = '0x7391Df76a885087990F11c5d5A4D2082E69a1B10';
// Treasure Hunt
export const contractTreasureHuntAddress = '0xDA0457EEE6A6f168da0A7C04dc4cB05efa0d584e';
// game Referral
export const contractGameReferralAddress = '0xf2520FB628409cE2acf7cbf1660aA3fbfCeD6843';
// game vesting
export const contractGameVestingAddress = '0x08602907795F00E6B92879d035D0AAF9DDB6fb85';
//craft
export const contractAssetCraft = '0xe05743AD21de241B6D25FCA0C8174e0CF1705D24';
