import { useRouter } from 'next/router';
import { PAGES } from './Pages';

type Layout = 'isWide' | 'isNarrow' | 'none';

const getLayout = (pathname: string): Layout => PAGES[pathname] || 'isNarrow'; // Default to 'isNarrow' if path not found

export const usePageLayout = (): Layout => {
  const router = useRouter();
  const { pathname } = router;
  return getLayout(pathname);
};
