import * as React from 'react';

const AvatarLoginSvg = (props) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={21}
      style={{ fill: isHovered ? '#979797' : 'white', transition: 'fill 0.3s ease' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
      viewBox='0 0 24 21'
    >
      <g clipPath='url(#a)'>
        <path
          fill={isHovered ? '#979797' : '#31DA60'} // Changes color on hover
          d='M17.697 20.1c-3.3 0-5.9-2.6-5.9-5.9 0-3.3 2.6-5.9 5.9-5.9.7 0 1.4.1 2.1.3l-.6 1.5c-.5-.2-1-.3-1.5-.3-2.4 0-4.3 1.9-4.3 4.3 0 2.3 1.9 4.3 4.3 4.3s4.3-1.9 4.3-4.3h1.5c.1 3.3-2.5 6-5.8 6Z'
        />
        <path
          fill={isHovered ? '#979797' : '#fff'} // Changes color on hover
          d='M8 10c2.3 0 4-1.6 4-4V4c0-2.3-1.8-4-4-4S4 1.6 4 4v2c0 2.4 1.8 4 4 4Z'
        />
        <path
          fill={isHovered ? '#979797' : '#31DA60'} // Changes color on hover
          d='m17.698 16.5-2.3-2.5 1-1.2 1.2 1.4 3.7-4.7 1.1 1.1-4.7 5.9Z'
        />
        <path
          fill={isHovered ? '#979797' : '#fff'} // Changes color on hover
          d='M9.1 14.9c0-1.5.6-2.8 1.5-3.7-.7-.2-1.3-.2-1.8-.2-3 0-8.8 1.6-8.8 4.7V19h11c-1.2-1-1.9-2.5-1.9-4.1Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h23.6v20.1H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AvatarLoginSvg;
