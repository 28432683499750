/* eslint-disable import/no-extraneous-dependencies */
import { toast } from 'react-toastify';
import { useConnect } from 'wagmi';

const useSwitchAndConnect = () => {
  const data = useConnect();

  return {
    ...data,
    connect: async (...args: Parameters<typeof data.connectAsync>) =>
      data.connectAsync(...args).then((res) => {
        toast.success('Wallet is connected.', {
          // eslint-disable-next-line sonarjs/no-duplicate-string
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#005a08',
            color: 'white',
          },
        });
        // if (res.connector) {
        //   switchNetwork({ chainId: polygon.id });
        // }
      }),
  };
};

export default useSwitchAndConnect;
