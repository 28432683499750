/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-anonymous-default-export */
import { IAuthSignup, IChangePasswordForget, IChangePasswordProfile, IEmailForget, IProfileSetting, ISendCodeMobile } from '@/types/Authentication';
import { axiosInstanceTwo } from '../serviceTest';

export default {
  verifyWhitleList: (params: { wallet: string }) => axiosInstanceTwo.get('/check/otc/validation', { params }),
  getUserProfile: (token: string, provider: string, referral?: any, route?: string) =>
    axiosInstanceTwo.get<IAuthSignup>(`/api/login/with/third/party?access_token=${token}&base=${provider}&referral=${referral}&route=${route}`),
  sendUserPass: (params: { email: string; password: string; base: string; referral?: string; route: string }) =>
    axiosInstanceTwo.get<IAuthSignup>('/api/login/with/third/party', {
      params,
    }),

  sendVerifyEmail: (params: { email: any; code: any }) =>
    axiosInstanceTwo.get<IAuthSignup>('/api/user/verify/code', {
      params,
    }),

  loginWithEmail: (params: { email: any; password: any }) =>
    axiosInstanceTwo.get<IAuthSignup>('/api/user/login', {
      params,
    }),
  getRefreshToken: (params: { token: string }) =>
    axiosInstanceTwo.get<IAuthSignup>('/api/user/refresh/token', {
      headers: {
        token: params.token,
      },
    }),
  getEmailForget: (params: { email: string }) => axiosInstanceTwo.get<IEmailForget>('/api/user/forget/password', { params }),
  changePasswordForget: (params: { email: string; code: string; password: string }) =>
    axiosInstanceTwo.get<IChangePasswordForget>('/api/user/forget/code/check', {
      params,
    }),
  checkForgetPassCode: (params: { email: string; code: string }) =>
    axiosInstanceTwo.get<{
      data: { validate: boolean };
      status: boolean;
      code: number;
    }>('/api/user/forget/code/validate', {
      params,
    }),
  getProfileSetting: (token: string) => {
    if (token !== undefined && token !== '') {
      return axiosInstanceTwo.get<IProfileSetting>('/api/user/settings', {
        headers: {
          token: token,
        },
      });
    } else {
      return null;
    }
  },
  changePasswordLogin: (old_password: string, new_password: string, token: string) =>
    axiosInstanceTwo.get<IChangePasswordProfile>(`/api/user/change/password?old_password=${old_password}&new_password=${new_password}`, {
      headers: {
        token: token,
      },
    }),
  sendCodeMobile: (params: { mobile: number }, token: string) =>
    axiosInstanceTwo.get<ISendCodeMobile>('/api/user/get/mobile/code', {
      headers: { token: token },
      params,
    }),
  verifyCodeMobile: (params: { mobile: number; code: string; state: boolean }, token) =>
    axiosInstanceTwo.get<ISendCodeMobile>('/api/user/confirm/mobile/code', {
      headers: { token: token },
      params,
    }),
  resendCodePhone: (params: { mobile: number }, token: string) =>
    axiosInstanceTwo.get<ISendCodeMobile>('/api/user/resend/mobile/code', {
      headers: { token: token },
      params,
    }),
  getQrCode: () => axiosInstanceTwo.get('https://www.authenticatorApi.com/pair.aspx?AppName=MyApp&AppInfo=John&SecretCode=12345678BXYT'),
  get2faSecret: (params: { tow_factor: boolean }) => axiosInstanceTwo.get('/api/user/set/2fa', { params }),

  check2faSecret: (params: { tow_factor_code: string; tow_factor_status: boolean }, token: string) => {
    if (token !== undefined && token !== '') {
      return axiosInstanceTwo.get('/api/user/check/2fa', {
        headers: {
          token: token,
        },
        params,
      });
    } else {
      return null;
    }
  },
  thirdPartyLogin: (params: { tp_token: string; tp_wallet: string; tp_email: string; tp_sub: string }) => {
    const { tp_token, tp_wallet, tp_email, tp_sub } = params;
    const url = `api/login/with/third/party?base=immutable&tp_token=${tp_token}&tp_wallet=${tp_wallet}&tp_email=${tp_email}&tp_sub=${tp_sub}`;
    // const url = `api/login/with/third/party?base=immutable&tp_token=${tp_token}&tp_wallet=${tp_wallet}&tp_email=${tp_email}&tp_sub=${tp_sub}`;
    // const url = `api/login/with/third/party?base=immutable&tp_token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjNhYVl5dGR3d2UwMzJzMXIzVElyOSJ9.eyJlbWFpbCI6Im00MzkzNzUyOEBnbWFpbC5jb20iLCJjbGllbnRfbmFtZSI6ImxyIiwiY2xpZW50X29yZyI6IjA5YmJhNmFkLTUyZTMtNDlhZC1iZjE2LTQwMmQ3NWIzYzE3MiIsInprZXZtX2V0aF9hZGRyZXNzIjoiMHhiMmViYWY4MGRjNGRmODUzYWFjZGFkOGYwNTM1MjVmMTBlMjRlNThkIiwiemtldm1fdXNlcl9hZG1pbl9hZGRyZXNzIjoiMHhmYzQyNjY4MzJhNGUyMTIwN2YxYWUyZjQxOGYwNzJiMTE0MDBiNzg1IiwiaXNzIjoiaHR0cHM6Ly9hdXRoLmltbXV0YWJsZS5jb20vIiwic3ViIjoiZ29vZ2xlLW9hdXRoMnwxMTcxMTg2NTgxNTQ0Nzc2OTQ5MTciLCJhdWQiOlsicGxhdGZvcm1fYXBpIiwiaHR0cHM6Ly9wcm9kLmltbXV0YWJsZS5hdXRoMGFwcC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNzIyNDM0NDAyLCJleHAiOjE3MjI1MjA4MDIsInNjb3BlIjoib3BlbmlkIGVtYWlsIHRyYW5zYWN0IG9mZmxpbmVfYWNjZXNzIiwiYXpwIjoidkk0ZzBMd2NoaUZKSVF0TkxxejVVTjV1ZGRrU3dzcW4ifQ.mRdUzkJSMKfrU70wXSS29FCKC212Y9e5pdDAFmqEoveOPCJNm0it6bth8432GHODLITuqLPMmFh1ylz9niIcv1Rx4e-YMuIf5ruylqqa64QKFnm1yC3uT029nLNMcSQS0EIXCFAdavydvrg6PgvJMFGhl5iHKOkU24f5XUlvOSGCeo4ECENuyrcM31iTyPs_c7bum5GswHBpX6fPJv3nbDn1dG2j4qPXrlzo1YN7_qTi3TaeESj9o_qcwIAP6uzt7xnk07lfVB9dH3LsVaN0z9HjrNqMCIN7yti2DzCvNx_KjUmqEMFOSIUtRyK8joz4HTNMwLPuHYM29TBimDZE3g&tp_wallet=0xb2ebaf80dc4df853aacdad8f053525f10e24e58d&tp_email=m43937528@gmail.com&tp_sub=google-oauth2%7C117118658154477694917`;

    return axiosInstanceTwo.get(url);
  },
};
