import React from 'react';

export interface ArrowDown3Props {}

const ArrowDown3: React.FunctionComponent<ArrowDown3Props> = () => (
  <svg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.239063 0.272217C-0.0810907 0.584636 -0.0810907 1.09117 0.239063 1.40359L5.33723 6.40289C5.65738 6.71531 6.17645 6.71531 6.49661 6.40289L11.6583 1.36586C11.9785 1.05344 11.9785 0.546905 11.6583 0.234485C11.3382 -0.0779343 10.8191 -0.0779343 10.4989 0.234485L5.89844 3.50024L1.39844 0.272217C1.07829 -0.0402026 0.559216 -0.0402026 0.239063 0.272217Z'
      fill='#5A5C82'
    />
  </svg>
);

export default ArrowDown3;
