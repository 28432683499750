import * as React from 'react';

const WalletSvg = (props) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={19}
      style={{ fill: isHovered ? '#979797' : 'white', transition: 'fill 0.3s ease' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
      viewBox="0 0 21 19"
    >
      <path
        fill={isHovered ? '#979797' : 'white'} // Updates color on hover
        d="M21 7.13c0-1.725-.756-2.507-2.436-2.507H3.759c-.179 0-1.029-.954-1.103-1.204-.084-.347.179-.608.546-.662.21-.032.42-.032.63-.032h14.595c.19 0 .389.01.578-.011.515-.055.693-.293.662-.836-.064-1.074-.904-1.855-2.08-1.855A1671.12 1671.12 0 0 0 2.835.012C1.029.022 0 1.14 0 3.018v12.858c0 2.05 1.018 3.114 2.993 3.114H18.584c1.648 0 2.415-.803 2.415-2.485V7.13Zm-4.011 6.543c-1.05 0-1.911-.879-1.911-1.975s.85-1.975 1.911-1.975c1.06 0 1.911.88 1.911 1.975 0 1.096-.861 1.975-1.911 1.975Z"
      />
    </svg>
  );
};

export default WalletSvg;
