// @ts-nocheck
import { useEffect, useState, useRef } from 'react';
import useWebSocket from '../useWebSocket';
import jwt_decode from 'jwt-decode';
import { useSession } from 'next-auth/react';
import { useStore } from '@/zustand/store/store';
import deepEqual from 'fast-deep-equal'; // Install this package for deep equality check

interface ICommandSocket {
  rewards: any[];
  state: string;
  stateChanged: boolean;
  topUsers: any;
}

const useCommandReward = () => {
  const { data, status } = useSession();
  const [userId, setUserId] = useState<string>('');
  const { setCommandSocketData, commandSocketData } = useStore((state) => ({
    setCommandSocketData: state.setCommandSocketData,
    commandSocketData: state.commandSocketData,
  }));
  const prevStateRef = useRef<string>('');

  // Decode the user's session token to get userId
  useEffect(() => {
    if (data !== undefined && data !== null && status !== 'loading') {
      let decodedAccessToken: { exp: any } = jwt_decode(data.accessToken).token;
      setUserId(decodedAccessToken as unknown as string);
    }
  }, [data]);

  const { messages } = useWebSocket<Record<'message_type' | 'content' | 'state' | 'user_id', string>>('wss://gn.landrocker.io/ws');

  useEffect(() => {
    if (messages && messages?.user_id === userId) {
      const content = messages?.content?.[0];
      const parsedContent = JSON.parse(content);
      const rewards = parsedContent?.Rewards?.filter((item) => item?.operation === 'add');
      const newState = messages?.state;

      // Ensure rewards have changed before updating Zustand store
      if (rewards.length > 0 && !deepEqual(rewards, commandSocketData.rewards)) {
        setCommandSocketData({
          rewards,
          state: newState || commandSocketData.state || '',
          topUsers: commandSocketData.topUsers || [],
          stateChanged: prevStateRef.current !== newState,
          rewardsProcessed: false,
        });
      }
      // Ensure topUsers have changed before updating Zustand store
      else if (messages?.message_type === 'top_users') {
        const topUsersData = parsedContent;
        if (!deepEqual(topUsersData, commandSocketData.topUsers)) {
          setCommandSocketData({
            topUsers: topUsersData,
            rewards: commandSocketData.rewards,
            state: commandSocketData?.state,
            stateChanged: commandSocketData.stateChanged,
          });
        }
      }
      // Ensure state has changed before updating Zustand store
      else if (newState && newState !== commandSocketData.state) {
        setCommandSocketData({
          state: newState,
          rewards: commandSocketData.rewards,
          topUsers: commandSocketData.topUsers,
          stateChanged: prevStateRef.current !== newState,
          rewardsProcessed: false,
        });
      }

      prevStateRef.current = newState; // Update the ref to the new state
    }
  }, [messages, userId]);

  // Automatically reset stateChanged flag after processing
  useEffect(() => {
    if (commandSocketData.stateChanged) {
      const timeout = setTimeout(() => {
        setCommandSocketData({ stateChanged: false });
      }, 500); // Adjust the timeout as necessary

      return () => clearTimeout(timeout); // Clean up the timeout if effect re-runs
    }
  }, [commandSocketData.stateChanged, setCommandSocketData, commandSocketData]);
};

export default useCommandReward;
