import * as React from 'react';

const YoutubeSvg = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 12 8'
    {...props}
    onMouseEnter={(e) => (e.currentTarget.querySelector('path').style.fill = '#29D6DF')}
    onMouseLeave={(e) => (e.currentTarget.querySelector('path').style.fill = 'white')}
    style={{ transition: 'fill 0.3s ease' }} // Adding smooth transition for hover
  >
    <path
      fillRule='evenodd'
      d='M11.75 1.25c-.14-.5-.54-.88-1.06-1.01C9.75 0 6 0 6 0S2.25 0 1.31.24C.8.37.4.76.25 1.24 0 2.15 0 4 0 4s0 1.86.25 2.75c.14.49.55.88 1.06 1 .94.25 4.7.25 4.7.25s3.74 0 4.68-.24c.52-.13.92-.52 1.06-1.01C12 5.85 12 4 12 4s0-1.86-.25-2.75ZM5 6V2l3 2-3 2Z'
      fill='black' // Initial fill color
    />
  </svg>
);

export default YoutubeSvg;
