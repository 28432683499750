import { toast } from 'react-toastify';

interface CopyToClipboardArgs {
  successMessageKey?: string | null;
}

const CopyToClipboard = (text: string, { successMessageKey }: CopyToClipboardArgs = { successMessageKey: null }): void => {
  navigator.clipboard.writeText(text).then(() => {
    toast.success(successMessageKey ?? 'messages.copied', {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: '#005a08',
        color: 'white',
      },
    });
  });
};

export default CopyToClipboard;
