import { useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';

const useAuthStatuses = () => {
  const session = useSession();
  const { status, data } = session;
  const [isLogin, setIsLogin] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  useEffect(() => {
    //@ts-ignore
    setIsLogin(data?.valid_token !== undefined && data?.valid_token);
    setShowLoginModal(status == 'unauthenticated');
  }, [session]);

  return { session, isLogin, showLoginModal };
};

export default useAuthStatuses;
