import React, { PropsWithChildren, ReactNode } from "react";
import styles from "./IconMenu.module.scss";

interface IIconMenuProps {
  icon: ReactNode;
}

const IconMenu = (props: PropsWithChildren<IIconMenuProps>) => {
  return (
    <div className={styles.boxIcon}>
      {props.icon}
      <div className={styles.boxMenu}>{props.children}</div>
    </div>
  );
};

export default IconMenu;
