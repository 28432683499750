import React from 'react';
import { Modal } from '@mantine/core';
import CrossSvg from '@/components/Svg/CrossSvg';
import { EAuthVarient } from '@/types/Authentication';
import { useStore } from '@/zustand/store/store';
import styles from './ConnectLoginOrWalletModal.module.scss';
import StepCard from './StepCard';
import useConnectWallet from '@/hooks/common/useConnectWallet';

const ConnectLoginOrWalletModal = ({ opened, setOpened }) => {
  const { setAuthModalVarient } = useStore();
  const { handleClickWallet } = useConnectWallet();

  const handleClickConnectWallet = () => {
    setOpened(false);
    handleClickWallet();
  };

  const handleClickLogin = () => {
    setOpened(false);
    setAuthModalVarient(EAuthVarient.SIGN_IN);
  };

  return (
    <Modal
      centered
      withCloseButton={false}
      styles={{
        body: { backgroundColor: '#20232B', maxHeight: '90%!important', maxWidth: '1022px' },
        // @ts-ignore
        content: { backgroundColor: '#20232B', overflowY: 'visible!important' },
        overlay: { zIndex: 86 },
        inner: { zIndex: 87 },
      }}
      opened={opened}
      onClose={() => {
        setOpened(false);
      }}
      size={'auto'}
      overlayProps={{
        opacity: 0.55,
        blur: 3,
      }}
      className='reletive'
    >
      <div className={styles.header}>
        <div onClick={() => setOpened(false)} className={styles.closeButton}>
          <CrossSvg />
        </div>
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.shadowContainer}>
          <div className={`${styles.shadow}`}></div>
        </div>
        <div className={styles.badgeDescContainer}>
          <div className={styles.modalSubTitle}>
            <span>To access the inventory, please login or connect your wallet.</span>
          </div>
          <div className={styles.bodyContainer}>
            <StepCard
              variant={'loginCard'}
              buttonLabel={'Login'}
              step={'Step 1'}
              stepDesc={'Login / Create account'}
              onClick={handleClickLogin}
              number='1.'
            />
            <StepCard
              variant={'connectWalletCard'}
              buttonLabel={'Connect wallet'}
              step={'Step 2'}
              stepDesc={'Connect your wallet'}
              onClick={handleClickConnectWallet}
              number='2.'
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConnectLoginOrWalletModal;
