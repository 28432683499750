/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Button, Typography } from '@/components/Atoms';
import { CustomModal } from '@/components/Molecules';
import { useStore } from '@/zustand/store/store';
import styles from './ItemInformationSuccess.module.scss';

const ItemInformationSuccess = () => {
  const { blueprintDetail, isShowSuccessCraftModal, setIsShowSuccessCraftModal, nftSuccessCraft } = useStore();
  const router = useRouter();
  if (!blueprintDetail) {
    return null;
  }
  const { asset_name, asset_image, asset_id, asset_type } = nftSuccessCraft;
  const onClickView = () => {
    router.push(`https://marketplace.landrocker.io/nft-detail/${asset_id}`);
    setIsShowSuccessCraftModal(false);
  };
  const onClickOk = () => {
    setIsShowSuccessCraftModal(false);
  };

  return (
    <CustomModal
      visible={isShowSuccessCraftModal}
      centered
      bodyStyle={{
        width: 'auto',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '90vh',
      }}
      onClose={() => {
        setIsShowSuccessCraftModal(false);
      }}
      closeOnClickOutside={false}
    >
      <div className={styles.contentContainer}>
        <div className={styles.shadowContainer}>
          <div className={`${styles.shadow}`}></div>
        </div>
        <div>
          <div>
            <Typography className={styles.title} isBold Component='div'>
              Craft successful
            </Typography>
            <Typography className={styles.desc} isBold Component='div'>
              New NFT added to your inventory.
            </Typography>
          </div>

          <div className={styles.planetContainer}>
            <div className={styles.image}>
              <Image src={asset_image} layout='fill' objectFit='cover' className=' rounded-[8px]' alt={asset_name} />
            </div>
            <Typography Component='div' className={styles.name} isBold>
              {asset_name}
            </Typography>
          </div>

          <div className='flex justify-center  relative'>
            <Button onClick={onClickOk} label='OK' className={styles.okButton} />
            {/* <Button onClick={onClickView} label='View' className={styles.viewButton} /> */}
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default ItemInformationSuccess;
