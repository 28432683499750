import * as React from 'react';

const TwitterSvg = (props) => (
  <svg
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    viewBox='0 0 1483 1483'
    style={{
      enableBackground: 'new 0 0 1483 1483',
      transition: 'fill 0.3s ease',
    }}
    xmlSpace='preserve'
    onMouseEnter={(e) => (e.currentTarget.querySelector('path').style.fill = '#29D6DF')}
    onMouseLeave={(e) => (e.currentTarget.querySelector('path').style.fill = 'white')}
    {...props}
  >
    <style type='text/css'>{'\n\t.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FAFAFA;}\n'}</style>
    <path
      className='st0'
      d='M304.3,302.8c3,4.3,72.2,105,153.7,223.8s157,228.7,167.6,244.1c10.6,15.5,19.3,28.5,19.3,28.8s-4,5.3-8.8,10.9 
      c-4.8,5.6-18.5,21.4-30.3,35.2c-11.8,13.8-31.8,36.9-44.3,51.5c-12.5,14.6-34.6,40.2-49,57c-14.4,16.8-40.4,46.9-57.6,67 
      c-53.4,62.1-61.2,71.1-101.8,118.5c-21.7,25.3-42.6,49.5-46.3,53.8s-6.8,8.2-6.8,8.8c0,0.6,13.6,1,38.4,1h38.4l42.3-49.2 
      c23.2-27.1,46.2-53.8,51-59.2c10.3-11.9,89.2-103.6,95.8-111.5c2.5-3,6.2-7.3,8.2-9.5c2-2.2,17.5-20.2,34.5-40 
      c17-19.8,31.4-36.5,31.9-37.1c0.6-0.6,9.6-11.1,20-23.2c10.4-12.2,19.3-22.2,19.7-22.2c0.4,0,53.9,77.5,119,172.2 
      c65,94.7,119.4,173.9,120.8,176l2.6,3.7l131.9,0c108.4,0,131.8-0.2,131.4-1.3c-0.4-1.1-63.6-93.3-225.7-329.2 
      C843.4,702.7,827.8,679.9,828.3,678.7c0.4-1.1,16.3-19.7,120.8-141.3c17.9-20.9,42.6-49.6,54.9-63.9s24.7-28.7,27.5-32 
      c2.9-3.3,18-20.9,33.6-39c15.6-18.1,42.1-49,58.9-68.5c16.8-19.5,31.2-36.3,31.9-37.2c1.3-1.6-0.9-1.7-37.7-1.7h-39l-17.4,20.2 
      c-23.1,27-64.9,75.5-76.8,89.3c-5.2,6.1-11.8,13.7-14.5,16.9c-2.8,3.3-8.2,9.5-12.1,14S935,462.6,915,486 
      c-20,23.4-36.7,42.7-37,43c-0.3,0.3-4.6,5.3-9.5,11.1c-8.6,10.2-17.3,20.3-57.4,66.8c-17.6,20.4-18.5,21.3-20,19.4
      c-0.9-1.1-52.4-76.1-114.6-166.6L563.5,295l-132.3,0l-132.3,0L304.3,302.8 M407.6,356.7c1,1.5,25.7,37,54.9,78.8 
      c55.4,79.3,273.2,391,413.3,591.5c44.8,64.1,81.9,117.1,82.6,117.8c1,1,14,1.2,60.9,1l59.7-0.3L922.8,922 
      c-85.9-122.9-210.3-300.9-276.4-395.5l-120.2-172l-60.2-0.3l-60.2-0.3L407.6,356.7'
    />
  </svg>
);

export default TwitterSvg;
