/* eslint-disable @typescript-eslint/ban-ts-comment */
import { StateCreator } from 'zustand';
import { EAuthVarient } from '@/types/Authentication';
import { IModalSlice } from './Interface';

export const modalSlice: StateCreator<IModalSlice> = (set, get) => ({
  isOpenDailyRewardModal: false,
  isOpenAchievmentModal: false,
  isOpenHowToEarnMore: false,
  isEnterReferralCode: false,
  isOpenOnBoarding: false,
  firstOpenReferralCode: false,
  firstOpenReferralSelf: false,
  firstModalConnectWallet: false,
  firstOpenFreeRover: false,
  logOutAction: '',
  isCloseReferralModal: true,
  openCraftModal: {
    isOpenCraftedModal: false,
    isOpenCraftingModal: false,
    isOpenFinishCraftModal: false,
  },
  isOpenAchievementModal: false,
  isCallActiveAchievment: false,
  isOpenLevelUpReward: false,
  isLogOutModal: false,

  modalsQueue: [],
  isOpenModal: false,

  isModalOpen: {
    comingSoon: false,
    quote: false,
    inventory: false,
    wallet: false,
  },

  setIsModalOpen: (modalType: string, isOpen: boolean) => {
    set({
      isModalOpen: { ...get().isModalOpen, [modalType]: isOpen },
    });
  },

  setModalsQueue: (handler: any, state: any) => {
    //@ts-ignore
    if (state === EAuthVarient) {
      set({ modalsQueue: [{ handler, state }, ...get().modalsQueue] });
    }
    set({ modalsQueue: [...get().modalsQueue, { handler, state }] });
  },

  setModalsQueueDelete: () => {
    const arr = [...get().modalsQueue];
    arr.shift();
    //@ts-ignore

    set({ modalsQueue: [...arr] });
  },
  setIsOpenModal: (isOpenModal: boolean) => {
    set({
      isOpenModal: isOpenModal,
    });
  },
  setIsOpenDailyRewardModal: (isOpenModal: boolean) => {
    set({
      isOpenDailyRewardModal: isOpenModal,
    });
  },
  setIsOpenAchievmentModal: (isOpenModal: boolean) => {
    set({ isOpenAchievmentModal: isOpenModal });
  },
  setIsOpenHowToEarnMore: (isOpenModal: boolean) => {
    set({ isOpenHowToEarnMore: isOpenModal });
  },

  setIsEnterReferralCode: (isOpenModal: boolean) => {
    set({ isEnterReferralCode: isOpenModal });
  },
  setIsOpenOnBoarding: (isOpenModal: boolean) => {
    set({ isOpenOnBoarding: isOpenModal });
  },
  setFirstOpenReferralCode: (isOpenModal: boolean) => {
    set({ firstOpenReferralCode: isOpenModal });
  },
  setFirstOpenReferralSelf: (isOpenModal: boolean) => {
    set({ firstOpenReferralSelf: isOpenModal });
  },
  setFirstModalConnectWallet: (isOpenModal: boolean) => {
    set({ firstModalConnectWallet: isOpenModal });
  },
  setFirstOpenFreeRover: (isOpenModal: boolean) => {
    set({ firstOpenFreeRover: isOpenModal });
  },
  setIsCloseReferralModal: (isCloseReferralModal: boolean) => {
    set({ isCloseReferralModal: isCloseReferralModal });
  },
  setLogOutModal: (isLogOutModal: boolean) => {
    set({ isLogOutModal });
  },
  setLogOutAction: (logOutAction: 'loading' | 'logOut' | '') => {
    set({ logOutAction });
  },

  setIsOpenCraftedModal: (isOpen: boolean) => {
    set({
      openCraftModal: {
        ...get().openCraftModal,
        isOpenCraftedModal: isOpen,
      },
    });
  },
  setIsOpenCraftingModal: (isOpen: boolean) => {
    set({
      openCraftModal: {
        ...get().openCraftModal,
        isOpenCraftingModal: isOpen,
      },
    });
  },
  setIsOpenFinishCraftModal: (isOpen: boolean) => {
    set({
      openCraftModal: {
        ...get().openCraftModal,
        isOpenFinishCraftModal: isOpen,
      },
    });
  },
  setIsOpenAchievementModal: (isOpenModal: boolean) => {
    set({ isOpenAchievementModal: isOpenModal });
  },
  setIsCallActiveAchievment: (isCallActiveAchievment: boolean) => {
    set({ isCallActiveAchievment: isCallActiveAchievment });
  },
  setIsOpenLevelUpReward: (isOpenModal: boolean) => {
    set({ isOpenLevelUpReward: isOpenModal });
  },
});
