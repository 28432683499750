import React from 'react';
import Link from 'next/link';

export interface MarketplaceCardLinkWrapperProps {
  children: React.ReactNode;
  href: string;
  isLink: boolean;
}

const MarketplaceCardLinkWrapper: React.FunctionComponent<MarketplaceCardLinkWrapperProps> = ({ children, href, isLink }) => (
  <>
    {isLink ? (
      <Link href={href} passHref>
        <a className='w-full'>{children}</a>
      </Link>
    ) : (
      children
    )}
  </>
);

export default MarketplaceCardLinkWrapper;
