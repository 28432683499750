/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/naming-convention */
import api from '@/api/services/auth';
import { IAuthSignup, IChangePasswordForget, IChangePasswordProfile, IEmailForget, IProfileSetting, ISendCodeMobile } from '@/types/Authentication';

export const _getUserProfile = (token: string, provider: string) =>
  new Promise<IAuthSignup>((resolve: (res: IAuthSignup) => void, reject: (err: any) => void) => {
    api
      .getUserProfile(token, provider)
      ?.then((res) => {
        resolve(res.data as IAuthSignup);
      })
      .catch((err) => reject(err));
  });

export const _sendUserPassword = (props: { base: string; email: string; password: string; referral?: string; route: string }) =>
  new Promise<any>((resolve: (res: any) => void, reject: (err: any) => void) => {
    api
      .sendUserPass(props)
      ?.then((res) => {
        resolve(res.data as any);
      })
      .catch((err) => reject(err));
  });

export const _sendVerifyEmail = (props: { email: any; code: any }) =>
  new Promise<any>((resolve: (res: any) => void, reject: (err: any) => void) => {
    api
      .sendVerifyEmail(props)
      ?.then((res) => {
        resolve(res.data as any);
      })
      .catch((err) => reject(err));
  });

export const _loginWithEmail = (props: { email: any; password: any }) =>
  new Promise<any>((resolve: (res: any) => void, reject: (err: any) => void) => {
    api
      .loginWithEmail(props)
      ?.then((res) => {
        resolve(res.data as any);
      })
      .catch((err) => reject(err));
  });
export const _getEmailForgetPassword = (email: string) =>
  new Promise<IEmailForget>((resolve: (res: IEmailForget) => void, reject: (err: any) => void) => {
    api
      .getEmailForget({ email })
      ?.then((res) => {
        resolve(res.data as IEmailForget);
      })
      .catch((err) => reject(err));
  });
export const _sendCodeAndNewPassword = (email: string, code: string, password: string) =>
  new Promise<IChangePasswordForget>((resolve: (res: IChangePasswordForget) => void, reject: (err: any) => void) => {
    api
      .changePasswordForget({ email, code, password })
      ?.then((res) => {
        resolve(res.data as IChangePasswordForget);
      })
      .catch((err) => reject(err));
  });
export const _checkForgetPassCode = (email: string, code: string) =>
  new Promise<{ validate: boolean }>((resolve: (res: any) => void, reject: (err: any) => void) => {
    api
      .checkForgetPassCode({ email, code })
      ?.then((res) => {
        resolve(res.data as any);
      })
      .catch((err) => reject(err));
  });
export const _getProfileSetting = (token: string) =>
  new Promise<IProfileSetting>((resolve: (res: IProfileSetting) => void, reject: (err: any) => void) => {
    api
      .getProfileSetting(token)
      ?.then((res) => {
        resolve(res.data as IProfileSetting);
      })
      .catch((err) => reject(err));
  });
export const _changePasswordLogin = (old_password: string, new_password: string, token) =>
  new Promise<IChangePasswordProfile>((resolve: (res: IChangePasswordProfile) => void, reject: (err: any) => void) => {
    api
      .changePasswordLogin(old_password, new_password, token)
      ?.then((res) => {
        resolve(res.data as IChangePasswordProfile);
      })
      .catch((err) => reject(err));
  });
export const _sendCodeMobile = (mobile: number, token) =>
  new Promise<ISendCodeMobile>((resolve: (res: ISendCodeMobile) => void, reject: (err) => void) => {
    api
      .sendCodeMobile({ mobile }, token)
      ?.then((res) => {
        resolve(res.data as ISendCodeMobile);
      })
      .catch((err) => reject(err));
  });
export const _resendCodeMobile = (mobile: number, token) =>
  new Promise<ISendCodeMobile>((resolve: (res: ISendCodeMobile) => void, reject: (err) => void) => {
    api
      .resendCodePhone({ mobile }, token)
      ?.then((res) => {
        resolve(res.data as ISendCodeMobile);
      })
      .catch((err) => reject(err));
  });
export const _verifyCodeOtp = (mobile: number, code: string, token, state: boolean) =>
  new Promise<ISendCodeMobile>((resolve: (res: ISendCodeMobile) => void, reject: (err) => void) => {
    api
      .verifyCodeMobile({ mobile, code, state }, token)
      ?.then((res) => {
        resolve(res.data as ISendCodeMobile);
      })
      .catch((err) => reject(err));
  });
export const _getQrCode = () =>
  new Promise<any>((resolve: (res: any) => void, reject: (err) => void) => {
    api
      .getQrCode()
      ?.then((res) => {
        resolve(res.data as any);
      })
      .catch((err) => reject(err));
  });

export const _get2faSecret = (twoFactorStatus: boolean) =>
  new Promise<any>((resolve: (res: any) => void, reject: (err) => void) => {
    api
      .get2faSecret({
        tow_factor: twoFactorStatus,
      })
      ?.then((res) => {
        resolve(res.data as any);
      })
      .catch((err) => reject(err));
  });

export const _check2faSecret = (twoFactorCode: string, twoFactorStatus: boolean, token) =>
  new Promise<any>((resolve: (res: any) => void, reject: (err) => void) => {
    api
      .check2faSecret(
        {
          tow_factor_code: twoFactorCode,
          tow_factor_status: twoFactorStatus,
        },
        token,
      )
      ?.then((res) => {
        resolve(res.data as any);
      })
      .catch((err) => reject(err));
  });
