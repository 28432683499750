/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable sonarjs/no-ignored-return */
import axios, { AxiosError, Canceler } from 'axios';

let cancel: [string, Canceler][] = [];

export const cancelAllRequests = () => {
  cancel.map((item) => {
    item[1]();
  });
};

const baseURL = process.env.NEXT_PUBLIC_BASE_URL_STRAPI;
// "https://srvs20.landrocker.io/game_service/api/portal"
// 'https://srvs30.landrocker.io'
// 'https://srvs20.landrocker.io/test_game_service';

export const axiosInstanceStrapi = axios.create({
  baseURL,
  headers: {},
  // timeout: 5000,
});

// Add a response interceptor
axiosInstanceStrapi.interceptors.request.use(
  async function (response) {
    // const CancelToken = axios.CancelToken;

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosInstanceStrapi.interceptors.response.use(
  function (response) {
    if (
      response.config.method === 'post' ||
      (response.config.method === 'POST' && response.status === 200 && response?.data.message)
    ) {
    }

    cancel = cancel.filter((fid) => fid[0] !== response.config.url);
    return response;
  },
  function (error: AxiosError) {
    cancel = cancel.filter((fid) => {
      if (error.response && error.response?.config) {
        return fid[0] !== error.response.config.url;
      }
    });

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);
