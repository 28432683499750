import React from 'react';

export interface FooterLogoProps {}

const FooterLogo: React.FunctionComponent<FooterLogoProps> = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='267.284' height='39.701' viewBox='0 0 267.284 39.701'>
    <g id='Group_30956' data-name='Group 30956' transform='translate(-51.04 -50.943)'>
      <g id='Group_13479' data-name='Group 13479' transform='translate(51.04 50.943)'>
        <g id='Group_13476' data-name='Group 13476' transform='translate(14.042)'>
          <path
            id='Path_29371'
            data-name='Path 29371'
            d='M107.37,55.018l-2.91-2.91a3.674,3.674,0,0,0-5.2,0l-2.91,2.91L99.328,58l1.955-1.955a.808.808,0,0,1,1.147,0L104.386,58Z'
            transform='translate(-96.35 -51.03)'
            fill='#47ffff'
          />
        </g>
        <g id='Group_13478' data-name='Group 13478' transform='translate(0 6.248)'>
          <path
            id='Path_29372'
            data-name='Path 29372'
            d='M136.64,110.808H114.2l4.608-4.608h13.224Z'
            transform='translate(-94.627 -95.35)'
            fill='#fff'
          />
          <path
            id='Path_29373'
            data-name='Path 29373'
            d='M80.812,123.924l-7.769,7.769-2.076,2.076a.5.5,0,0,1-.707,0l-2.079-2.079L57.56,121.07H51.04l16.877,16.877a3.814,3.814,0,0,0,5.4,0l2.994-2.994,11.026-11.026h-6.52Z'
            transform='translate(-51.04 -105.612)'
            fill='#fff'
          />
          <path
            id='Path_29374'
            data-name='Path 29374'
            d='M137.466,79.189l-8-8-3.26,3.26,4.738,4.738Z'
            transform='translate(-102.915 -71.19)'
            fill='#fff'
          />
          <g id='Group_13477' data-name='Group 13477' transform='translate(1.735)'>
            <path
              id='Path_29375'
              data-name='Path 29375'
              d='M56.64,82.046,67.5,71.19l3.26,3.257-7.6,7.6Z'
              transform='translate(-56.64 -71.19)'
              fill='#fff'
            />
          </g>
        </g>
      </g>
      <g id='Group_13492' data-name='Group 13492' transform='translate(108.48 62.564)'>
        <g id='Group_13480' data-name='Group 13480' transform='translate(0 11.6)'>
          <path
            id='Path_29376'
            data-name='Path 29376'
            d='M239.607,132.35v.245a1.114,1.114,0,0,0,1.113,1.113h.223v3.217H239.2a2.807,2.807,0,0,1-2.808-2.808V132.35Z'
            transform='translate(-236.39 -132.35)'
            fill='#47ffff'
          />
        </g>
        <g id='Group_13481' data-name='Group 13481' transform='translate(205.291)'>
          <path
            id='Path_29377'
            data-name='Path 29377'
            d='M900.166,99.494v-.245a1.114,1.114,0,0,0-1.112-1.113h-.223V94.92h1.745a2.809,2.809,0,0,1,2.808,2.808v1.766Z'
            transform='translate(-898.83 -94.92)'
            fill='#47ffff'
          />
        </g>
        <g id='Group_13486' data-name='Group 13486' transform='translate(0.167 0.18)'>
          <g id='Group_13485' data-name='Group 13485'>
            <g id='Group_13484' data-name='Group 13484'>
              <g id='Group_13482' data-name='Group 13482'>
                <rect id='Rectangle_3992' data-name='Rectangle 3992' width='3.672' height='9.932' fill='#fff' />
                <path
                  id='Path_29378'
                  data-name='Path 29378'
                  d='M264.464,134.67l-2.123,3.675H256.76V134.67Z'
                  transform='translate(-250.615 -122.531)'
                  fill='#fff'
                />
                <path
                  id='Path_29379'
                  data-name='Path 29379'
                  d='M867.154,103.945v-2.563h-3.672v1.844a.741.741,0,0,1-.741.741H850.252V99.175h11.023V95.5h-14.7v15.814h3.675v-3.672h8.281l3.53,3.672h5.095l-3.536-3.679A3.692,3.692,0,0,0,867.154,103.945Z'
                  transform='translate(-657.648 -95.5)'
                  fill='#fff'
                />
              </g>
              <g id='Group_13483' data-name='Group 13483'>
                <rect id='Rectangle_3993' data-name='Rectangle 3993' width='3.672' height='9.932' fill='#fff' />
                <path
                  id='Path_29380'
                  data-name='Path 29380'
                  d='M264.464,134.67l-2.123,3.675H256.76V134.67Z'
                  transform='translate(-250.615 -122.531)'
                  fill='#fff'
                />
              </g>
            </g>
          </g>
        </g>
        <g id='Group_13491' data-name='Group 13491' transform='translate(13.685 0.177)'>
          <g id='Group_13490' data-name='Group 13490'>
            <g id='Group_13489' data-name='Group 13489'>
              <g id='Group_13487' data-name='Group 13487'>
                <path
                  id='Path_29381'
                  data-name='Path 29381'
                  d='M449.7,95.5H432.83v15.817H449.7a3.7,3.7,0,0,0,3.7-3.7V99.2A3.7,3.7,0,0,0,449.7,95.5Zm.022,11.4a.741.741,0,0,1-.741.741H436.5V99.175h12.486a.741.741,0,0,1,.741.741Z'
                  transform='translate(-385.638 -95.497)'
                  fill='#fff'
                />
                <path
                  id='Path_29382'
                  data-name='Path 29382'
                  d='M593.1,95.5h-13.18a3.7,3.7,0,0,0-3.7,3.7v8.423a3.7,3.7,0,0,0,3.7,3.7H593.1a3.7,3.7,0,0,0,3.7-3.7V99.2A3.7,3.7,0,0,0,593.1,95.5Zm.025,11.4a.741.741,0,0,1-.741.741H580.636a.741.741,0,0,1-.741-.741V99.913a.741.741,0,0,1,.741-.741h11.745a.741.741,0,0,1,.741.741Z'
                  transform='translate(-484.591 -95.497)'
                  fill='#fff'
                />
                <path
                  id='Path_29383'
                  data-name='Path 29383'
                  d='M664.777,95.5H651.6a3.7,3.7,0,0,0-3.7,3.7v8.423a3.7,3.7,0,0,0,3.7,3.7h13.18a3.7,3.7,0,0,0,3.7-3.7v-1.639H664.8v.92a.741.741,0,0,1-.741.741H652.316a.741.741,0,0,1-.741-.741V99.913a.741.741,0,0,1,.741-.741h11.745a.741.741,0,0,1,.741.741v.92h3.672V99.194A3.7,3.7,0,0,0,664.777,95.5Z'
                  transform='translate(-534.058 -95.497)'
                  fill='#fff'
                />
                <path
                  id='Path_29384'
                  data-name='Path 29384'
                  d='M294.734,95.5h-5.051l-9.133,15.814h4.243l7.416-12.842,5.29,9.167h-8.69l-2.123,3.675h17.178Z'
                  transform='translate(-280.55 -95.497)'
                  fill='#fff'
                />
                <path
                  id='Path_29385'
                  data-name='Path 29385'
                  d='M377.991,107.645,366.323,95.5H361.08v15.814h3.672V99.169l11.671,12.145h5.241V95.5h-3.672Z'
                  transform='translate(-336.124 -95.497)'
                  fill='#fff'
                />
                <path
                  id='Path_29386'
                  data-name='Path 29386'
                  d='M525.094,103.938V99.187a3.7,3.7,0,0,0-3.7-3.7H504.51v15.817h3.675v-3.675h8.284l3.53,3.675h5.095l-3.536-3.682A3.694,3.694,0,0,0,525.094,103.938Zm-16.908-4.772h12.492a.741.741,0,0,1,.741.741v3.313a.741.741,0,0,1-.741.741H508.185Z'
                  transform='translate(-435.104 -95.49)'
                  fill='#fff'
                />
                <rect id='Rectangle_3994' data-name='Rectangle 3994' width='18.042' height='3.675' transform='translate(155.704 0.003)' fill='#fff' />
                <path
                  id='Path_29387'
                  data-name='Path 29387'
                  d='M786.655,118.755h8.082v-3.672h-8.082v0H782.98v6.288l4.583,3.459h13.462v-3.672h-14.37Z'
                  transform='translate(-627.276 -109.009)'
                  fill='#fff'
                />
                <path
                  id='Path_29388'
                  data-name='Path 29388'
                  d='M737.61,98.119V98.1h0V95.5h-2.628l-8.079,6.071h-3.651V95.5H719.58v15.814h3.672v-6.071H726.9l8.079,6.071h6.108l-10.515-7.906Z'
                  transform='translate(-583.524 -95.497)'
                  fill='#fff'
                />
              </g>
              <g id='Group_13488' data-name='Group 13488'>
                <path
                  id='Path_29389'
                  data-name='Path 29389'
                  d='M449.7,95.5H432.83v15.817H449.7a3.7,3.7,0,0,0,3.7-3.7V99.2A3.7,3.7,0,0,0,449.7,95.5Zm.022,11.4a.741.741,0,0,1-.741.741H436.5V99.175h12.486a.741.741,0,0,1,.741.741Z'
                  transform='translate(-385.638 -95.497)'
                  fill='#fff'
                />
                <path
                  id='Path_29390'
                  data-name='Path 29390'
                  d='M593.1,95.5h-13.18a3.7,3.7,0,0,0-3.7,3.7v8.423a3.7,3.7,0,0,0,3.7,3.7H593.1a3.7,3.7,0,0,0,3.7-3.7V99.2A3.7,3.7,0,0,0,593.1,95.5Zm.025,11.4a.741.741,0,0,1-.741.741H580.636a.741.741,0,0,1-.741-.741V99.913a.741.741,0,0,1,.741-.741h11.745a.741.741,0,0,1,.741.741Z'
                  transform='translate(-484.591 -95.497)'
                  fill='#fff'
                />
                <path
                  id='Path_29391'
                  data-name='Path 29391'
                  d='M664.777,95.5H651.6a3.7,3.7,0,0,0-3.7,3.7v8.423a3.7,3.7,0,0,0,3.7,3.7h13.18a3.7,3.7,0,0,0,3.7-3.7v-1.639H664.8v.92a.741.741,0,0,1-.741.741H652.316a.741.741,0,0,1-.741-.741V99.913a.741.741,0,0,1,.741-.741h11.745a.741.741,0,0,1,.741.741v.92h3.672V99.194A3.7,3.7,0,0,0,664.777,95.5Z'
                  transform='translate(-534.058 -95.497)'
                  fill='#fff'
                />
                <path
                  id='Path_29392'
                  data-name='Path 29392'
                  d='M294.734,95.5h-5.051l-9.133,15.814h4.243l7.416-12.842,5.29,9.167h-8.69l-2.123,3.675h17.178Z'
                  transform='translate(-280.55 -95.497)'
                  fill='#fff'
                />
                <path
                  id='Path_29393'
                  data-name='Path 29393'
                  d='M377.991,107.645,366.323,95.5H361.08v15.814h3.672V99.169l11.671,12.145h5.241V95.5h-3.672Z'
                  transform='translate(-336.124 -95.497)'
                  fill='#fff'
                />
                <path
                  id='Path_29394'
                  data-name='Path 29394'
                  d='M525.094,103.938V99.187a3.7,3.7,0,0,0-3.7-3.7H504.51v15.817h3.675v-3.675h8.284l3.53,3.675h5.095l-3.536-3.682A3.694,3.694,0,0,0,525.094,103.938Zm-16.908-4.772h12.492a.741.741,0,0,1,.741.741v3.313a.741.741,0,0,1-.741.741H508.185Z'
                  transform='translate(-435.104 -95.49)'
                  fill='#fff'
                />
                <rect id='Rectangle_3995' data-name='Rectangle 3995' width='18.042' height='3.675' transform='translate(155.704 0.003)' fill='#fff' />
                <path
                  id='Path_29395'
                  data-name='Path 29395'
                  d='M786.655,118.755h8.082v-3.672h-8.082v0H782.98v6.288l4.583,3.459h13.462v-3.672h-14.37Z'
                  transform='translate(-627.276 -109.009)'
                  fill='#fff'
                />
                <path
                  id='Path_29396'
                  data-name='Path 29396'
                  d='M737.61,98.119V98.1h0V95.5h-2.628l-8.079,6.071h-3.651V95.5H719.58v15.814h3.672v-6.071H726.9l8.079,6.071h6.108l-10.515-7.906Z'
                  transform='translate(-583.524 -95.497)'
                  fill='#fff'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default FooterLogo;
