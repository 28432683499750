import { useCallback, useEffect, useRef, useState } from 'react';

interface UseWebSocketResponseType<T> {
  messages: T | null;
  isLoading: boolean;
  sendMessage: (message: string) => void;
}

function useWebSocket<T>(url: string): UseWebSocketResponseType<T> {
  const ws = useRef<WebSocket | null>(null); // Store WebSocket connection
  const [messages, setMessages] = useState<T | null>(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    let retryCount = 0;

    const connect = () => {
      ws.current = new WebSocket(url);

      ws.current.onopen = () => {
        retryCount = 0;
        setLoading(false);
      };

      ws.current.onmessage = (event) => {
        try {
          setMessages(JSON.parse(event.data));
        } catch (e) {
          console.error('Error parsing message:', e);
        }
      };

      ws.current.onclose = () => {
        setLoading(false);
        if (retryCount < 5) {
          // Retry connection with limit
          setTimeout(connect, 2000);
          retryCount++;
        }
      };

      ws.current.onerror = (err) => {
        console.error('WebSocket error:', err);
      };
    };

    if (!ws.current) {
      connect(); // Only connect if ws.current is null (i.e., first render)
    }

    return () => {
      if (ws.current) {
        ws.current.close(); // Clean up WebSocket connection when component unmounts
      }
    };
  }, [url]);

  const sendMessage = useCallback((message: string) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(message);
    } else {
      console.error('WebSocket is not open');
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      sendMessage('ping');
    }, 20000); // Keep connection alive

    return () => clearInterval(interval); // Clean up interval on unmount
  }, [sendMessage]);

  return { messages, isLoading, sendMessage };
}

export default useWebSocket;
