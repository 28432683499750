/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable @next/next/inline-script-id */
/* eslint-disable import/no-extraneous-dependencies */
import { lazy, PropsWithChildren, Suspense, useEffect, useState } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ToastContainer } from 'react-toastify';
import AOS from 'aos';
import App from 'next/app';
import Router from 'next/router';
import Script from 'next/script';
import { SessionProvider, signOut } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import { cacheExchange, Client, fetchExchange, Provider } from 'urql';
import Toast from '@/components/Common/Toast';
import LoadingPage from '@/components/Common/LoadingPage';
import InviteFriendModal from '@/components/2d-game/InviteFriendModal/InviteFriendModal';
// import usePageTracking from '@/hooks/usePageTracking/usePageTracking';
import SignWithReferralModal from '@/components/Referral/_signupWithReferralModal';
import { _getMetaTags } from '@/service/service';
// import GoogleAnalytics from '@/utils/GoogleAnalytics';
import { GTMPageView } from '@/utils/gtm';
import 'aos/dist/aos.css';
import '../styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/fonts.css';
import 'intro.js/introjs.css';
import nextI18nextConfig from '../next-i18next.config';
import React from 'react';
import Cookies from 'js-cookie';
import LayoutPage from '@/components/Common/LayoutPage';
import dynamic from 'next/dynamic';
import ItemInformationSuccess from '@/components/Blueprint/components/Modals/ItemInformationSuccess';
// import ContextProvider from '@/components/appkit/appkit-context-provider';

// const ContextProvider = lazy(() => import('@/components/appkit/appkit-context-provider'));
const ContextProvider = dynamic(() => import('@/components/appkit/appkit-context-provider'), { ssr: true });

const AblySocketConfig = dynamic(() => import('@/components/AblySocketConfig/AblySocketConfig'), { ssr: false });

// theGraph client mumbai
// const client = new Client({
//   url: 'https://api.studio.thegraph.com/query/71429/landrocker_test/version/latest',
//   exchanges: [cacheExchange, fetchExchange],
// });

// theGraph client mainnet
const client = new Client({
  url: 'https://main-graph.landrocker.io/subgraphs/name/landrocker_io/',
  exchanges: [cacheExchange, fetchExchange],
});

const MyApp = ({ Component, pageProps, metaTags }: { Component: any; pageProps: any; metaTags?: any }) => {
  // const createStore = useCreateStore(pageProps.initialZustandState);
  useEffect(() => {
    AOS.init({
      easing: 'ease-in-back',
      once: true,
      offset: 50,
    });
    // initialWeb3();
  }, []);

  // useScrollToTop();
  const [loading, SetLoading] = useState(false);
  // const [isShowZoho, setIsShowZoho] = useState(false);

  // let isShowVideo = typeof window !== 'undefined' && sessionStorage.getItem('isShowVideo');
  // useEffect(() => {
  //   if (isShowVideo == 'true') {
  //     setIsShowZoho(true);
  //   } else {
  //     setIsShowZoho(false);
  //   }
  // }, [isShowVideo]);

  useEffect(() => {
    const handleRouteChange = (url: string) => GTMPageView(url);
    Router.events.on('routeChangeStart', (url) => {
      if (url.includes('assetType=')) {
      } else {
        SetLoading(true);
        handleRouteChange(url);
      }
    });

    Router.events.on('routeChangeComplete', (url) => {
      SetLoading(false);
      handleRouteChange(url);
    });

    if (typeof window !== 'undefined') {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (typeof window !== 'undefined') {
        const newAuth = localStorage.getItem('newAuth');

        if (newAuth === undefined || newAuth === null) {
          ['next-auth.session-token', 'next-auth.csrf-token', 'next-auth.callback-url', 'next-auth.redirect-url', 'next-auth.state'].forEach(
            (cookie) => {
              Cookies.remove(cookie);
            },
          );
          // console.log('All cookies removed');
          try {
            await signOut({ redirect: false, callbackUrl: '/' });
          } catch (error) {
          } finally {
            localStorage.setItem('newAuth', 'true');
          }
        }
      }
    })();
  }, []);

  const res = (
    <SkeletonTheme baseColor='#202020' highlightColor='#444'>
      {/* <Provider createStore={createStore}> */}
      <LayoutPage metaTags={metaTags}>
        {loading && <LoadingPage />}
        <Component {...pageProps} />

        <ToastContainer limit={1} newestOnTop={true} />
        <Toast />
        <InviteFriendModal />
        <AblySocketConfig />
        <ItemInformationSuccess />
        <SignWithReferralModal />

        {/* <NFTSuccessModal /> */}
      </LayoutPage>
      {/* </Provider> */}
    </SkeletonTheme>
  );

  return (
    <>
      <Script strategy='afterInteractive' src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`} />

      <Script strategy='afterInteractive'>
        {`
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                      page_path: window.location.pathname,
                      });
                  `}
      </Script>
      <Script
        id='twitter-pixel'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
            },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
            twq('config','ohm9s');`,
        }}
      />
      {/*  zoho iframe */}
      {/* <Script src='https://desk.zoho.com/portal/api/feedbackwidget/974690000000273003?orgId=849954020&displayType=iframe'></Script>
      <Script
        id='zsiqchat'
        dangerouslySetInnerHTML={{
          __html:
            'var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siq5852579f0de506c910036f3bafed7a2a51b7478b32c57e3c6edb010a48159011", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);',
        }}
      /> */}

      <SessionProvider refetchOnWindowFocus={false} session={pageProps.session}>
        <Provider value={client}>
          {/* <Suspense fallback={ <>{res}</>}> */}
          {/* <Suspense fallback={<div>{res}</div>}> */}
          <ContextProvider>{res}</ContextProvider>
          {/* </Suspense> */}
        </Provider>
      </SessionProvider>
    </>
  );
};

const fallbackMetaImage = 'https://cdn.landrocker.io/website_content/landing/metatag/Immutable_poster%20Version_04.png';

MyApp.getInitialProps = async (appContext) => {
  // const { route: url } = appContext.router;
  // const routes = url.split('/');
  // let metaTags = {
  //   title: '',
  //   description: '',
  //   imageUrl: '',
  //   isHome: false,
  // };
  // if (routes.length <= 2) {
  //   const route = routes[1];
  //   if (route === '') {
  //     metaTags = {
  //       title: 'Home',
  //       description:
  //         'LandRocker is a play-to-earn game about discovery and space exploration where players compete to uncover valuable resources such as NFTs, crypto tokens, crafting materials and more.',
  //       imageUrl: fallbackMetaImage,
  //       isHome: true,
  //     };
  //   } else {
  //     await _getMetaTags(route)
  //       .then((res) => {
  //         metaTags = res;
  //       })
  //       .catch(() => {
  //         metaTags = {
  //           title: 'LandRocker',
  //           description:
  //             'LandRocker is a play-to-earn game about discovery and space exploration where players compete to uncover valuable resources such as NFTs, crypto tokens, crafting materials and more.',
  //           imageUrl: fallbackMetaImage,
  //           isHome: false,
  //         };
  //       });
  //   }
  // } else {
  //   metaTags = undefined;
  // }

  let metaTags = {
    title: 'LandRocker',
    description:
      'LandRocker is a play-to-earn game about discovery and space exploration where players compete to uncover valuable resources such as NFTs, crypto tokens, crafting materials and more.',
    imageUrl: fallbackMetaImage,
    isHome: false,
  };
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps, metaTags };
};
export default appWithTranslation(MyApp, nextI18nextConfig);
