import { StateCreator } from 'zustand';
import { EMiningCardType } from '@/components/MiningCards/MiningCard';
import { IActiveBuyItem } from '@/types/Marketplace';
import { IMarketplaceSlice } from './Interface';

export const marketplaceSlice: StateCreator<IMarketplaceSlice> = (set) => ({
  //state initializing
  isShowBuyModal: false,
  switchPaymentModal: false,
  activeBuyItem: {
    image: '',
    name: '',
    value: '',
    price: 0,
    titleModal: 'Buy Material',
  },
  isRepeatRequest: false,
  marketplaceList: [
    { data: { count: 1, data: [], page: 0 }, type: EMiningCardType.Rover },
    { data: { count: 1, data: [], page: 0 }, type: EMiningCardType.Fuel },
    { data: { count: 1, data: [], page: 0 }, type: EMiningCardType.Lootbox },
    { data: { count: 1, data: [], page: 0 }, type: EMiningCardType.Material },
    { data: { count: 1, data: [], page: 0 }, type: EMiningCardType.Planet },
    { data: { count: 1, data: [], page: 0 }, type: EMiningCardType.Companion },
    { data: { count: 1, data: [], page: 0 }, type: EMiningCardType.PrimaryWeapon },
    { data: { count: 1, data: [], page: 0 }, type: EMiningCardType.SecondaryWeapon },
    { data: { count: 1, data: [], page: 0 }, type: EMiningCardType.Suit },
  ],

  //state handler functions
  setMarketplaceList: (marketplaceList: any) => {
    set({
      marketplaceList: marketplaceList,
    });
  },
  setIsShwoBuyModal: (isShowBuyModal: boolean) => {
    set({
      isShowBuyModal: isShowBuyModal,
    });
  },
  setSwitchPaymentModal: (switchPaymentModal: boolean) => {
    set({
      switchPaymentModal: switchPaymentModal,
    });
  },
  setActiveBuyItem: (activeBuyItem: IActiveBuyItem) => {
    set({
      activeBuyItem: activeBuyItem,
    });
  },
  setIsRepeatRequest: (isRepeatRequest: boolean) => {
    set({
      isRepeatRequest: isRepeatRequest,
    });
  },
});
