import * as React from 'react';

const BlogSvg = (props) => (
  <svg width={19} height={13} viewBox='0 0 19 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2462_818)'>
      <path
        d='M3.885 2.67532C3.81574 2.91182 3.95425 3.1631 4.20051 3.22961C4.86231 3.41438 5.35482 3.58436 5.87042 3.76912C6.37831 3.9465 6.9093 4.13126 7.60958 4.33081C7.65575 4.34559 7.69423 4.34559 7.7404 4.34559C7.94048 4.34559 8.13287 4.21995 8.18674 4.0204C8.256 3.7839 8.11748 3.53263 7.87123 3.46611C7.20172 3.28135 6.71691 3.11136 6.19362 2.9266C5.68572 2.74923 5.15474 2.56446 4.46215 2.37231C4.2159 2.2984 3.95425 2.43882 3.885 2.67532Z'
        fill='white'
      />
      <path
        d='M7.87123 5.49079C7.20172 5.30603 6.71691 5.13604 6.19362 4.95128C5.68572 4.7739 5.15474 4.58914 4.46215 4.39699C4.2159 4.33047 3.95425 4.4635 3.885 4.7C3.81574 4.9365 3.95425 5.18778 4.20051 5.25429C4.86231 5.43905 5.35482 5.60904 5.87042 5.7938C6.37831 5.97118 6.9093 6.15594 7.60958 6.35548C7.65575 6.37027 7.69423 6.37027 7.7404 6.37027C7.94048 6.37027 8.13287 6.24463 8.18674 6.04508C8.256 5.80858 8.11748 5.5573 7.87123 5.49079Z'
        fill='white'
      />
      <path
        d='M7.87123 7.51618C7.20172 7.33142 6.71691 7.16143 6.20132 6.97667C5.68572 6.79929 5.15474 6.61453 4.46215 6.42238C4.2159 6.35586 3.95425 6.48889 3.885 6.72539C3.81574 6.96189 3.95425 7.21317 4.20051 7.27968C4.86231 7.46445 5.35482 7.63443 5.87042 7.81919C6.37831 7.99657 6.9093 8.18133 7.60958 8.37348C7.65575 8.38827 7.69423 8.38827 7.7404 8.38827C7.94048 8.38827 8.13287 8.26263 8.18674 8.06308C8.256 7.83397 8.11748 7.59008 7.87123 7.51618Z'
        fill='white'
      />
      <path
        d='M7.87123 9.54841C7.20172 9.36364 6.71691 9.19366 6.19362 9.00889C5.68572 8.83152 5.15474 8.64676 4.46215 8.4546C4.2159 8.38809 3.95425 8.52112 3.885 8.75762C3.81574 8.99411 3.95425 9.24539 4.20051 9.31191C4.86231 9.49667 5.35482 9.66666 5.87042 9.85142C6.37831 10.0288 6.9093 10.2136 7.60958 10.4131C7.65575 10.4279 7.69423 10.4279 7.7404 10.4279C7.94048 10.4279 8.13287 10.3022 8.18674 10.1027C8.256 9.8662 8.11748 9.61492 7.87123 9.54841Z'
        fill='white'
      />
      <path
        d='M18.5306 1.7366H16.8838V0.450645C16.8838 0.325006 16.8299 0.206757 16.7375 0.12546C16.6452 0.0367736 16.5144 -0.000179245 16.3836 0.00721132C14.9291 0.103289 13.8441 0.51716 12.7898 0.90886C11.851 1.25622 10.966 1.58879 9.82705 1.72182C9.61158 1.74399 9.38072 1.74399 9.16525 1.72182C8.03402 1.59618 7.14135 1.26361 6.20251 0.90886C5.15593 0.50977 4.06318 0.103289 2.60875 -0.000179246C2.47793 -0.00756981 2.3548 0.0367736 2.25476 0.11807C2.16241 0.199366 2.10855 0.325006 2.10855 0.443255V1.7366H0.469421C0.207776 1.7366 0 1.93615 0 2.18743V12.549C0 12.7929 0.207776 12.9998 0.469421 12.9998H18.5306C18.7845 12.9998 19 12.8003 19 12.549V2.18743C19 1.93615 18.7922 1.7366 18.5306 1.7366ZM9.02673 11.8321C7.95707 11.6917 7.10288 11.3739 6.21021 11.0413C5.26367 10.6866 4.29405 10.3244 3.04739 10.1766V4.17549V0.945813C4.11705 1.08623 4.97124 1.40403 5.87161 1.7366C6.81815 2.09135 7.78777 2.45349 9.03443 2.6013V11.8321H9.02673ZM15.9526 10.1766C14.7136 10.317 13.7363 10.6866 12.7898 11.0413C11.8894 11.3739 11.0352 11.6991 9.97327 11.8321V2.6013C11.2122 2.46088 12.1895 2.09135 13.1361 1.7366C14.0365 1.39664 14.8906 1.07884 15.9603 0.945813V4.17549V10.1766H15.9526Z'
        fill='white'
      />
      <path
        d='M11.256 4.34559C11.3022 4.34559 11.3406 4.3382 11.3868 4.33081C12.0871 4.13865 12.6104 3.95389 13.126 3.76912C13.6416 3.58436 14.1341 3.41438 14.7959 3.22961C15.0421 3.1631 15.1883 2.91182 15.1114 2.67532C15.0421 2.43882 14.7805 2.2984 14.5342 2.37231C13.8416 2.56446 13.3107 2.74923 12.8028 2.9266C12.2872 3.11136 11.7947 3.28135 11.1252 3.46611C10.8789 3.53263 10.7327 3.7839 10.8096 4.0204C10.8635 4.21995 11.0559 4.34559 11.256 4.34559Z'
        fill='white'
      />
      <path
        d='M14.5342 4.39699C13.8416 4.58914 13.3107 4.7739 12.8028 4.95128C12.2872 5.13604 11.7947 5.30603 11.1252 5.49079C10.8789 5.5573 10.7327 5.80858 10.8096 6.04508C10.8712 6.24463 11.0559 6.37027 11.256 6.37027C11.3022 6.37027 11.3406 6.36288 11.3868 6.35548C12.0871 6.16333 12.6104 5.97857 13.126 5.7938C13.6416 5.60904 14.1341 5.43905 14.7959 5.25429C15.0421 5.18778 15.1883 4.9365 15.1114 4.7C15.0421 4.4635 14.7805 4.33047 14.5342 4.39699Z'
        fill='white'
      />
      <path
        d='M14.5343 6.42229C13.8418 6.61444 13.3108 6.79921 12.8029 6.97658C12.2873 7.16135 11.7948 7.33133 11.133 7.51609C10.8867 7.58261 10.7405 7.83389 10.8175 8.07038C10.879 8.26993 11.0637 8.39557 11.2638 8.39557C11.31 8.39557 11.3484 8.38818 11.3946 8.38079C12.0949 8.18863 12.6182 8.00387 13.1338 7.8265C13.6494 7.64173 14.1419 7.47175 14.8037 7.28699C15.0499 7.22047 15.1962 6.96919 15.1192 6.73269C15.0422 6.49619 14.7806 6.35577 14.5343 6.42229Z'
        fill='white'
      />
      <path
        d='M14.5342 8.45483C13.8416 8.64698 13.3107 8.83174 12.8028 9.00912C12.2872 9.19388 11.7947 9.36386 11.1252 9.54863C10.8789 9.61514 10.7327 9.86642 10.8096 10.1029C10.8712 10.3025 11.0559 10.4281 11.256 10.4281C11.3022 10.4281 11.3406 10.4207 11.3868 10.4133C12.0871 10.2212 12.6104 10.0364 13.126 9.85164C13.6416 9.66688 14.1341 9.4969 14.7959 9.31213C15.0421 9.24562 15.1883 8.99434 15.1114 8.75784C15.0421 8.52134 14.7805 8.38092 14.5342 8.45483Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_2462_818'>
        <rect width={19} height={13} fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export default BlogSvg;
