/* eslint-disable @typescript-eslint/ban-ts-comment */
import { StateCreator } from 'zustand';
import {
  IGetPlanetsPlanItem,
  IGetPlanetsRecipeItem,
  IStartMining,
  IUserRoverData,
} from '@/types/DTO/miniGame';
import { IMiningSlice } from './Interface';

export const miningSlice: StateCreator<IMiningSlice> = (set, get) => ({
  activeItemMining: {
    activeRover: {
      assetsName: '---',
      prefix: '',
      image: '',
      id: '',
      fuel: 0,
      inventoryId: '',
      assetId: '',
    },
    activePlanet: {
      Count: '---',
      Created_at: '---',
      Distance: '---',
      Element_per_block: '---',
      Galaxy: '---',
      Level: '---',
      Lock: false,
      Name: '---',
      Published_at: '---',
      Updated_at: '---',
      Fuel: '1',
      Picture: [
        {
          Id: 0,
          Name: '',
          Url: '',
          Formats: {
            Large: { Url: '', Width: 0, Height: 0 },
            Medium: { Url: '', Width: 0, Height: 0 },
            Small: { Url: '', Width: 0, Height: 0 },
            Thumbnail: { Url: '', Width: 0, Height: 0 },
          },
        },
      ],
      Id: '',
    },
    activeBluePrint: {
      id: '',
      assetId: '',
      assetName: '---',
      assetImageThumbnail: '',
      assetImageSmal: '',
      assetPrefix: '',
      typeId: '',
      typeName: '',
      recipId: '',
      recipeName: '',
      materials: [],
      perFixMaterials: '',
    },
    prifixImage: '',
    fuelActiveRover: 0,
  },

  dataMining: {
    hours: 0,
    minutes: 0,
    seconds: 0,
    maxSeconds: 0,
  },

  startMining: {
    end_time: 0,
    start_time: 0,
    message: '',
    status: true,
  },
  craftReward: {
    data: {
      Created_at: '',
      Day: '',
      Id: 0,
      Published_at: '',
      Reward: [],
      Updated_at: '',
    },
    material_prefix: '',
    dateTimeStamp: '',
    isReset: false,
  },

  dataMiningReward: {
    Reward: [],
    all_blocks: 0,
    blocks_count: 0,
    end_time: 0,
    mileage: 0,
    start_time: 0,
    all_blocks_unit: '',
    Reward_unit: '',
    mileage_unit: '',
    additionial: {
      newYearEvent: false,
      isSpecialPrize: false,
    },
  },

  craftedNFT: {
    PrifixAddress: '',
    Image: '',
  },
  freeRover: {
    PrifixAddress: '',
    Image: '',
  },
  lastSecondStartMining: 0,
  levelUp: {
    isShowLevelUp: false,
    data: {
      old_level: 0,
      new_level: 0,
      reward: '',
    },
  },

  planetUserData: [{ Planet_id: 0, authorize: false }],

  levelUpRewards: [],

  currentTime: 0,
  rovers: [],

  newYearEvent: false,
  isSpecialPrize: false,

  achievement: {
    action: '',
    value: '',
  },

  setActivePlanet: (activePlanet: IGetPlanetsPlanItem, prifixImage: string) => {
    set({
      activeItemMining: {
        ...get().activeItemMining,
        //@ts-ignore
        activePlanet: activePlanet,
        prifixImage: prifixImage,
      },
    });
  },

  setDefaultActiveMining: () => {
    set({
      activeItemMining: {
        activeRover: {
          assetsName: '---',
          assetId: '',
          prefix: '',
          image: '',
          id: '',
          fuel: 0,
          inventoryId: '',
        },
        activePlanet: {
          Count: '---',
          Created_at: '---',
          Distance: '---',
          Lock: false,
          Element_per_block: '---',
          Galaxy: '---',
          Level: '---',
          Name: '---',
          Published_at: '---',
          Updated_at: '---',
          Fuel: '1',
          Picture: [
            {
              Id: 0,
              Name: '',
              Url: '',
              Formats: {
                Large: { Url: '', Width: 0, Height: 0 },
                Medium: { Url: '', Width: 0, Height: 0 },
                Small: { Url: '', Width: 0, Height: 0 },
                Thumbnail: { Url: '', Width: 0, Height: 0 },
              },
            },
          ],
          Id: '',
        },
        activeBluePrint: {
          id: '',
          assetId: '',
          assetName: '---',
          assetImageThumbnail: '',
          assetImageSmal: '',
          assetPrefix: '',
          typeId: '',
          recipId: '',
          typeName: '',
          recipeName: '',
          materials: [],
          perFixMaterials: '',
        },
        prifixImage: '',
        fuelActiveRover: 0,
      },
    });
  },
  setActiveRover: (activeRover: IUserRoverData) => {
    set({
      activeItemMining: {
        ...get().activeItemMining,
        //@ts-ignore
        activeRover: activeRover,
      },
    });
  },
  setFuelActiveRover: (fuel: number) => {
    set({
      activeItemMining: {
        ...get().activeItemMining,
        //@ts-ignore
        fuelActiveRover: fuel,
      },
    });
  },
  setActiveBluePrint: (activeBlueprint: IGetPlanetsRecipeItem) => {
    set({
      activeItemMining: {
        ...get().activeItemMining,
        //@ts-ignore
        activeBluePrint: activeBlueprint,
      },
    });
  },

  setTimeMiningData: (dataMining: any) => {
    set({
      dataMining: dataMining,
    });
  },
  setStartMining: (startMining: IStartMining) => {
    set({
      startMining: startMining,
    });
  },
  setCraftReward: (item: any, prifix: string, time: any, isReset: boolean) => {
    set({
      craftReward: {
        data: item,
        material_prefix: prifix,
        dateTimeStamp: time,
        isReset: isReset,
      },
    });
  },

  setDataMiningReward: (dataMiningReward: any) => {
    set({
      dataMiningReward: dataMiningReward,
    });
  },
  setCraftedNFT: (craftedNFTItem: any) => {
    set({ craftedNFT: craftedNFTItem });
  },
  setFreeRover: (freeRover: any) => {
    set({ freeRover: freeRover });
  },
  setLastSecondStartMining: (lastSecond: number) => {
    set({ lastSecondStartMining: lastSecond });
  },
  setPlanetUserData: (planetUserData: any) => {
    set({
      planetUserData: planetUserData,
    });
  },
  setLevelUpRewards: (levelUpReward: any) => {
    //@ts-ignore
    set({ levelUpRewards: [...levelUpReward] });
  },
  setCurrentTime: (currentTime: number) => {
    set({
      currentTime: currentTime,
    });
  },
  setRovers: (rovers: any) => {
    //@ts-ignore
    set({ rovers: [...rovers] });
  },
  setNewYearEvent: (newYearEvent: boolean) => {
    set({ newYearEvent });
  },
  setIsSpecialPrize: (isSpecialPrize: boolean) => {
    set({ isSpecialPrize });
  },
  setLevelUp: (data: any) => {
    set({
      levelUp: {
        ...get().levelUp,
        data: data,
      },
    });
  },
  setIsShowLevelUp: (isShow: boolean) => {
    set({
      levelUp: {
        ...get().levelUp,
        isShowLevelUp: isShow,
      },
    });
  },
  setAchievement: (achievement: any) => {
    set({
      achievement,
    });
  },
});
