import {
  Basket,
  CompanionSvg,
  EconomySvg,
  Financial,
  NewFuelSvg,
  PlayIconSvg,
  PrimaryWeapon,
  Roadmap,
  RoversSvg,
  SecondaryWeapon,
  TasksSvg,
} from '@/components/Svg';
import BlogSvg from '@/components/Svg/Updates/BlogSvg';
import BreakingNewsSvg from '@/components/Svg/Updates/BreakingNewsSvg';
import NewsSvg from '@/components/Svg/Updates/NewsSvg';
import PressSvg from '@/components/Svg/Updates/PressSvg';

export { default } from './Header';

export const MENU_LINKS: Array<{
  label: string;
  labelFallback?: string;
  href?: string;
  icon?: any;
  badge?: string;
  subLinks?: Array<{
    icon?: any;
    label: string;
    href: string;
    description: string;
    disabled?: boolean;
  }>;
}> = [
  {
    label: 'header:playTitle', // Translation key for Play Title
    labelFallback: 'Play',
    href: '/start-mining',
    icon: PlayIconSvg,
    badge: 'Beta',
  },
  {
    label: 'header:cc', // Translation key for Command Center
    labelFallback: 'Command Center',
    href: '/command-center',
    icon: TasksSvg,
    badge: 'Beta',
  },
  {
    label: 'header:marketTitle', // Translation key for Marketplace Title
    labelFallback: 'Marketplace',
    href: 'https://marketplace.landrocker.io/',
    icon: Basket,
    // subLinks: [
    //   {
    //     label: 'header:marketRoverTitle', // Translation key for Rover Title
    //     href: '/marketplace?assetType=Rover',
    //     description: 'header:marketRoverDesc', // Translation key for Rover Description
    //     icon: RoversSvg,
    //   },
    //   {
    //     label: 'header:marketTacticalTitle', // Translation key for Tactical Tools Title
    //     href: '/marketplace?assetType=TacticalTools',
    //     description: 'header:marketTacticalDesc', // Translation key for Tactical Tools Description
    //     icon: SecondaryWeapon,
    //   },
    //   {
    //     label: 'header:marketFuelTitle', // Translation key for Fuel Title
    //     href: '/marketplace?assetType=Fuel',
    //     description: 'header:marketFuelDesc', // Translation key for Fuel Description
    //     icon: NewFuelSvg,
    //   },
    //   {
    //     label: 'header:marketCompanionTitle', // Translation key for Companion Title
    //     href: '/marketplace?assetType=Companion',
    //     description: 'header:marketCompanionDesc', // Translation key for Companion Description
    //     icon: CompanionSvg,
    //   },
    //   {
    //     label: 'header:marketWeaponTitle', // Translation key for Weapon Title
    //     href: '/marketplace?assetType=Weapon',
    //     description: 'header:marketWeaponDesc', // Translation key for Weapon Description
    //     icon: PrimaryWeapon,
    //   },
    // ],
  },

  {
    label: 'header:updateTitle', // Translation key for Updates Title
    labelFallback: 'Updates',
    subLinks: [
      {
        label: 'header:updateNewsTitle', // Translation key for News Title
        description: 'header:updateNewsDesc', // Translation key for News Description
        href: '/news',
        icon: NewsSvg,
      },
      {
        label: 'header:updateBlogTitle', // Translation key for Blog Title
        description: 'header:updateBlogDesc', // Translation key for Blog Description
        href: '/blogs',
        icon: BlogSvg,
      },
      {
        label: 'header:updateBreakingNewsTitle', // Translation key for Breaking News Title
        description: 'header:updateBreakingNewsDesc', // Translation key for Breaking News Description
        href: '/breaking-news',
        icon: BreakingNewsSvg,
      },
      {
        label: 'header:updatePressTitle', // Translation key for Press Title
        description: 'header:updatePressDesc', // Translation key for Press Description
        href: '/press',
        icon: PressSvg,
      },
      {
        label: 'header:roadmap', // Translation key for Roadmap
        description: 'Discover upcoming features and plans for LandRocker.', // Translation key for Press Description
        href: '/roadmap',
        icon: Roadmap,
      },
    ],
  },
  {
    label: 'header:defi', // Translation key for Updates Title
    labelFallback: 'DeFi',
    subLinks: [
      {
        label: 'header:staking', // Translation key for Staking
        description: 'header:stakingDesc',
        href: '/stake',
        icon: Financial,
      },
      {
        label: 'header:economy', // Translation key for Economy
        description: 'header:economyDesc',
        href: '/how-to-play-mine-earn',
        icon: EconomySvg,
      },
    ],
  },

  // {
  //   label: 'header:inventory', // Translation key for Inventory
  //   href: '/inventory',
  // },

  {
    label: 'header:events', // Translation key for Inventory
    labelFallback: 'Events',
    href: '/events',
  },
];
