export enum EAuthVarient {
  null = 0,
  SIGN_UP = 1,
  SIGN_IN = 2,
  FORGOT = 3,
  CHANGE = 4,
  REFERRAL = 5,
  TWOFA = 6,
  ENABLETWOFA = 7,
  VERIFYCODE = 8,
  PHONE = 9,
  JOIN_OUR_WHITE_LIST = 10,
}

export enum EForgetPassword {
  null = 0,
  CODE = 1,
  PASSWORD = 2,
  MOBILE = 3,
}
