import React from 'react';
import classNames from 'classnames';
import { Button } from '@/components/Atoms';
import styles from './StepperClubContent.module.scss';

interface IProps {
  variant: string;
  buttonLabel: string;
  step: string;
  stepDesc: string;
  onClick: () => void;
  number: string;
}

const StepCard = (props: IProps) => {
  const { variant, buttonLabel, step, stepDesc, number, onClick } = props;
  const isLoginCard = variant === 'loginCard';
  const StepCardClassNames = classNames(styles.stepCard, {
    [styles.loginCard]: isLoginCard,
    [styles.connectWalletCard]: !isLoginCard,
  });
  const buttonClassNames = classNames({
    [styles.loginVariant]: isLoginCard,
    [styles.connectWalletVariant]: !isLoginCard,
  });
  const circleClassNames = classNames({
    [styles.loginCircle]: isLoginCard,
    [styles.connectWalletCircle]: !isLoginCard,
  });
  const stepTitleClassNames = classNames(styles.stepTitle, {
    ['text-[#27FF00]']: isLoginCard,
    ['text-[#29D7DF]']: !isLoginCard,
  });

  return (
    <div className={StepCardClassNames}>
      <div className={styles.stepContainer}>
        <div className={circleClassNames}>{number}</div>

        <div className={styles.stepTextContainer}>
          <span className={stepTitleClassNames}>{step}</span>
          <span className={styles.stepDesc}>{stepDesc}</span>
        </div>
      </div>

      <div className='w-full sm:w-auto flex justify-center mt-3'>
        <Button className={buttonClassNames} onClick={onClick}>
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
};

export default StepCard;
