//@ts-ignore
import { Environment } from '@imtbl/sdk/config';
//@ts-ignore
import { Passport } from '@imtbl/sdk/passport';

const recovery = 'ZRL5 - ELNW - 24YZ - EG9S - VSW1 - DAL3';

export const passportInstance = new Passport({
  baseConfig: {
    environment: Environment.PRODUCTION, // or config.Environment.SANDBOX
    publishableKey: process.env.NEXT_PUBLIC_PUBLISHABLE_KEY, // replace with your publishable API key from Hub
  },
  clientId: process.env.NEXT_PUBLIC_IMMUTABLE_PASSPORT_CLIENT_ID, // replace with your client ID from Hub
  redirectUri: `${process.env.NEXT_PUBLIC_HOSTNAME || 'https://landrocker.io'}/redirect`, // replace with one of your redirect URIs from Hub
  logoutRedirectUri: `${process.env.NEXT_PUBLIC_HOSTNAME || 'https://landrocker.io'}/logout-silent`, // replace with one of your logout URIs from Hub
  audience: 'platform_api',
  scope: 'openid offline_access email transact',
  logoutMode: 'silent',
});

export const getSessionForImtbl = async () => {
  const passportProvider = await passportInstance.connectEvm();
  const accounts = await passportProvider.request({
    method: 'eth_requestAccounts',
  });
  try {
    const userProfile = await passportInstance.login({
      useCachedSession: true,
    });
    if (userProfile) {
      const immutableAccessToken = await passportInstance.getAccessToken();
      return {
        immutableAccessToken,
        accounts,
        userProfile,
      };
    }
  } catch (error) {
    return null;
  }
};
