import * as React from 'react';

const ConnectedWalletSvg = (props) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={19}
      style={{ fill: isHovered ? '#979797' : 'white', transition: 'fill 0.3s ease' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      <g clipPath='url(#a)'>
        <path
          fill={isHovered ? '#979797' : '#31DA60'} // Hover effect for the first path
          d='M5.575 19C2.457 19 0 16.578 0 13.505 0 10.43 2.457 8.01 5.575 8.01c.661 0 1.323.093 1.984.372L6.992 9.78A3.84 3.84 0 0 0 5.575 9.5c-2.268 0-4.063 1.77-4.063 4.005 0 2.142 1.795 4.005 4.063 4.005s4.063-1.77 4.063-4.005h1.417C11.15 16.578 8.598 19 5.575 19Z'
        />
        <path
          fill={isHovered ? '#979797' : '#31DA60'} // Hover effect for the second path
          d='m5.478 15.647-2.173-2.422.945-1.117 1.133 1.304L8.88 9.034l1.04 1.025-4.441 5.588Z'
        />
        <path
          fill={isHovered ? '#979797' : '#fff'} // Hover effect for the third path
          d='M23.996 6.985c0-1.676-.755-2.421-2.456-2.421H6.327c-.189 0-1.04-.932-1.134-1.211-.094-.373.19-.652.567-.652h16.158c.567-.093.756-.373.756-.838C22.579.838 21.729.093 20.5 0H5.382c-1.89 0-2.929 1.118-2.929 2.98v4.285c1.04-.466 2.079-.745 3.307-.745 4.158 0 7.465 3.26 7.465 7.357 0 1.863-.662 3.54-1.795 4.844h10.11c1.7 0 2.456-.745 2.456-2.422V6.985Zm-4.063 6.427c-1.039 0-1.984-.839-1.984-1.956 0-1.025.85-1.956 1.984-1.956 1.04 0 1.985.838 1.985 1.956-.095 1.024-.945 1.956-1.985 1.956Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h24v19H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ConnectedWalletSvg;
