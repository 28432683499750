import { useStore } from '@/zustand/store/store';

const useConnectWallet = () => {
  const { setIsModalOpen } = useStore();

  const handleClickWallet = () => {
    setIsModalOpen('wallet', true);
  };

  return {
    handleClickWallet,
  };
};

export default useConnectWallet;
