/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable sonarjs/no-ignored-return */
import axios, { AxiosError, Canceler } from 'axios';
import { getSession, signOut } from 'next-auth/react';
import { useStore } from '@/zustand/store/2dGameSore';

let cancel: [string, Canceler][] = [];

export const cancelAllRequests = () => {
  cancel.map((item) => {
    item[1]();
  });
};

const baseURL = process.env.REACT_APP_BASE_URL_TWO;
// || 'https://srvs30.landrocker.io'
export const axiosInstanceTwo = axios.create({
  baseURL,
  headers: {},

  // timeout: 5000,
});

// Add a response interceptor
axiosInstanceTwo.interceptors.request.use(
  async function (response) {
    // const lang = document.documentElement.lang || 'ar'; // Default to 'en' if not set
    // const lang = 'ar'; // Default to 'en' if not set
    // const url = new URL(response.url, response.baseURL || baseURL);
    // url.searchParams.append('lang', lang);
    // response.url = url.pathname + url.search;
    if (!response?.headers.token) {
      const session = await getSession();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (session !== null && session?.valid_token) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        response.headers = { token: session.accessToken };
      }
    }
    // const CancelToken = axios.CancelToken;

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosInstanceTwo.interceptors.response.use(
  function (response) {
    if (response.config.method === 'post' || (response.config.method === 'POST' && response.status === 200 && response?.data.message)) {
    }

    cancel = cancel.filter((fid) => fid[0] !== response.config.url);
    return response;
  },
  async function (error: AxiosError) {
    // const test = JSON.stringify(error);
    cancel = cancel.filter((fid) => {
      if (error.response && error.response?.config) {
        return fid[0] !== error.response.config.url;
      }
    });
    if (error?.response?.status == 401) {
      const url = window.location.href;
      // const { clearData } = useStore();
      localStorage.removeItem('kitblockRefreshToken');
      localStorage.removeItem('kitblockRefreshToken');
      // await signOut({ redirect: true, callbackUrl: `${url}?error=401` });
      // clearData();
      localStorage.removeItem('2dGame');
    }
    if (error?.response?.status == 422) {
      return error?.response;
      // const url = window.location.href;
      // const { signMessage } = useSignMessage({({
      //   isShow: true,
      //   message: 'Swap has been successful',
      //   method: 'success',
      // });
    }

    if (error.response == undefined) {
      // window.location.href = '/500';
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);
