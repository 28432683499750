import React from 'react';
import Link from 'next/link';
import { useStore } from '@/zustand/store/store';

const MailBoxLink = () => {
  const { notifier } = useStore();
  return (
    <div className='flex gap-1'>
      <Link href='/mail-box'>Inbox</Link>
      {notifier.newMail && <div className='header_notifier_badge'></div>}
    </div>
  );
};

export default MailBoxLink;
