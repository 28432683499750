/* eslint-disable sonarjs/no-duplicate-string */
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useResponsive } from '@/hooks';
import { useStore } from '@/zustand/store/store';

const Toast = (props) => {
  const { isShowToast, setIsShowToast } = useStore();
  const { isTablet } = useResponsive();
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (isShowToast.isShow) {
      switch (isShowToast.method) {
        case 'success':
          toast.success(`${isShowToast.message}`, {
            position: isShowToast.position == undefined ? 'bottom-center' : isShowToast.position,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => setIsShowToast({ isShow: false, message: '', method: 'default' }),
            style: {
              background: '#005a08',
              color: 'white',
              minWidth: isTablet ? '100%' : '600px',
            },
          });
          break;
          case 'info':
            toast.info(`${isShowToast.message}`, {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              onClose: () =>
                setIsShowToast({ isShow: false, message: '', method: 'default' }),
              style: {
                // background: 'blue',
                // color: 'white',
                minWidth: isTablet ? '100%' : '600px',
              },
            });
            break;
        case 'error':
          toast.error(`${isShowToast.message}`, {
            position: isShowToast.position == undefined ? 'bottom-center' : isShowToast.position,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => setIsShowToast({ isShow: false, message: '', method: 'default' }),
            style: {
              background: '#5a0000',
              color: 'white',
            },
          });
          break;
        case 'warning':
          toast.warning(`${isShowToast.message}`, {
            position: isShowToast.position == undefined ? 'bottom-center' : isShowToast.position,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => setIsShowToast({ isShow: false, message: '', method: 'default' }),
            style: {
              background: '#cab600',
              color: 'black',
              minWidth: isTablet ? '100%' : '600px',
            },
          });
          break;
      }
    }
  }, [isShowToast]);

  return <div></div>;
};

export default Toast;
