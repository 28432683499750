import React from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Typography } from '@/components/Atoms';
import ArrowDown3 from '@/components/Svg/ArrowDown3';
import styles from './Header.module.scss';
import { MENU_LINKS } from '.';
import IconMenu from './IconMenu';
import ChildHeaderSubLink from './ChildHeaderSubLink';
import Link from 'next/link';
import useTranslationStatus from '@/hooks/useTranslationStatus';

const HeaderSubLinks = () => {
  const { t } = useTranslation('header'); // Load translations from the 'header' namespace
  const router = useRouter();
  const { isReady } = useTranslationStatus();

  const isActive = (link: string) => router.asPath === link;

  return (
    <div className='hidden lg:flex lg:gap-4 xl:gap-6 h-full items-end justify-center w-fit '>
      {MENU_LINKS.map((link, index) => (
        <div key={index} className={classNames({ [styles.active]: isActive(link.href) })}>
          <div className='flex items-start justify-center flex-col min-h-[55px] relative hover:text-[#979797]'>
            <div className='cursor-pointer'>
              <Link href={link.href || '#'}>
                <a className={styles.link}>
                  {link.badge && <Typography className='absolute text-[9px] xl:text-[10px] text-[#29D6DF] -top-1 -right-3'>{link.badge}</Typography>}
                </a>
              </Link>
              {link.label === 'events' ? (
                <Link href={link.href || '#'}>
                  <a className={styles.link}>
                    <Typography size='s14ToS12' className='text-[#29D6DF]'>
                      {t(link.label as any, link.labelFallback)}
                      {/* {isReady ? t(link.label as any) : link.labelFallback} */}
                    </Typography>
                  </a>
                </Link>
              ) : (
                <IconMenu
                  icon={
                    <div className='h-14 flex justify-center flex-col'>
                      <Link href={link.href || '#'}>
                        <a>
                          <Typography size='s14ToS12'>{t(link.label as any, link.labelFallback)}</Typography>
                        </a>
                      </Link>
                      {link.subLinks && (
                        <div className='w-full mx-auto flex justify-center -mb-2'>
                          <ArrowDown3 />
                        </div>
                      )}
                    </div>
                  }
                >
                  {(link.subLinks ?? []).length > 0 && <ChildHeaderSubLink link={link} />}
                </IconMenu>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HeaderSubLinks;
