/* eslint-disable sonarjs/no-duplicate-string */
const errorMap = new Map<string, { userMessage: string }>();

errorMap.set('PreSale::Not valid address', { userMessage: 'The wallet address is not correct. Please check and try again.' });

errorMap.set('PreSale::Insufficient amount, equal to zero', { userMessage: 'Please specify an amount.' }); // withdraw - withdrawCoins

errorMap.set('LRTPreSale::You\'ve reached the daily buying limit', { userMessage: 'Limit reached. Please try again after 24 hours.' }); // _processPurchase
errorMap.set('PreSale::Fail create vesting', { userMessage: 'Error creating vesting plan. Please try again.' }); // _processPurchase

errorMap.set('LRTPreSale::sale is not active', { userMessage: 'The round has finished. Attempt failed.' }); // _calculateTotalPayment

errorMap.set('PreSale::LRT limit Exceeded', { userMessage: 'Limit exceeded. Please try a different amount.' }); // _validateAmount
errorMap.set('PreSale::Insufficient amount:Below minLrtPerUser', {
  userMessage: 'The amount selected is less than the minimum. Please select a different amount.',
}); // _validateAmount

errorMap.set('PreSale::You have reached the max LRT amount', { userMessage: 'Limit reached. Attempt failed.' }); // _validateUserBalanceLimit

errorMap.set('LRTPreSale::Price too old', { userMessage: 'Token price is not updated. Please try again later.' }); // _getTokenPrice

errorMap.set('PreSale::allowance error', { userMessage: 'Transaction failed. Could not transfer tokens.' }); // buyTokenByStableCoin
errorMap.set('PreSale::Fail transfer to contract', { userMessage: 'Transaction failed. Could not transfer tokens.' }); // buyTokenByStableCoin

errorMap.set('LRTVesting::Not valid address', { userMessage: 'The address is equal to zero' }); // validAddress

errorMap.set('LRTVesting::Plan is not exist', { userMessage: 'The specified plan to vest is not exist.' }); // createVesting
errorMap.set('LRTVesting::Amount is too low', { userMessage: 'The vesting amount is not correct.' }); // createVesting
errorMap.set('LRTVesting::StartDate is not valid', { userMessage: 'The vesting date is not correct.' }); // createVesting

errorMap.set('LRTVesting::Debt limit Exceeded', { userMessage: 'Insufficient tokens. Debt creating failed.' }); // setDebt

errorMap.set('LRTVesting::Not enough vested tokens', { userMessage: 'The release amount is not correct.' }); // _release

errorMap.set('LRTVesting::No vesting', { userMessage: 'No vesting found.' }); // _getClaimableToken
errorMap.set('LRTVesting::Only beneficiary can claim', { userMessage: 'Address is not correct. Please make sure and try again.' }); // _getClaimableToken
errorMap.set('PreSale::insufficient balance', { userMessage: 'Sorry! insufficient balance' });
errorMap.set('ERC20: transfer amount exceeds balance', { userMessage: 'ERC20: transfer amount exceeds balance' });
//assetMarketplace
errorMap.set('AssetMarketplace::The sell not exist', { userMessage: 'When an invalid sellId is passed to the contract' });
errorMap.set('AssetMarketplace::Exceed sell limit', { userMessage: 'When there is insufficient quantity of the asset available for the sale' });
errorMap.set('AssetMarketplace::Listed asset has not valid status', {
  userMessage: 'When the listed asset has already been sold or canceled prior to the transaction.',
});
errorMap.set('AssetMarketplace::Allowance error', {
  userMessage: 'When the user has LRT but has not approved the contract with the listed price amount',
});
errorMap.set('AssetMarketplace::Insufficient vested balance', { userMessage: 'When the user does not have enough vested balance' });
errorMap.set('AssetMarketplace::Unsuccessful transfer', { userMessage: 'When the transfer of LRT to the contract is unsuccessful' });
errorMap.set('AssetMarketplace::The sale has expired', { userMessage: 'The listing for this sale has expired' });

//LRT Staking
errorMap.set('LRTStaking::Invalid duration', { userMessage: 'Invalid duration. Please select one of the options.' });
errorMap.set(' LRTStaking::Stake capacity not set', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LRTStaking::Threshold not set', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LRTStaking::Duration limit not set', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LRTStaking::Amount must be greater than the threshold', {
  userMessage: 'Minimum required amount not met. Please enter a larger amount.',
});
errorMap.set('LRTStaking::Stake exceed capacity', { userMessage: 'Amount exceeds maximum required. Please enter a smaller amount.' });
errorMap.set('LRTStaking::Stake exceed duration limit', { userMessage: 'Invalid duration, please select one of the options.' });
errorMap.set('LRTStaking::Staking period not yet finished', { userMessage: 'Staking period has not finished yet.' });
errorMap.set('LRTStaking::You do not have any staking', { userMessage: 'No staking history found.' });
errorMap.set('LRTStaking::Treasury has not enough balance', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set(' LRTStaking::Unsuccessful transfer', { userMessage: 'Transfer unsuccessful. Please try again.' });
errorMap.set('LRTStaking::The staking schedule is fully claimed', { userMessage: 'All rewards have been claimed.' });
errorMap.set('LRTStaking::Allowance  error', { userMessage: 'Something went wrong. Please try again.' });

//LRT NFT Staking
errorMap.set('LRTNFTStaking::Invalid duration', { userMessage: 'Invalid duration, please select one of the options.' });
errorMap.set('LRTNFTStaking::Invalid reward limit', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LRTNFTStaking::Token price is invalid', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LRTNFTStaking::Stake capacity not set', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LRTNFTStaking::Threshold not set', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LRTNFTStaking::Duration limit not set', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LRTNFTStaking::Amount must be greater than the threshold', {
  userMessage: 'Minimum required amount not met. Please enter a larger amount.',
});
errorMap.set('LRTNFTStaking::Stake exceed capacity', { userMessage: 'Amount exceeds maximum required. Please enter a smaller amount.' });
errorMap.set('LRTNFTStaking::Stake exceed duration limit', { userMessage: 'Invalid duration, please select one of the options.' });
errorMap.set('LRTNFTStaking::Staking period not yet finished', { userMessage: 'Staking period has not finished yet.' });
errorMap.set('LRTNFTStaking::You do not have any staking', { userMessage: 'No staking history found.' });
errorMap.set('LRTNFTStaking::Contract has not enough balance', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LRTNFTStaking::Unsuccessful transfer', { userMessage: 'Transfer unsuccessful. Please try again.' });
errorMap.set('LRTNFTStaking::There is no more tokenId to reward', {
  userMessage: 'This NFT has reached its capacity. Please enter a different amount or time period.',
});
errorMap.set('LRTNFTStaking::TokenId not found', { userMessage: 'No NFT found. Please enter a different amount or time period.' });
errorMap.set('LRTNFTStaking::Not valid address', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LRTNFTStaking::Allowance error', { userMessage: 'Something went wrong. Please try again.' });

//LRT Fuel Staking
errorMap.set('LRTFuelStaking::Invalid duration', { userMessage: 'Invalid duration, please select one of the options.' });
errorMap.set(' LRTFuelStaking::Stake capacity not set', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set(' LRTFuelStaking::Threshold not set', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LRTFuelStaking::Duration limit not set', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LRTFuelStaking::Amount must be greater than the threshold', {
  userMessage: 'Minimum required amount not met. Please enter a larger amount.',
});
errorMap.set('LRTFuelStaking::Stake exceed capacity', { userMessage: 'Amount exceeds maximum required. Please enter a smaller amount.' });
errorMap.set('LRTFuelStaking::Stake exceed duration limit', { userMessage: 'Invalid duration, please select one of the options.' });
errorMap.set('LRTFuelStaking::Staking period not yet finished', { userMessage: 'Staking period has not finished yet.' });
errorMap.set('LRTFuelStaking::You do not have any staking', { userMessage: 'No staking history found.' });
errorMap.set('LRTFuelStaking::Contract has not enough balance', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LRTFuelStaking::Unsuccessful transfer', { userMessage: 'Transfer unsuccessful. Please try again.' });
errorMap.set('LRTFuelStaking::There is no more tokenId to reward', { userMessage: 'Fuel has reached its capacity. ' });
errorMap.set('LRTFuelStaking::TokenId not found', { userMessage: 'No reward found. Please enter a different amount or time period.' });
errorMap.set('LRTFuelStaking::Allowance error', { userMessage: 'Something went wrong. Please try again.' });

//Loot Box
errorMap.set('LootBox::Not valid address', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LootBox::LootBox capacity not set', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LootBox::LootBox price is invalid', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LootBox::The sell does not exist', { userMessage: 'Item sold out! Please select another item.' });
errorMap.set('LootBox::Sold listing lootBox cannot be edit', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LootBox::There are not any item to sell', { userMessage: 'Item sold out! Please select another item.' });
errorMap.set('LootBox::Cannot cancel active offer', { userMessage: 'Offer cannot be canceled.' });
errorMap.set('LootBox::Exceed sell limit', { userMessage: 'Selected quantity is more than available. Please select a lower amount.' });
errorMap.set('LootBox::Listed lootBox has not valid status', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set(' LootBox::Insufficient lootBox balance', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LootBox::Collection is not active', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LootBox::Insufficient amount equal to zero', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LootBox::No balance to withdraw', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LootBox::Invalid admin wallet', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LootBox::Unsuccessful transfer', { userMessage: 'Transfer unsuccessful. Please try again.' });
errorMap.set('LootBox::Allowance error', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LootBox::Insufficient vested balance', { userMessage: 'Insufficient balance.' });
errorMap.set('LootBox::At least one item to sell', { userMessage: 'Please specify quantity.' });
errorMap.set('LootBox::Stake exceed capacity', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LootBox::Sell unit is larger than listed amount', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LootBox::Listed amount is not a coefficient of sell unit', { userMessage: 'Something went wrong. Please try again.' });
errorMap.set('LootBox::Invalid input parameters', { userMessage: 'Invalid input. Please check and try again.' });
errorMap.set('LootBox::Invalid listed amount', { userMessage: 'Something went wrong. Please try again.' });

export default errorMap;
