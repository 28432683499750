import { StateCreator } from 'zustand';
import { IGameRoadMap } from './Interface';

// IAchievementsSlice
export const gameRoadMapSlice: StateCreator<IGameRoadMap> = (set) => ({
  gameRoadMapModalData: null,
  setModalData: (gameRoadMapModalData: any | null) => {
    set({ gameRoadMapModalData });
  },
});
