import React, { FC, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSession } from 'next-auth/react';
import api from '@/api/services/achievements';
import { Typography } from '@/components/Atoms';
import LoadingBalls from '@/components/Common/LoadingBalls';
import { UserPintData } from '@/types/Achievements';
import { numberSeparator } from '@/utils/numberSeparator';
import styles from './Table.module.scss';
import useAuthStatuses from '@/hooks/authentication/useAuthStatuses';

//  when me checked selected prop changes and the colr of the selected row turns to this color code #2A3625;
const LIMIT = 10;

interface ComponentProps {
  myRank: UserPintData;
  showMyRank: boolean;
}

const Table: FC<ComponentProps> = ({ myRank, showMyRank }) => {
  const { data: session } = useSession();
  const isLogin = useAuthStatuses();
  const [initialLoading, setInitialLoading] = useState(false);
  const [data, setData] = useState<any>({
    data: [],
    pages: 0,
    offset: 0,
    total: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const fetchMoreRanks = () => {
    if (data.offset <= data.total) {
      setLoading(true);
      api
        .getLeaderBoardInfinit({
          limit: LIMIT,
          offset: data.offset,
        })
        .then((res: any) => {
          setData((prevData) => ({
            ...prevData,
            data: [...prevData.data, ...res?.data?.leader_board],
            pages: res.data.pages,
            offset: prevData.offset + LIMIT,
            total: res.data.count,
          }));
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const initialFetch = async () => {
    setInitialLoading(true);
    try {
      const res: any = await api.getLeaderBoardInfinit({
        limit: LIMIT,
        offset: 0,
      });

      setData({
        data: res.data.leader_board,
        pages: res.data.pages,
        offset: LIMIT,
        total: res.data.count,
      });
      setInitialLoading(false);
    } catch (error) {
    } finally {
      setInitialLoading(false);
    }
  };
  useEffect(() => {
    if (isLogin) {
      initialFetch();
    }
  }, []);

  let content;

  if (!showMyRank && !initialLoading) {
    content =
      data?.data.length > 0 ? (
        data?.data.map((rowData, idx) => (
          <tr key={idx} className='odd:bg-[#20232B] bg-[#191C22] h-[56px]'>
            <td scope='row' align='center'>
              {rowData?.user_rank}
            </td>
            <td scope='row' align='center'>
              <Typography size='s22ToS16' className={styles.userText}>
                {rowData.user_wallet === 'nil' ? rowData.user_email : rowData.user_wallet}
              </Typography>
            </td>
            <td scope='row' className={styles.bodyTask} align='center'>
              {rowData.task_done}
            </td>
            <td scope='row' className={styles.bodyAchievements} align='center'>
              {rowData.Achievement_done}
            </td>
            <td scope='row' align='center'>
              <Typography size='s22ToS16' className={styles.totalPointText}>
                {numberSeparator(rowData.point, ',')}
              </Typography>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td scope='row' colSpan={5} className='text-center p-3 text-[16px] leading-[33px] text-wrap w-full'>
            No data available right now. Please try again later.
          </td>
        </tr>
      );
  }

  if (showMyRank && !initialLoading) {
    content =
      myRank?.point !== undefined ? (
        <tr className='odd:bg-[#20232B] bg-[#191C22] h-[56px]'>
          <td scope='row' align='center'>
            {myRank?.user_rank}
          </td>
          <td scope='row' align='center' className='overflow-auto max-w-[100px]'>
            <Typography size='s22ToS16' className={styles.userText}>
              {myRank?.user_wallet === 'nil' ? myRank?.user_email : myRank?.user_wallet}
            </Typography>
          </td>
          <td scope='row' className={styles.bodyTask} align='center'>
            {myRank?.task_done}
          </td>
          <td scope='row' className={styles.bodyAchievements} align='center'>
            {myRank?.Achievement_done}
          </td>
          <td scope='row' align='center'>
            <Typography size='s22ToS16' className={styles.totalPointText}>
              {numberSeparator(myRank?.point, ',')}
            </Typography>
          </td>
        </tr>
      ) : (
        <tr>
          <td colSpan={5} className='text-center p-3 text-[16px] leading-[33px] text-wrap w-full'>
            You have 0 points and are not on the leaderboard. Complete tasks to start earning points and climb the ranks!
          </td>
        </tr>
      );
  }

  if (initialLoading) {
    content = (
      <tr>
        <td scope='row' colSpan={5} className='text-center'>
          <LoadingBalls />
        </td>
      </tr>
    );
  }

  return (
    <div className={styles.container}>
      <InfiniteScroll
        dataLength={data?.data?.length}
        next={() => fetchMoreRanks()}
        hasMore={data?.data?.length < data.total}
        loader={
          loading && (
            <div className='absolute bottom-0 left-0 right-0  w-full flex justify-center items-center'>
              <LoadingBalls />
            </div>
          )
        }
        height={540}
        scrollableTarget='scrollableDiv'
      >
        <table>
          <thead>
            <tr>
              <th scope='col' align='center'>
                <div>
                  <p>Rank</p>
                  <div className={styles.divider} />
                </div>
              </th>

              <th scope='col' className={styles.userThead} align='center'>
                <div>
                  <p>User</p>
                  <div className={styles.divider} />
                </div>
              </th>

              <th scope='col' className={styles.headTasks} align='center'>
                <div>
                  <p>Tasks done</p>
                  <div className={styles.divider} />
                </div>
              </th>

              <th scope='col' className={styles.headAchievements} align='center'>
                <div>
                  <p>Achievements done</p>
                  <div className={styles.divider} />
                </div>
              </th>

              <th scope='col' align='center'>
                <div>
                  <p>Total XP</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className={styles.tbodyInfinit}>{content}</tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};

export default Table;
