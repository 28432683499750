import * as React from 'react';

const InstagramSvg = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 100 100'
    {...props}
    onMouseEnter={(e) => (e.currentTarget.querySelector('path').style.fill = '#29D6DF')} // Change to green on hover
    onMouseLeave={(e) => (e.currentTarget.querySelector('path').style.fill = 'white')} // Change back to white on mouse leave
    style={{ transition: 'fill 0.3s ease' }} // Smooth transition for hover
  >
    <path
      fill='#fff' // Initial fill color
      d='M50 0C36.417 0 34.721.062 29.387.3A36.869 36.869 0 0 0 17.25 2.625a24.485 24.485 0 0 0-8.858 5.767 24.4 24.4 0 0 0-5.767 8.858A36.761 36.761 0 0 0 .3 29.387C.05 34.721 0 36.417 0 50s.062 15.279.3 20.612A36.891 36.891 0 0 0 2.625 82.75a24.52 24.52 0 0 0 5.767 8.858 24.45 24.45 0 0 0 8.858 5.767A36.915 36.915 0 0 0 29.387 99.7c5.333.25 7.029.3 20.613.3s15.279-.062 20.613-.3a37 37 0 0 0 12.137-2.325A25.553 25.553 0 0 0 97.375 82.75 36.892 36.892 0 0 0 99.7 70.612c.25-5.333.3-7.029.3-20.612s-.062-15.279-.3-20.612a36.977 36.977 0 0 0-2.325-12.138 24.54 24.54 0 0 0-5.767-8.858 24.362 24.362 0 0 0-8.858-5.767A36.784 36.784 0 0 0 70.613.3C65.279.05 63.583 0 50 0Zm0 9c13.346 0 14.937.067 20.208.3a27.548 27.548 0 0 1 9.279 1.729 16.454 16.454 0 0 1 9.492 9.487 27.606 27.606 0 0 1 1.721 9.276c.237 5.275.292 6.858.292 20.208s-.063 14.937-.308 20.208a28.138 28.138 0 0 1-1.754 9.279 15.876 15.876 0 0 1-3.746 5.758 15.6 15.6 0 0 1-5.75 3.733 27.809 27.809 0 0 1-9.313 1.722c-5.308.238-6.871.292-20.246.292s-14.942-.062-20.246-.308a28.352 28.352 0 0 1-9.317-1.754 15.484 15.484 0 0 1-5.746-3.746 15.182 15.182 0 0 1-3.75-5.75 28.376 28.376 0 0 1-1.75-9.312c-.187-5.25-.254-6.871-.254-20.183S8.879 35 9.067 29.683a28.343 28.343 0 0 1 1.75-9.308 14.821 14.821 0 0 1 3.75-5.754 14.789 14.789 0 0 1 5.746-3.742 27.674 27.674 0 0 1 9.254-1.754c5.312-.187 6.875-.25 20.246-.25L50 9Zm0 15.325A25.675 25.675 0 1 0 75.675 50 25.673 25.673 0 0 0 50 24.325Zm0 42.342A16.667 16.667 0 1 1 66.667 50 16.662 16.662 0 0 1 50 66.666Zm32.692-43.355a6 6 0 1 1-6-6 6 6 0 0 1 6 6Z'
    />
  </svg>
);

export default InstagramSvg;
