const InventorySvg = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={21.572} height={21.571} viewBox={'0 0 21.572 21.572'} {...props}>
    <g fill='#fff' data-name='Group 13734'>
      <path
        d='M11.589.276v2.743a.276.276 0 0 1-.276.276H9.578a.276.276 0 0 1-.275-.276V.276A.277.277 0 0 0 9.027 0H5.596a.276.276 0 0 0-.276.276v9.7a.275.275 0 0 0 .276.276h9.7a.276.276 0 0 0 .276-.276v-9.7A.277.277 0 0 0 15.296 0h-3.431a.276.276 0 0 0-.276.276'
        data-name='Path 11878'
      />
      <path
        d='M6.269 11.595v2.475a.545.545 0 0 1-.545.546h-1.2a.545.545 0 0 1-.545-.546v-2.475a.277.277 0 0 0-.276-.276H.274a.276.276 0 0 0-.276.276v9.7a.275.275 0 0 0 .276.276h9.7a.276.276 0 0 0 .276-.276v-9.7a.277.277 0 0 0-.276-.276H6.543a.276.276 0 0 0-.276.276'
        data-name='Path 11879'
      />
      <path
        d='M17.589 11.595v2.745a.276.276 0 0 1-.276.276h-1.735a.276.276 0 0 1-.275-.276v-2.745a.277.277 0 0 0-.276-.276h-3.431a.276.276 0 0 0-.276.276v9.7a.275.275 0 0 0 .276.276h9.7a.276.276 0 0 0 .276-.276v-9.7a.277.277 0 0 0-.276-.276h-3.431a.276.276 0 0 0-.276.276'
        data-name='Path 11880'
      />
    </g>
  </svg>
);
export default InventorySvg;
