import React from 'react';
import dynamic from 'next/dynamic';
import levelupLottie from '@/components/Common/LoadingPage/Loading-V04.json';

// Dynamically import the Lottie component with SSR disabled
const Lottie = dynamic(() => import('lottie-react'), {
  ssr: false,
});

const LoadingWithLogo = () => (
  <div className={'mt-[40px]'}>
    <div className='w-[100px] mx-auto'>
      <Lottie animationData={levelupLottie} loop={true} onComplete={(e) => {}} />
    </div>
  </div>
);

export default LoadingWithLogo;
