import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BaseError, ContractFunctionExecutionError, ContractFunctionRevertedError } from 'viem';
import errorMap from './errorsData';

class SmartContactError {
  private static mErrorMessage: string;

  public static error(errorMessage: string) {
    SmartContactError.mErrorMessage = errorMessage;
    return this;
  }

  public static log(): void {
    // console.log(SmartContactError.mErrorMessage);
  }

  public static getErrorMessage = (): string => SmartContactError.mErrorMessage;

  public static catchError = (err) => {
    if (err instanceof BaseError) {
      const revertError = err?.walk((e) => e instanceof ContractFunctionRevertedError || e instanceof ContractFunctionExecutionError);
      const errorTitle = revertError?.message.split('\n')[1];
      SmartContactError.error(errorTitle).show();
    }
  };

  public static show(): void {
    let message;

    if (errorMap.has(SmartContactError.mErrorMessage)) {
      message = errorMap.get(SmartContactError.mErrorMessage).userMessage;
    } else {
      message = 'Something went wrong!';
    }

    toast.error(message, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: '#5a0000',
        color: 'white',
      },
    });
  }
}

export default SmartContactError;
