import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import { CloseIcon } from '@/components/Svg';
import styles from './BasicModal.module.scss';

interface ComponentProps {
  children: ReactNode;
  handleClose: () => void;
  leftClose?: boolean;
  classnames?: string;
}

const BasicModal: FC<ComponentProps> = ({ classnames, leftClose = false, children, handleClose }) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <div
        className={cn(styles.closeBtn, {
          'left-[40px]': leftClose,
          inherit: !leftClose,
        })}
      >
        <CloseIcon width='100%' height='100%' onClick={handleClose} />
      </div>
      <div className={classnames || styles.modalContent}>{children}</div>
    </div>
  </div>
);

export default BasicModal;
