import { useCallback, useEffect, useState } from 'react';
import apiInventory from '@/api/services/inventory';
import api from '@/api/services/lite-game';
import { useStore as useStore2DGame } from '@/zustand/store/2dGameSore';
import useAuthStatuses from '@/hooks/authentication/useAuthStatuses';

const use2DUserData = () => {
  const { setUserData: setUserDataStore } = useStore2DGame();
  const { isLogin } = useAuthStatuses();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState({
    userInventory: null,
    user2DData: null,
  });

  const fetchData = useCallback(async () => {
    if (isLogin) {
      setIsLoading(true);
      const [userDataResponse, inventoryResponse] = await Promise.all([api.getUserData2DGame(), apiInventory.inventory()]);

      setUserData({
        user2DData: userDataResponse.data,
        userInventory: inventoryResponse.data,
      });
      setUserDataStore(userDataResponse.data);
      setIsLoading(false);
    }
  }, [isLogin]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { user2DData: userData.user2DData, userInventory: userData.userInventory, mutate: fetchData, isLoading };
};

export default use2DUserData;
