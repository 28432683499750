import React from 'react';
import { LrtDarkSvg, MaticSvg, UsdcSvg, UsdtSvg } from '@/components/Svg';

export interface AvailableCoinsProps {}

// const buyIcons = [LrtDarkSvg, UsdtSvg, UsdcSvg, MaticSvg];
const buyIcons = [LrtDarkSvg];

const AvailableCoins: React.FunctionComponent<AvailableCoinsProps> = () => (
  <div className='flex gap-x-1 mt-1 items-center'>
    {buyIcons.map((Icon, index) => (
      <div className={index === 0 ? 'w-[26px] h-[26px]' : 'w-[22px] h-[22px]'} key={index}>
        <Icon width={'100%'} height={'100%'} />
      </div>
    ))}
  </div>
);

export default AvailableCoins;
