import { StateCreator } from 'zustand';
import { IAchievementsSlice, IBuilderBanner, ICommandSocket, IRankConfig, IThem } from './Interface';

// IAchievementsSlice
export const achievementsSlice: StateCreator<IAchievementsSlice> = (set) => ({
  them: {
    name: '',
    color: '',
    bg: '',
    borderImage: '',
  },
  id: '',
  requestModalVisible: false,
  requestModalData: { taskId: '', link: '', expireDate: '', additional_data: undefined },
  AchievementsAbly: {},
  achievementData: {},
  inviteFriendModal: false,
  isTweetTaskVerified: [],
  showPointList: false,
  commandCenterBar: {},
  rankConfig: {
    currentPoint: 0,
    ranks: [],
  },
  showRewardsModal: false,
  onBoardingData: {
    isShowHint: false,
    isShowHintClaimButton: false,
  },
  builderBanner: {
    connectionId: '',
    id: '',
    name: '8077',
    timestamp: 0,
    data: '',
  },
  setBuilderBanner: (builderBanner: IBuilderBanner) => {
    set({ builderBanner });
  },
  liteGameId: '',
  liteGameWinnerName: '',
  liteGameResult: '',
  liteGameRewards: [],
  earlyAccessModal: false,
  commandSocketData: {
    rewards: [],
    state: '',
    stateChanged: false,
    topUsers: [],
    rewardsProcessed: true,
  },
  setCommandSocketData: (commandSocketData: ICommandSocket) => {
    set({ commandSocketData });
  },
  setEarlyAccessModal: (earlyAccessModal: boolean) => {
    set({ earlyAccessModal: earlyAccessModal });
  },
  setLiteGameRewards: (liteGameRewards: any) => {
    set({ liteGameRewards });
  },
  setLiteGameResult: (data: 'win' | 'lose' | '' | 'idle') => {
    set({ liteGameResult: data });
  },
  setLiteGameWinnerName: (data: string) => {
    set({ liteGameWinnerName: data });
  },
  setLiteGameId: (liteGameId: string) => {
    set({ liteGameId });
  },
  setShowRewardsModal: (show: boolean) => {
    set({ showRewardsModal: show });
  },
  setOnBoardingData: (onBoardingData: { isShowHint: boolean; isShowHintClaimButton: boolean }) => {
    set({ onBoardingData: onBoardingData });
  },
  setRankConfig: (rankConfig: IRankConfig) => {
    set({ rankConfig });
  },
  setShowPointList: (showPointList: boolean) => {
    set({ showPointList });
  },
  setIsTweetTaskVerified: (isTweetTaskVerified: Array<string>) => {
    set({ isTweetTaskVerified });
  },
  setThem: (them: IThem) => {
    set({
      them,
    });
  },
  setCommandCenterBar: (commandCenterBar: any) => {
    set({ commandCenterBar });
  },

  setAchievements: (id: string) => {
    set({ id });
  },
  setAchievementsAbly: (AchievementsAbly: any) => {
    set({ AchievementsAbly });
  },
  setRequestModalVisible: (
    requestModalVisible: boolean,
    requestModalData?: { taskId: string; link: string; expireDate?: string; additional_data: any },
  ) => {
    set({ requestModalVisible, requestModalData });
  },
  setachievementData: (achievementData: any) => {
    set({ achievementData });
  },
  setInviteFriendModal: (inviteFriendModal: boolean) => {
    set({ inviteFriendModal });
  },
});
