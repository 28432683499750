import { StateCreator } from 'zustand';
import { EAuthVarient } from '@/types/Authentication';
import { IModalSlice } from '../Interface';
import { IAuthSlice } from './Interface';

export const authSlice: StateCreator<IAuthSlice & IModalSlice, [], [], IAuthSlice> = (set, get) => ({
  //state initializing
  userDataSign: {
    owner: '',
    hash_signe: '',
    message: '',
    new_user: false,
  },
  twoFaSecret: {
    tow_factor_qrcode: 'string',
    tow_factor_secret: 'string',
    tow_factor_status: false,
  },
  authModalVarient: EAuthVarient.null,
  callbackUrl: null,
  onCloseModal: () => {},

  //state handler functions
  setUserDataSign: (isNewUser: any) => {
    set({
      userDataSign: isNewUser,
    });
  },
  setAuthModalVarient: (authModalVarient: EAuthVarient, callbackUrl?: string, onCloseModal?: () => void) => {
    const closeModal = onCloseModal || (() => {});
    set({ authModalVarient, callbackUrl, onCloseModal: closeModal });
  },
  setTwoFaSecret: (twoFaSecret: any) => {
    set({
      twoFaSecret: twoFaSecret,
    });
  },
});
