import * as React from 'react';

const FacebookSvg = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 12 12'
    {...props}
    onMouseEnter={(e) => (e.currentTarget.querySelector('path').style.fill = '#29D6DF')} // Change to green on hover
    onMouseLeave={(e) => (e.currentTarget.querySelector('path').style.fill = 'white')} // Change back to black on mouse leave
    style={{ transition: 'fill 0.3s ease' }} // Smooth transition for hover effect
  >
    <path
      fillRule='evenodd'
      d='M10.86 12h-3V7.86h2.06v-2.1H7.86V4.55c0-.22.19-.4.4-.4h1.87v-2.1H8.31v.01h-.07a2.47 2.47 0 0 0-2.47 2.48v1.24H4.12v2.09h1.65v4.12H1.12C.5 12 0 11.5 0 10.88V1.12C0 .5.5-.01 1.12-.01h9.74C11.48 0 12 .5 12 1.13v9.75c0 .62-.5 1.13-1.13 1.13z'
      fill='black' // Initial fill color
    />
  </svg>
);

export default FacebookSvg;
