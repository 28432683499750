import Typography from '@/components/Atoms/Typography';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';

interface IChildHeaderSubLinkProps {
  link: {
    label: string;
    href?: string;
    icon?: (props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => JSX.Element;
    badge?: string;
    subLinks?: Array<{
      icon?: (props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => JSX.Element;
      label: string;
      href: string;
      description: string;
      disabled?: boolean;
    }>;
  };
}
const ChildHeaderSubLink = (props: IChildHeaderSubLinkProps) => {
  const { t } = useTranslation('header'); // Load translations from the 'header' namespace
  const { link } = props;
  const { push } = useRouter();
  return (
    <>
      <div
        className='absolute top-[0px] right-1/2 translate-x-1/2
                     min-w-[470px] bg-[#272C36] xl:min-w-[530px] 2xl:min-w-[600px] rounded-lg px-3 w-fit h-fit'
      >
        <div className='mt-2'>
          <Typography className='mt-2'>{t(link.label as any)}</Typography>
        </div>
        <div className='w-full h-[2px] bg-[#FFFFFF1A] my-3' />
        <div className={'grid grid-cols-2 gap-2 rounded-xl text-white'}>
          {(link.subLinks ?? []).map((item, idx) => {
            return (
              <div
                key={idx}
                // href={!item.disabled ? item.href : undefined}
                className={classNames(
                  'flex items-start p-2 rounded-lg gap-2 text-white transition-all hover:bg-[#151414]',
                  item.disabled && 'cursor-default',
                )}
                onClick={() => {
                  push(!item.disabled ? item.href : '#');
                }}
              >
                {item.icon && (
                  <div className='flex items-center justify-center pt-1'>
                    <item.icon
                      // @ts-ignore
                      color='#fff'
                      width='22px'
                      height='22px'
                    />
                  </div>
                )}
                <div>
                  <Typography size='s14ToS12'>{t(item.label as any)}</Typography>
                  <Typography size='s14ToS12' className={'text-[#686868]'}>
                    {t(item.description as any)}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ChildHeaderSubLink;
