import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const useResponsive = () => {
  const [isMobile, setisMobile] = useState(false);
  const [isTablet, setisTablet] = useState(false);
  const [isDesktop, setisDesktop] = useState(false);
  const [isDesktop1024, setisDesktop1024] = useState(false);
  const [isDesktop1440, setisDesktop1440] = useState(false);
  const [isMediumLaptop, setIsMediemLaptop] = useState(false);
  const [isLargeDesktop, setIsLargeDesktop] = useState(false);

  const isMobiles = useMediaQuery({
    query: '(max-width: 767px)',
  });
  const isTablets = useMediaQuery({
    query: '(max-width: 992px)',
  });
  const isDesktops = useMediaQuery({
    query: '(max-width: 1280px)',
  });
  const isDesktops1024 = useMediaQuery({
    query: '(max-width: 1023px)',
  });
  const isMediumLaptops = useMediaQuery({
    query: '(max-width: 1280px)',
  });
  const isDesktops1440 = useMediaQuery({
    query: '(max-width: 1439px)',
  });
  const isLargeDesktops = useMediaQuery({
    query: '(min-width: 1920px)',
  });

  useEffect(() => {
    setisMobile(isMobiles);
  }, [isMobiles]);

  useEffect(() => {
    setisTablet(isTablets);
  }, [isTablets]);

  useEffect(() => {
    setisDesktop(isDesktops);
  }, [isDesktops]);
  useEffect(() => {
    setisDesktop1024(isDesktops1024);
  }, [isDesktops1024]);
  useEffect(() => {
    setisDesktop1440(isDesktops1440);
  }, [isDesktops1440]);

  useEffect(() => {
    setIsMediemLaptop(isMediumLaptops);
  }, [isMediumLaptops]);

  useEffect(() => {
    setIsLargeDesktop(isLargeDesktops);
  }, [isLargeDesktops]);

  return { isMobile, isTablet, isDesktop, isMediumLaptop, isLargeDesktop, isDesktop1024,isDesktop1440 };
};

export default useResponsive;
