import * as React from 'react';

const BreakingNewsSvg = (props) => (
  <svg width={18} height={20} viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2462_802)'>
      <path
        d='M9.38644 19.9021C9.25989 19.9624 9.13334 20.0002 8.9919 20.0002C8.68669 20.0002 8.38893 19.8266 8.2326 19.5322L7.05642 17.2379C6.90754 16.9436 6.48322 16.9436 6.33434 17.2379L5.15816 19.5322C4.93483 19.9549 4.42118 20.1209 4.00431 19.9021C3.58744 19.6756 3.42367 19.1549 3.64699 18.7322L5.32937 15.4492H8.06883L9.75121 18.7322C9.96709 19.1624 9.80332 19.6756 9.38644 19.9021Z'
        fill='white'
      />
      <path
        d='M16.7888 6.18878L14.4141 7.57746V12.0529L16.7888 13.4416C17.3247 13.7586 17.9947 13.3661 17.9947 12.7322V6.89821C18.0022 6.26425 17.3322 5.8718 16.7888 6.18878Z'
        fill='white'
      />
      <path
        d='M12.0521 5.19238H1.35484C0.602978 5.19238 0 5.8037 0 6.56597V13.0565C0 13.8188 0.602978 14.4301 1.35484 14.4301H12.0521C12.804 14.4301 13.4069 13.8188 13.4069 13.0565V6.56597C13.4069 5.8037 12.804 5.19238 12.0521 5.19238ZM8.94045 10.3094L5.89578 12.1131C5.52357 12.3471 5.03226 12.0603 5.03226 11.6226V7.99238C5.03226 7.55465 5.51613 7.26785 5.89578 7.50182L8.94045 9.30559C9.31265 9.53201 9.31265 10.0905 8.94045 10.3094Z'
        fill='white'
      />
      <path
        d='M4.0941 4.47547C5.31494 4.47547 6.29757 3.4717 6.29757 2.24151C6.29757 1.00377 5.31494 0 4.0941 0C2.87326 0 1.89062 1.00377 1.89062 2.23396C1.89062 3.4717 2.87326 4.47547 4.0941 4.47547Z'
        fill='white'
      />
      <path
        d='M9.30504 4.47547C10.5259 4.47547 11.5085 3.4717 11.5085 2.24151C11.516 1.00377 10.5259 0 9.30504 0C8.08419 0 7.10156 1.00377 7.10156 2.23396C7.10156 3.4717 8.09164 4.47547 9.30504 4.47547Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_2462_802'>
        <rect width={18} height={20} fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export default BreakingNewsSvg;
