import React, { ComponentType, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Loader } from '@mantine/core';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { formatNumberDecimals, numberSeparator } from '@/utils/numberSeparator';
import { useStore } from '@/zustand/store/store';
import { Button, Typography } from '../Atoms';
import { CustomModal } from '../Molecules';
import styles from './styles.module.scss';

// eslint-disable-next-line arrow-body-style
const SignWithReferralModal = () => {
  const { data: session, update } = useSession();
  const [showModal, setShowModal] = useState(false);
  const [userReferral, setUserReferral] = useState('');
  const router = useRouter();
  const { seasonZeroVisible, setSeasonZerolVisible } = useStore();

  useEffect(() => {
    // @ts-ignore
    if (session?.newUser && session?.inviteCode) {
      setShowModal(true);
    }
    // @ts-ignore
    if (session?.userReferral) {
      // @ts-ignore
      setUserReferral(session?.userReferral);
    }
  }, [session]);

  const onCloseModal = () => {
    setShowModal(false);
    update({
      ...session,
      user: {
        ...session.user,
        new_user: false,
      },
    });
  };

  const downloadGameButton = () => {
    setShowModal(false);
    update({
      ...session,
      user: {
        ...session.user,
        new_user: false,
      },
    }).then(() => {
      router.push('game');
    });
  };

  const handleCopyClick = (data) => {
    if (navigator.clipboard.writeText(data)) {
      toast.success('Copied to clipboard!', {
        position: 'bottom-center',
        autoClose: 2000,
        closeOnClick: true,
        theme: 'dark',
      });
    }
  };

  const seasonZeroVisible2 = router.pathname === '/' && seasonZeroVisible;

  return (
    <CustomModal
      showCloseIcon={false}
      closeOnClickOutside={false}
      visible={showModal && !seasonZeroVisible2}
      centered
      zIndexOverlay={999999998}
      zIndexInner={999999999}
    >
      <div className={styles.container} id='process_transaction_modal'>
        <div className={styles.shadowContainer}>
          <div className={`${styles.shadow}`}></div>
        </div>
        <div>
          <Typography isBold Component='div' className={styles.title}>
            Sign up successful
          </Typography>
          <div className='gap-2 lg:gap-4 flex flex-col'>
            <Typography Component='div' className={styles.desc}>
              Your account was created successfully on LandRocker.
            </Typography>
            <Typography Component='div' className={styles.desc}>
              {/* @ts-ignore */}
              You were referred by: <span className='text-[#FBCE00] font-bold'>{session?.inviteCode}</span>
            </Typography>
            <Typography Component='div' className={styles.desc}>
              You can download the game and start playing to find LRT.
            </Typography>
            <Typography Component='div' className={styles.desc}>
              There is <span className='text-[#FBCE00] font-bold'>20,000,000 LRT</span> to be found.
            </Typography>
            <Typography Component='div' className={styles.desc}>
              Each time you find some LRT in the game, your referrer gets <span className='text-[#FBCE00] font-bold'>10% bonus LRT.</span>
            </Typography>
            <Typography Component='div' className={styles.desc}>
              You can also join our referral program and invite your friends to earn passive income.
            </Typography>
          </div>
        </div>
        <div className='w-full flex justify-center items-center'>
          <div className='z-[11] w-full lg:w-[550px] mt-[16px] md:mt-[34px] flex flex-col'>
            <Typography className='text-base text-[#7D7E82]'>Referral link:</Typography>
            <div
              className='h-[56px] border-2 border-[#31333B] mt-[6px] rounded-[8px] px-[20px] pl-[10px] 
            md:pl-[20px] pr-[6px] flex items-center justify-between'
            >
              <Typography className='text-white' size='s18ToS14'>
                {/* @ts-ignore*/}
                https://landrocker.io?code={userReferral}
              </Typography>
              <Button
                className='flex justify-center items-center bg-[#31333B] rounded-[6px] 
              w-[50px] md:w-[128px] h-[44px] font-bold text-white'
                onClick={
                  () =>
                    handleCopyClick(`Hey there,:clap:
Get ready for an epic adventure! Here’s your exclusive invitation link to join LandRocker. Download the game, mine, explore fascinating landscapes, take on thrilling quests, and unleash your creativity.
Join now and let the fun begin!
https://landrocker.io?code=${userReferral}
`)
                  // ${referalData?.user_referral_link}
                }
              >
                <Typography size='s20ToS16'>Copy</Typography>
              </Button>
            </div>
            <div className='w-full flex justify-center items-center mt-[20px] md:mt-[48px]'>
              <Button
                className='w-[70%] md:w-[80%] rounded-lg border border-[#29DF41] bg-[#29df412a] h-[46px] md:h-[54px] font-bold text-white'
                onClick={downloadGameButton}
              >
                <Typography size='s22ToS14'>Play For Free</Typography>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default SignWithReferralModal;
