import * as React from 'react';
import styles from '../../components/Common/Header/Header.module.scss'; // Adjust path as necessary

const LanguageSvg = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    style={{ fill: 'white', transition: 'fill 0.3s ease' }}
    onMouseEnter={(e) => (e.currentTarget.style.fill = '#979797')}
    onMouseLeave={(e) => (e.currentTarget.style.fill = 'white')}
    {...props}
  >
    <path d='M0 10c0 5.514 4.486 10 10 10s10-4.486 10-10S15.514 0 10 0 0 4.486 0 10Zm17.88 0a8.75 8.75 0 0 1-.164 1.615h-3.703a26.712 26.712 0 0 0 0-3.23H17.7c.114.538.18 1.077.18 1.615Zm-5.939 0c0 .571-.016 1.093-.049 1.615H8.124A25.14 25.14 0 0 1 8.075 10c0-.571.016-1.093.049-1.615h3.768c.033.522.05 1.06.05 1.615Zm-1.925 7.88c-.424-.082-1.223-1.534-1.647-4.128h3.295c-.44 2.594-1.24 4.046-1.648 4.127ZM2.137 10c0-.555.065-1.093.163-1.615h3.687a26.688 26.688 0 0 0 0 3.23H2.3A7.535 7.535 0 0 1 2.137 10Zm6.215-3.736c.425-2.61 1.224-4.045 1.632-4.127.408.082 1.223 1.533 1.647 4.127H8.352ZM7.13 2.692c-.424 1.027-.718 2.25-.914 3.589H3.083A7.84 7.84 0 0 1 7.13 2.69Zm-4.046 11.06H6.2c.196 1.321.506 2.561.914 3.589a8.086 8.086 0 0 1-4.03-3.589Zm9.804 3.573c.425-1.028.718-2.252.914-3.59h3.132a7.91 7.91 0 0 1-4.046 3.59Zm4.046-11.06h-3.132c-.196-1.322-.506-2.562-.914-3.59a7.982 7.982 0 0 1 4.046 3.59Z' />
  </svg>
);

export default LanguageSvg;
