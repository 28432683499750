import { StateCreator } from 'zustand';
import { IStakeSliceProps } from './Interface';

export const stakeSlice: StateCreator<IStakeSliceProps> = (set) => ({
  //state initializing
  isRefreshStakingPoolsData: false,
  isRefreshStakingCardsData: false,
  isRefreshStakingHistoryData: false,
  stakingLrtToNFTReward: {
    apy: 0,
    asset_image: '',
    asset_name: 'No Reward',
    can_stake: false,
  },
  stakingLrtToFuelReward: {
    apr: 0,
    roi: 0,
  },
  isRefreshStakingLeaderboardData: false,
  stakeLeaderboardData: {
    data: [],
    total: 0,
  },

  //state handler functions
  setIsRefreshStakingPoolsData: (isRefreshStakingPoolsData) => {
    set({
      isRefreshStakingPoolsData: isRefreshStakingPoolsData,
    });
  },
  setIsRefreshStakingCardsData: (isRefreshStakingCardsData) => {
    set({
      isRefreshStakingCardsData: isRefreshStakingCardsData,
    });
  },
  setIsRefreshStakingHistoryData: (isRefreshStakingHistoryData) => {
    set({
      isRefreshStakingHistoryData: isRefreshStakingHistoryData,
    });
  },
  setStakingLrtToNFTReward: (stakingLrtToNFTReward) => {
    set({
      stakingLrtToNFTReward: stakingLrtToNFTReward,
    });
  },
  setStakingLrtToFuelReward: (stakingLrtToFuelReward) => {
    set({
      stakingLrtToFuelReward: stakingLrtToFuelReward,
    });
  },
  setIsRefreshStakingLeaderboardData: (isRefreshStakingLeaderboardData) => {
    set({
      isRefreshStakingLeaderboardData: isRefreshStakingLeaderboardData,
    });
  },
  setStakeLeaderboardData: (leaderboardData) => {
    set({
      stakeLeaderboardData: leaderboardData,
    });
  },
});
