import dynamic from 'next/dynamic';

export const Basket = dynamic(() => import('./Basket'));
export const Builder = dynamic(() => import('./Builder'));
export const Download = dynamic(() => import('./Download'));
export const Financial = dynamic(() => import('./Financial'));
export const HomeIcon = dynamic(() => import('./Home'));
export const Location = dynamic(() => import('./Location'));
export const ChangeIcon = dynamic(() => import('./ChangeIcon'));
export const ResetIcon = dynamic(() => import('./Reset'));
export const AngleLeft = dynamic(() => import('./AngleLeft'));
export const AngleUp = dynamic(() => import('./AngleUp'));
export const WorldWeb = dynamic(() => import('./WordWeb'));
export const TwitchSvg = dynamic(() => import('./twitch'));
export const FacebookSvg = dynamic(() => import('./Facebook'));
export const Twitter = dynamic(() => import('./twitter'));
export const ArmiesSvg = dynamic(() => import('./ArmiesSvg'));
export const LineSvg = dynamic(() => import('./lineSvg'));
export const Instagram = dynamic(() => import('./Instagram'));
export const TasksSvg = dynamic(() => import('./TasksSvg'));
export const WhitePaper = dynamic(() => import('./WhitePaper'));
export const MissedSvg = dynamic(() => import('./MissedSvg'));
export const ChevronRight = dynamic(() => import('./ChevronRight'));
export const LockedSvg = dynamic(() => import('./LockedSvg'));
export const IdeaSvg = dynamic(() => import('./IdeaSvg'));
export const LandrockerSvg = dynamic(() => import('./LandrockerSvg'));
export const SuttleSpace = dynamic(() => import('./SuttleSpace'));
export const Crypto = dynamic(() => import('./Crypto'));
export const Education = dynamic(() => import('./Education'));
export const LivePricesSvg = dynamic(() => import('./LivePricesSvg'));
export const Residential = dynamic(() => import('./Residential'));
export const Fashion = dynamic(() => import('./Fashion'));
export const Arcade = dynamic(() => import('./Arcade'));
export const NFT = dynamic(() => import('./Nft'));
export const Business = dynamic(() => import('./Business'));
export const Sports = dynamic(() => import('./Sports'));
export const Art = dynamic(() => import('./Art'));
export const Halal = dynamic(() => import('./Halal'));
export const Entertainment = dynamic(() => import('./Entertainment'));
export const Science = dynamic(() => import('./Science'));
export const Tech = dynamic(() => import('./Tech'));
export const BnbSvg = dynamic(() => import('./BnbSvg'));
export const Media = dynamic(() => import('./Media'));
export const Animation = dynamic(() => import('./Animation'));
export const Production = dynamic(() => import('./Production'));
export const ETHSvg = dynamic(() => import('./ETHSvg'));
export const Celebrity = dynamic(() => import('./Celebrity'));
export const Startup = dynamic(() => import('./Startup'));
export const Voxel = dynamic(() => import('./Voxel'));
export const Kids = dynamic(() => import('./Kids'));
export const Exhibition = dynamic(() => import('./Exhibition'));
export const CloseIcon = dynamic(() => import('./CloseIcon'));
export const Roadmap = dynamic(() => import('./Roadmap'));
export const LinkedIn = dynamic(() => import('./LinkedIn'));
export const DiscordSvg = dynamic(() => import('./Discord'));
export const ReddiSvg = dynamic(() => import('./Reddit'));
export const TelegramSvg = dynamic(() => import('./Telegram'));
export const YoutubeSvg = dynamic(() => import('./youtube'));
export const CategorySvg = dynamic(() => import('./Category'));
export const FilterSvg = dynamic(() => import('./Filter'));
export const CloseSvg = dynamic(() => import('./CloseSvg'));
export const RealEstate = dynamic(() => import('./RealEstate'));
export const ResetDisableIcon = dynamic(() => import('./ResetDisableIcon'));
export const ConnectToWallet = dynamic(() => import('./ConnectToWallet'));
export const AvatarMaker = dynamic(() => import('./AvatarMaker'));
export const Services = dynamic(() => import('./Services'));
export const NotFound = dynamic(() => import('./404'));
export const BgCardMining = dynamic(() => import('./BgCardMining'));
export const LogoLightSvg = dynamic(() => import('./logoLightSvg'));
export const LogoSSvg = dynamic(() => import('./LogoSSvg'));
export const EventBack = dynamic(() => import('./EventBack'));
export const EventTime = dynamic(() => import('./EventTime'));
export const Audience = dynamic(() => import('./Audience'));
export const EvenetLocation = dynamic(() => import('./EventLocation'));
export const CloseShowAllSvg = dynamic(() => import('./CloseShowAllSvg'));
export const Copy = dynamic(() => import('./Copy'));
export const LevelBox = dynamic(() => import('./LevelRequired'));
export const Lock = dynamic(() => import('./Lock'));
export const TickCraft = dynamic(() => import('./TickCraft'));
export const Multiplied = dynamic(() => import('./Multiplied'));
export const WalletFill = dynamic(() => import('./WalletFill'));
export const BugIcon = dynamic(() => import('./Bug'));
export const Feedback = dynamic(() => import('./FeedBack'));
export const StartButton = dynamic(() => import('./StartButton'));
export const LetsGoBtn = dynamic(() => import('./LetsGoBtn'));
export const ConnectWalletBtn = dynamic(() => import('./ConnectWalletBtn'));
export const PinkUniSwapLogo = dynamic(() => import('./PinkUniSwapLogo'));
export const MexcGlobalLogo = dynamic(() => import('./MexcGlobalLogo'));
export const GateioSvg = dynamic(() => import('./GateioSvg'));
export const PlanetBtn = dynamic(() => import('./RoverBtn'));
export const RoverPriceBtn = dynamic(() => import('./RoverPriceBtn'));
export const ArrowDown = dynamic(() => import('./ArrowDown'));
export const ChooseLandrockerBtn = dynamic(() => import('./ChooseAvatarBtnLandrocker'));
export const ReadyPlayerMe = dynamic(() => import('./ReadyPlayerMeBtn'));
export const ChooseLineLeft = dynamic(() => import('./ChooseLineLeft'));
export const RandomBtn = dynamic(() => import('./RandomBtn'));
export const ChooseLineRight = dynamic(() => import('./ChooseRightLine'));
export const ArrowLeftLand = dynamic(() => import('./ArrowLeftLand'));
export const ArrowRightLand = dynamic(() => import('./ArrowRightLand'));
export const LandSale = dynamic(() => import('./LandSale'));
export const ArrowDownNew = dynamic(() => import('./ArrowDownNew'));
export const Kitblock = dynamic(() => import('./Kitblock'));
export const Search = dynamic(() => import('./Search'));
export const Bin = dynamic(() => import('./Bin'));
export const EditPen = dynamic(() => import('./EditPen'));
export const Checked = dynamic(() => import('./Checked'));
export const Unchecked = dynamic(() => import('./Unchecked'));
export const Cross = dynamic(() => import('./Cross'));
export const Tick = dynamic(() => import('./Tick'));
export const ArrowLeftKit = dynamic(() => import('./ArrowLeftKit'));
export const BuyInventoryBg = dynamic(() => import('./BuyInventoryBg'));
export const LmntCoin = dynamic(() => import('./LmntCoin'));
export const BuyInventoryGrayBtn = dynamic(() => import('./BuyInventoryGrayBtn'));
export const InsertSellBtn = dynamic(() => import('./InsertSellBtn'));
export const MinesBtnInventory = dynamic(() => import('./MinesInventory'));
export const PlusInventoryBtn = dynamic(() => import('./PlusInventory'));
export const InventoryCloseBtn = dynamic(() => import('./InventoryCloseBtn'));
export const BuyInventoryGrayBtnMobile = dynamic(() => import('./BuyInventoryGrayBtnMobile'));
export const NewYearHat = dynamic(() => import('./NewYearHat'));
export const Top10 = dynamic(() => import('./Top10'));
export const HexagoneLevel = dynamic(() => import('./HexagoneLevel'));
export const XPSvg = dynamic(() => import('./XPSvg'));
export const LRTCoin = dynamic(() => import('./LRTCoin'));
export const ETHCoin = dynamic(() => import('./ETHCoin'));
export const Avatar = dynamic(() => import('./Avatar'));
export const Google = dynamic(() => import('./GoogleIcon'));
export const Facebook = dynamic(() => import('./FacebookCurve'));
export const Eye = dynamic(() => import('./Eye'));
export const ClosedEye = dynamic(() => import('./ClosedEye'));
export const TickBg = dynamic(() => import('./TickBg'));
export const Enable = dynamic(() => import('./Enable'));
export const DisableBg = dynamic(() => import('./DisableBg'));
export const ConfirmUsernameTick = dynamic(() => import('./ConfirmUsernameTick'));
export const Share = dynamic(() => import('./Share'));
export const StakeSvg = dynamic(() => import('./StakeSvg'));
export const SwapSvg = dynamic(() => import('./SwapSvg'));
export const InventorySvg = dynamic(() => import('./InventorySvg'));
export const SupportSvg = dynamic(() => import('./SupportSvg'));
export const PlayIconSvg = dynamic(() => import('./PlayIconSvg'));
export const UsdtSvg = dynamic(() => import('./UsdtSvg'));
export const UsdcSvg = dynamic(() => import('./UsdcSvg'));
export const DaiSvg = dynamic(() => import('./DaiSvg'));
export const AuditSvg = dynamic(() => import('./AuditSvg'));
export const WhitepaperSvg = dynamic(() => import('./WhitepaperSvg'));
export const KYCSvg = dynamic(() => import('./KYCSvg'));
export const TelegramSocialSvg = dynamic(() => import('./TelegramSocialSvg'));
export const ChevronDownSvg = dynamic(() => import('./ChevronDownSvg'));
export const FeulSvg = dynamic(() => import('./FeulSvg'));
export const MarketBuySvg = dynamic(() => import('./MarketBuySvg'));
export const RoversSvg = dynamic(() => import('./RoversSvg'));
export const FunnelSvg = dynamic(() => import('./FunnelSvg'));
export const NewLrtSvg = dynamic(() => import('./NewLrtSvg'));
export const CreditCardSvg = dynamic(() => import('./CreditCardSvg'));
export const BuyWithCryptoSvg = dynamic(() => import('./BuyWithCrypto'));
export const HowToearnIconSvg = dynamic(() => import('./HowToearnIconSvg'));
export const HowToMineSvg = dynamic(() => import('./HowToMineSvg'));
export const SwapToLrtSvg = dynamic(() => import('./SwapToLrtSvg'));
export const InviteFriendsSvg = dynamic(() => import('./InviteFriendsSvg'));
export const ArrowDownSvg = dynamic(() => import('./ArrowDownSvg'));
export const LrtWithShadowSvg = dynamic(() => import('./LrtBlackSvg'));
export const ArrowDown2 = dynamic(() => import('./ArrowDown2'));
export const TicketActiveSvg = dynamic(() => import('./TicketActiveSvg'));
export const TicketInActiveSvg = dynamic(() => import('./TicketInActiveSvg'));
export const ConnectLineSvg = dynamic(() => import('./ConnectLineSvg'));
export const ConnectLine2Svg = dynamic(() => import('./ConnectLine2Svg'));
export const ConnectLine3Svg = dynamic(() => import('./ConnectLine3Svg'));
export const ConnectLine4Svg = dynamic(() => import('./ConnectLine4Svg'));
export const ConnectLine5Svg = dynamic(() => import('./ConnectLine5Svg'));
export const GreenTick = dynamic(() => import('./GreenTick'));
export const TwitterSvg = dynamic(() => import('./Social/Twitter'));
export const WebSVG = dynamic(() => import('./Social/Web'));
export const WindowsSVG = dynamic(() => import('./Social/Windows'));
export const EconomySvg = dynamic(() => import('./EconomySvg'));
export const ArrowFillSvg = dynamic(() => import('./ArrowFillSvg'));
export const HeartSvg = dynamic(() => import('./HeartSvg'));
export const RetweetSvg = dynamic(() => import('./RetweetSvg'));
export const FollowSvg = dynamic(() => import('./FollowSvg'));
export const QuoteSvg = dynamic(() => import('./QuoteSvg'));
export const QuoteTweetSvg = dynamic(() => import('./QuoteTweetSvg'));
export const AchievementsSvg = dynamic(() => import('./AchievementsSvg'));
export const RecipeIcon = dynamic(() => import('./RecipeIcon'));
export const PropertiesSvg = dynamic(() => import('./PropertiesSvg'));
export const AreaSvg = dynamic(() => import('./AreaSvg'));
export const StorageSvg = dynamic(() => import('./StorageSvg'));
export const DetailIcon = dynamic(() => import('./DetailIcon'));
export const RoundedTriangleSvg = dynamic(() => import('./RoundedTriangleSvg'));
export const PcSvg = dynamic(() => import('./PcSvg'));
export const AndroidSvg = dynamic(() => import('./AndroidSvg'));
export const IosSvg = dynamic(() => import('./IosSvg'));
export const HookSvg = dynamic(() => import('./HookSvg'));
export const HookSmallSvg = dynamic(() => import('./HookSmallSvg'));
export const SequenceArrows = dynamic(() => import('./SequenceArrows'));
export const WarningSvg = dynamic(() => import('./WarningSvg'));
export const ArrowLeftSvg = dynamic(() => import('./ArrowLeftSvg'));
export const ArrowRightSvg = dynamic(() => import('./ArrowRightSvg'));
export const LiteWebEditionSvg = dynamic(() => import('./LiteWebEditionSvg'));
export const Pro3DeditionSvg = dynamic(() => import('./Pro3DeditionSvg'));
export const RangeSvg = dynamic(() => import('./RangeSvg'));
export const RewardSvg = dynamic(() => import('./RewardSvg'));
export const QuestionMarkSvg = dynamic(() => import('./QuestionMarkSvg'));
export const TrophySvg = dynamic(() => import('./TrophySvg'));
export const ClaimedTrophySvg = dynamic(() => import('./ClaimedTrophySvg'));
export const MediumSvg = dynamic(() => import('./MediumSvg'));
export const PlaySvg = dynamic(() => import('./seasons/zero/PlaySvg'));
export const CtaBorderSvg = dynamic(() => import('./seasons/zero/CtaBorderSvg'));
export const CupSvg = dynamic(() => import('./seasons/zero/CupSvg'));
export const GoHighSvg = dynamic(() => import('./Finance/cards/Swap/GoHighSvg'));
export const RevolutionSvg = dynamic(() => import('./Finance/cards/Swap/RevolutionSvg'));
export const AndroidXSvg = dynamic(() => import('./DownloadGame/AndroidXSvg'));
export const MacSvg = dynamic(() => import('./DownloadGame/MacSvg'));
export const AppleSvg = dynamic(() => import('./DownloadGame/AppleSvg'));
export const GreenBtnSvg = dynamic(() => import('./DownloadGame/GreenBtnSvg'));
export const PcXSvg = dynamic(() => import('./DownloadGame/PcXSvg'));
export const ZipperSvg = dynamic(() => import('./DownloadGame/ZipperSvg'));
export const CustomPlaySvg = dynamic(() => import('./seasons/zero/CustomPlaySvg'));
export const LrtDarkSvg = dynamic(() => import('./LrtDarkSvg'));
export const SeasonZeroSvg = dynamic(() => import('./seasons/zero/SeasonZeroSvg'));
export const StakeXSvg = dynamic(() => import('./TradingCountDown/StakeXSvg'));
export const MetamaskSvg = dynamic(() => import('./MetamaskSvg'));
export const BuyLrtSvg = dynamic(() => import('./BuyLrtSvg'));
export const VerifiedSvg = dynamic(() => import('./VerificationModals/VerifiedSvg'));
export const UnverifiedSvg = dynamic(() => import('./VerificationModals/UnverifiedSvg'));
export const PurpleBtnSvg = dynamic(() => import('./DownloadGame/PurpleBtnSvg'));
export const DownloadGameSvg = dynamic(() => import('./DownloadGameSvg'));
export const UploadIdeaSvg = dynamic(() => import('./UploadIdeaSvg'));
export const TaskIconSvg = dynamic(() => import('./TaskIconSvg'));
export const CalenderSvg = dynamic(() => import('./CalenderSvg'));
export const PointTrackSvg = dynamic(() => import('./PointTrackSvg'));
export const ThreeDSvg = dynamic(() => import('./3DSvg'));
export const LockSvg = dynamic(() => import('./LockSvg'));
export const LRSvg = dynamic(() => import('./LRSvg'));

//marketplace tabs
export const PlanetsSvg = dynamic(() => import('./Marketplace/PlanetsSvg'));
export const LootboxSvg = dynamic(() => import('./Marketplace/Lootbox'));
export const MaterialsSvg = dynamic(() => import('./Marketplace/MaterialsSvg'));
export const FuelSvg = dynamic(() => import('./Marketplace/FuelSvg'));
export const CompanionSvg = dynamic(() => import('./Marketplace/CompanionSvg'));
export const PrimaryWeapon = dynamic(() => import('./Marketplace/PrimaryWeapon'));
export const SuitSvg = dynamic(() => import('./Marketplace/SuitSvg'));
export const SecondaryWeapon = dynamic(() => import('./Marketplace/SecondaryWeapon'));
export const MaticSvg = dynamic(() => import('./MATICSvg'));
export const CalculateLrtSvg = dynamic(() => import('./CalculateLrt'));
export const FuelTardingSvg = dynamic(() => import('./Marketplace/FuelDetail/FuelTarding'));
export const FuelManagementSvg = dynamic(() => import('./Marketplace/FuelDetail/FuelManagement'));
export const FuelTravelSvg = dynamic(() => import('./Marketplace/FuelDetail/FuelTravelSvg'));
export const NewFuelSvg = dynamic(() => import('./NewFeulSvg'));

// Twitter Logos
export const LRLogoSvg = dynamic(() => import('./Twitter/LRLogoSvg'));
export const VerifiedGoldStarSvg = dynamic(() => import('./Twitter/VerifiedGoldStarSvg'));
export const VerifiedBlueStarSvg = dynamic(() => import('./Twitter/VerifiedBlueStarSvg'));
export const RetweetXSvg = dynamic(() => import('./Twitter/RetweetXSvg'));
export const TweetEyeSvg = dynamic(() => import('./Twitter/TweetEyeSvg'));
export const TweetHeartSvg = dynamic(() => import('./Twitter/TweetHeartSvg'));
export const TweetShareSvg = dynamic(() => import('./Twitter/TweetShareSvg'));
export const TweetMessageSvg = dynamic(() => import('./Twitter/TweetMessageSvg'));

// Task center
export const TaskProfileSvg = dynamic(() => import('./TaskCenter/ProfileSvg'));
export const TaskAllSvg = dynamic(() => import('./TaskCenter/AllSvg'));
export const PrizeSVG = dynamic(() => import('./TaskCenter/PrizeSvg'));
export const TaskSocialSvg = dynamic(() => import('./TaskCenter/SocialSvg'));
export const TaskReferralSvg = dynamic(() => import('./TaskCenter/ReferralSvg'));
export const TaskGameSvg = dynamic(() => import('./TaskCenter/GameSvg'));
export const TaskNftSvg = dynamic(() => import('./TaskCenter/NftSvg'));

// light game
export const ArrowBarRightSvg = dynamic(() => import('./LiteGame/ArrowRight'));
export const LiteGameQuestionMarkSvg = dynamic(() => import('./LiteGame/LiteGameQuestionMarkSvg'));

// Why Buying lrt
export const WhyBuyingLRTOne = dynamic(() => import('./WhyBuyingLrt/WhyBuyingLRTOne'));
export const WhyBuyingLRTTwo = dynamic(() => import('./WhyBuyingLrt/WhyBuyingLRTTwo'));
export const WhyBuyingLRTThree = dynamic(() => import('./WhyBuyingLrt/WhyBuyingLRTThree'));
export const WhyBuyingLRTFour = dynamic(() => import('./WhyBuyingLrt/WhyBuyingLRTFour'));

// home
export const BingxSvg = dynamic(() => import('./home/BingxSvg'));
export const LandrockerLogoLight = dynamic(() => import('./home/LandrockerLogoLight'));
export const MexclogoSvg = dynamic(() => import('./home/MexclogoSvg'));
export const UniswapLogoSvg = dynamic(() => import('./home/UniswapLogoSvg'));
export const ByeBit = dynamic(() => import('./home/ByeBit'));
export const BinanceSvg = dynamic(() => import('./home/BinanceSvg'));
export const GateIoSvg = dynamic(() => import('./home/GateIoSvg'));
export const KucoInSvg = dynamic(() => import('./home/KucoInSvg'));
export const DexSvg = dynamic(() => import('./home/DexSvg'));
export const CoinsultSvg = dynamic(() => import('./home/CoinsultSvg'));
export const CertikSvg = dynamic(() => import('./home/CertikSvg'));
export const HeroChartSvg = dynamic(() => import('./Hero/HeroChart'));
export const HeroHeartSvg = dynamic(() => import('./Hero/HeroHeartSvg'));
export const HeroShieldSvg = dynamic(() => import('./Hero/HeroShield'));
// home
export const PassportLogoSvg = dynamic(() => import('./passport/PassportLogoSvg'));

// help
export const HelpSvg = dynamic(() => import('./Help/HelpSvg'));
export const FaqSvg = dynamic(() => import('./Help/FaqSvg'));
export const ForumSvg = dynamic(() => import('./Help/ForumSvg'));
export const OnePagerSvg = dynamic(() => import('./Help/OnePagerSvg'));
export const TicketSvg = dynamic(() => import('./Help/TicketSvg'));
export const WhitePaperSvg = dynamic(() => import('./Help/WhitepaperSvg'));

// telegram
export const TelegramCrystalSvg = dynamic(() => import('./Telegram/CrystalSvg'));
export const TelegramPlanetsSvg = dynamic(() => import('./Telegram/PlanetsSvg'));
export const TelegramRoversSvg = dynamic(() => import('./Telegram/RoversSvg'));

// how to earn
export const HowToMPSvg = dynamic(() => import('./HowToEarn/mp'));
