import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mantine/core';
import cn from 'classnames';
import { useAccount, useChainId, useDisconnect } from 'wagmi';
import { Typography } from '@/components/Atoms';
import { ArrowBarRightSvg, ArrowRightSvg, Copy, LRTCoin } from '@/components/Svg';
import { BalanceLRT } from '@/utils/marketplace';
import { formatNumberDecimals, numberSeparator } from '@/utils/numberSeparator';
import { truncateAddress } from '@/utils/utils';
import CustomSkeleton from '../CustomSkeleton';
import Link from 'next/link';
import useConnectWallet from '@/hooks/common/useConnectWallet';
import CopyToClipboard from '@/utils/CopyToClipboard';
import InventoryTwoSvg from '@/components/Svg/InventoryTwoSvg';
import InventorySvg from '@/components/Svg/InventorySvg';
import ArrowRight from '@/components/Svg/LiteGame/ArrowRight';

const WalletInfo = () => {
  const { address, connector } = useAccount();
  const [userLrtAmount, setUserLRTAmount] = useState<any>();
  const { handleClickWallet } = useConnectWallet();
  const chainId = useChainId();
  const [showBalance, setShowBalance] = useState(true);

  const handleBalanceLRT = async () => {
    setShowBalance(chainId == 137);

    if (chainId == 137) {
      let lrtAmount = await BalanceLRT({ address });
      setUserLRTAmount(lrtAmount);
    }
  };

  useEffect(() => {
    if (address !== undefined) {
      handleBalanceLRT();
    }
  }, [address, chainId]);

  const { disconnect } = useDisconnect();

  return (
    <div
      className={cn(
        'bg-[#1C1F26] px-4 pt-3 pb-4 rounded-t-[0] rounded-b-lg absolute -right-20 w-[280px] rounded-lg py-4 font-normal',
        `${address ? 'top-5' : 'top-0'}`,
      )}
    >
      {address == undefined ? (
        <div
          className='text-[#58f862] py-1 rounded-md w-full border border-[#0F9918] hover:bg-[#0f991836] hover:text-[#58f862] cursor-pointer transition-colors ease-out duration-300 text-center'
          onClick={() => {
            handleClickWallet();
          }}
        >
          <Typography size='s16ToS14'>Connect Wallet</Typography>
        </div>
      ) : (
        <>
          <div className='mb-2'>
            <Link href='/inventory' passHref>
              <a
                href=''
                className={cn(
                  'w-full flex rounded-lg transition hover:shadow hover:bg-secondary/10 hover:scale-105 cursor-pointer justify-between items-center',
                )}
              >
                <div className='flex gap-1 items-center'>
                  <div className='w-[20px] h-[20px]'>
                    <InventorySvg width='100%' height='100%' />
                  </div>
                  <Typography size='s14ToS12' className='text-white text-center py-1 w-full'>
                    Inventory
                  </Typography>
                </div>
                <div className='w-[15px] h-[15px]'>
                  <ArrowRightSvg width={'100%'} height={'100%'} />
                </div>
              </a>
            </Link>
          </div>
          <div>
            {typeof userLrtAmount === 'undefined' ? (
              <CustomSkeleton width={'100%'} height='38px' animate />
            ) : (
              <>
                {showBalance && (
                  <div className='flex justify-between items-center ps-1 pe-2 w-full border border-[#92A2AF] rounded-md py-1'>
                    <div className='flex items-center gap-1'>
                      <LRTCoin width={28} height={28} />
                      <Typography size='s16ToS14' className=''>
                        LRT
                      </Typography>
                    </div>
                    <Typography className=''>{numberSeparator(formatNumberDecimals(+userLrtAmount, 6))}</Typography>
                  </div>
                )}
              </>
            )}
            <div className='my-2 flex justify-center items-center'>
              <Tooltip label={address} withArrow>
                <Typography size='s14ToS12' className='text-[#92A2AF]'>
                  Address: {truncateAddress(address)}
                </Typography>
              </Tooltip>
              <div className='ms-2 cursor-pointer' onClick={() => CopyToClipboard(address, { successMessageKey: 'Wallet address Copied!' })}>
                <Copy />
              </div>
            </div>
          </div>
          <div
            className='text-[#D91122] py-1 rounded-md w-full border border-[#D91122] hover:bg-[#35070b] cursor-pointer transition-colors ease-out duration-300 text-center'
            onClick={() => {
              disconnect();
            }}
          >
            <Typography size='s16ToS14'>Disconnect</Typography>
          </div>
        </>
      )}
    </div>
  );
};

export default WalletInfo;
