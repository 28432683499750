/* eslint-disable sonarjs/no-duplicate-string */
import { useEffect, useRef, useState } from 'react';
import { useSession } from 'next-auth/react';
import api from '@/api/services/achievements';
import { Typography } from '@/components/Atoms';
import BasicModal from '@/components/Common/Modals/BasicModal/BasicModal';
import { MyRank, UserPintData } from '@/types/Achievements';
import { useStore } from '@/zustand/store/store';
import styles from './LeaderBoardModal.module.scss';
import LeaderBoardTable from './Table';

const LeaderBoardModal = () => {
  const { data: session } = useSession();
  const [myRank, setMyRank] = useState<UserPintData>();
  const [showMyRank, setShowMyRank] = useState<boolean>(false);
  const checkboxRef = useRef<HTMLInputElement>(null);

  const { setIsLeaderboardModal } = useStore();

  const handleClose = () => {
    setIsLeaderboardModal(false);
  };

  const fetchMyRank = async () => {
    try {
      if (checkboxRef.current.checked) {
        const res = await api.getMyRank();
        setShowMyRank(true);
        setMyRank(res.data.user_pint_data);
      } else {
        setShowMyRank(false);
      }
    } catch (error) {}
  };

  return (
    <BasicModal handleClose={handleClose}>
      <div className={styles.wrapper}>
        <div className={styles.textWrapper}>
          <Typography size='s26ToS16' className={styles.headTitle}>
            leaderboard
          </Typography>
          <Typography className={styles.subTitle}>Explore the leaderboard to see the top performers for this task.</Typography>
          <div className={styles.findmeWrapper}>
            <input ref={checkboxRef} id='findMe' type='checkbox' onChange={fetchMyRank} />
            <label htmlFor='findMe'>Me</label>
          </div>
        </div>
        <LeaderBoardTable myRank={myRank} showMyRank={showMyRank} />
      </div>
    </BasicModal>
  );
};
export default LeaderBoardModal;
