import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

interface IHeadProps {
  isHome?: boolean;
  title: string;
  imageUrl: string;
  description: string;
}

const HeadPage = (props: IHeadProps) => {
  const { title, description, isHome = false, imageUrl } = props;
  const router = useRouter();
  const href = `https://landrocker.io${router.asPath}`;

  return (
    <Head>
      <title>LandRocker | {title}</title>
      <meta itemProp='name' content={title} />
      <meta itemProp='description' content={description} />
      <meta itemProp='image' content={imageUrl} />

      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={imageUrl} />
      <meta name='twitter:site' content='@landrockergame' />
      <meta name='twitter:creator' content='@landrockergame' />

      <meta property='og:type' content={isHome ? 'website' : 'article'} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={imageUrl} />
      <meta property='og:url' content={isHome ? 'https://landrocker.io' : href} />
      <meta property='og:site_name' content='LandRocker' />

      <link rel='canonical' href={href} />
      <meta name='apple-mobile-web-app-capable' content='yes' />
      <meta name='mobile-web-app-capable' content='yes' />
      <meta name='description' content={description} />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta name='robots' content='max-image-preview:large' />

      {/* FavIcon & Manifest */}
      <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png?v=5' />
      <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png?v=5' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png?v=5' />
      <link rel='manifest' href='/site.webmanifest?v=5' />
      <link rel='mask-icon' href='/safari-pinned-tab.svg?v=5' color='#5bbad5' />
      <link rel='shortcut icon' href='/favicon.ico?v=5' />
      <meta name='apple-mobile-web-app-title' content='LandRocker' />
      <meta name='application-name' content='LandRocker' />
      <meta name='msapplication-TileColor' content='#ffffff' />
      <meta name='theme-color' content='#ffffff' />

      {/* No Index */}
      {process.env.NEXT_PUBLIC_NO_INDEX === 'true' && <meta name='robots' content='noindex, nofollow' />}
    </Head>
  );
};
export default React.memo(HeadPage);
