import { StateCreator } from 'zustand';
import { IConfirmModal, ISwapSlice } from './Interface';

export const swapSlice: StateCreator<ISwapSlice> = (set) => ({
  //state initializing
  swapData: [],
  modalVisible: false,
  restrictedModal: { visible: false, type: 'a' },
  type: '',
  id: '',
  isBluePrint: false,
  status: 'pending',
  data: [
    {
      image: '',
      name: '',
    },
  ],
  //state handler functions
  setSwapData: (swapData: string) => {
    set({ swapData: swapData });
  },
  setConfirmModal: ({ modalVisible, type, isBluePrint, id, data, status }: IConfirmModal) => {
    set({ modalVisible, type, isBluePrint, id, data, status });
  },
  setRestrictedModal: (restrictedModal: { visible: boolean, type: string }) => {
    set({ restrictedModal });
  },
});
