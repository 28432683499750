import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Input, UnstyledButton } from '@mantine/core';
import classNames from 'classnames';
import jwt_decode from 'jwt-decode';
import { useSession } from 'next-auth/react';
import api from '@/api/services/lite-game';
import { CustomModal } from '@/components/Molecules';
import { useStore } from '@/zustand/store/store';
import styles from './InviteFriendModal.module.scss';

const InviteFriendModal = ({ opened, onCloseModal }: { opened?: boolean; onCloseModal?: any }) => {
  const { inviteFriendModal, setInviteFriendModal } = useStore();
  const [token, setToken] = useState(null);
  const [referalData, setReferalData] = useState({
    status: '',
    user_referral_code: '',
    user_referral_link: '',
  });

  const getReferalData = async () => {
    try {
      const response = await api.getUserReferalData();
      if (response.status === 200) {
        const { data } = response;
        if (data.status) {
          setReferalData(data);
        }
      } else {
      }
    } catch (e) {}
  };

  useEffect(() => {
    getReferalData();
  }, []);
  const session = useSession();
  useEffect(() => {
    if (session.data !== undefined && session.data !== null && session.status !== 'loading') {
      let decodedAccessToken: { exp: any } = jwt_decode(
        //@ts-ignore
        session.data.accessToken,
        //@ts-ignore
      ).token;

      setToken(decodedAccessToken);
    }
  }, [session]);

  const handleCopyClick = (data) => {
    if (navigator.clipboard.writeText(data)) {
      toast.success('Copied to clipboard!', {
        position: 'bottom-center',
        autoClose: 2000,
        closeOnClick: true,
        theme: 'dark',
      });
    }
  };

  const telegramInviteLink = `https://t.me/landrocker_invite_bot?start=generate_referral_message=${token}`;
  return (
    <CustomModal
      centered
      styles={{
        body: {
          backgroundColor: '#20232B',
          maxHeight: '90%!important',
          maxWidth: '886px',
          borderRadius: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
        // @ts-ignore
        content: {
          backgroundColor: 'transparent',
          overflowY: 'visible!important',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
        overlay: { zIndex: 86 },
        inner: { zIndex: 87 },
      }}
      visible={inviteFriendModal || opened}
      onClose={() => {
        if (!opened) {
          setInviteFriendModal(false);
        }
        onCloseModal?.();
      }}
      overlayProps={{
        opacity: 0.55,
        blur: 3,
      }}
      className='reletive'
    >
      <div className={styles.contentContainer}>
        <div className={styles.shadowContainer}>
          <div className={`${styles.shadow}`}></div>
        </div>
        <div className={styles.badgeDescContainer}>
          <div className={styles.modalTitle}>Let’s unite, conquer, and make history!</div>

          <div className={styles.promotionContainer}>
            <span className={styles.textItem}>
              As a soldier in the ULA, every command you complete and every friend you invite strengthens our army—and your rewards. Together, we’re
              growing stronger, reaching new heights, and claiming valuable rewards. The bigger our army, the greater the spoils.
            </span>
          </div>

          <div className={styles.form}>
            <div className={styles.label}>Referral invite link:</div>
            <div className={styles.inputContainer}>
              <Input className={styles.input} value={referalData?.user_referral_link} />
              <UnstyledButton
                onClick={() =>
                  handleCopyClick(`Hey there,:clap:
Get ready for an epic adventure! Here’s your exclusive invitation link to join LandRocker. Download the game, mine, explore fascinating landscapes, take on thrilling quests, and unleash your creativity.
Join now and let the fun begin!
${referalData?.user_referral_link}`)
                }
                className={classNames(styles.button, 'gtm-invite-link')}
              >
                Copy
              </UnstyledButton>
            </div>
            {/* <div className={styles.label}>Invite code:</div>
            <div className={styles.inputContainer}>
              <Input className={styles.input} value={referalData?.user_referral_code} />
              <UnstyledButton onClick={() => handleCopyClick(referalData?.user_referral_code)} className={styles.button}>
                Copy
              </UnstyledButton>
            </div> */}
            {/* <div className={styles.label}>Telegram referral invite link:</div>
            <div className={styles.inputContainer}>
              <Input className={styles.input} value={telegramInviteLink} />
              <UnstyledButton onClick={() => handleCopyClick(telegramInviteLink)} className={classNames(styles.button, 'gtm-invite-telegram')}>
                Copy
              </UnstyledButton>
            </div> */}
            <div className={styles.label}>Referral code:</div>
            <div className={styles.inputContainer}>
              <Input className={styles.input} value={referalData.user_referral_code} />
              <UnstyledButton
                onClick={() => handleCopyClick(referalData.user_referral_code)}
                className={classNames(styles.button, 'gtm-invite-copy-referral')}
              >
                Copy
              </UnstyledButton>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default InviteFriendModal;
