import React from 'react';
import FuelCard from './FuelCard/FuelCard';
import GeneralCard from './GeneralCard';
import MaterialCard from './MaterialCard/MaterialCard';
import PlanetCard from './PlanetCard/PlanetCard';
import RoverCard from './RoverCard/RoverCard';

export enum EMiningCardType {
  Planet = 'Planet',
  Material = 'Element',
  Rover = 'Rover',
  Fuel = 'Fuel',
  Lootbox = 'Loot Box',
  Companion = 'Companion',
  Suit = 'Suit',
  PrimaryWeapon = 'Weapon',
  SecondaryWeapon = 'TacticalTools',
}

interface IMininCardProps {
  type: EMiningCardType;
  data: any;
  tokenPrice: string;
}

const MiningCard = ({ data, type, tokenPrice }: IMininCardProps) => {
  switch (type) {
    case EMiningCardType.Fuel:
      return <FuelCard data={data} tokenPrice={tokenPrice} />;
    case EMiningCardType.Planet:
      return <PlanetCard data={data} />;
    case EMiningCardType.Rover:
      return <RoverCard data={data} />;
    case EMiningCardType.Material:
      return <MaterialCard data={data} />;
    case EMiningCardType.Lootbox:
      return <GeneralCard cardType={EMiningCardType.Lootbox} data={data} tokenPrice={tokenPrice} />;
    case EMiningCardType.Companion:
      return <GeneralCard cardType={EMiningCardType.Companion} data={data} tokenPrice={tokenPrice} />;
    case EMiningCardType.Suit:
      return <GeneralCard cardType={EMiningCardType.Suit} data={data} tokenPrice={tokenPrice} />;
    case EMiningCardType.PrimaryWeapon:
      return <GeneralCard cardType={EMiningCardType.PrimaryWeapon} data={data} tokenPrice={tokenPrice} />;
    case EMiningCardType.SecondaryWeapon:
      return <GeneralCard cardType={EMiningCardType.SecondaryWeapon} data={data} tokenPrice={tokenPrice} />;
  }
};

export default MiningCard;
