/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
const HttpBackend = require('i18next-http-backend/cjs');
const ChainedBackend = require('i18next-chained-backend').default;
const LocalStorageBackend = require('i18next-localstorage-backend').default;

const isBrowser = typeof window !== 'undefined';
const isDev = process.env.NODE_ENV === 'development';

module.exports = {
  debug: false,
  backend: {
    backendOptions: [{ expirationTime: isDev ? 0 : 60 * 60 * 1000 }, {}], // 1 hour
    backends: isBrowser ? [LocalStorageBackend, HttpBackend] : [],
  },
  i18n: {
    // all the locales supported in the application
    locales: ['en', 'ru', 'pt', 'tr', 'id', 'fr', 'es', 'de', 'sw', 'fil', 'vi', 'ar', 'it', 'ja', 'nl', 'uk', 'zh', 'tl', 'hi', 'th'],
    // locales: ['en', 'fr', 'es', 'de'],
    // the default locale to be used when visiting
    // a non-localized route (e.g. `/about`)
    defaultLocale: 'en',
  },
  serializeConfig: false,
  use: isBrowser ? [ChainedBackend] : [],
};
