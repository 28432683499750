import { StateCreator } from 'zustand';
import { IBlueprintDetailDataProps, INFTSuccessCraftProps } from '@/types/Blueprint';
import { IBlueprintDetailSlice } from './Interface';

export const blueprintSlice: StateCreator<IBlueprintDetailSlice> = (set) => ({
  //state initializing
  blueprintDetail: {
    asset_currency: '',
    asset_image: '',
    asset_name: '',
    asset_price: 0,
    craft_fee: 0,
    craft_fee_currency: '',
    craft_id: 0,
    asset_property: [],
    material_need: [],
    recipe_image: '',
    recipe_name: '',
    recipe_owner: '',
    recipe_craft_total: 0,
    recipe_description: '',
    recipe_crafted: 0,
    recipe_rarity: '',
    user_material: [],
    recipe_id: '',
  },
  userLRTAmount: '0',
  userVestedAmount: '0',
  modalVisibleCraft: null,
  isShowCraftModal: false,
  isShowSuccessCraftModal: false,
  isLoadingCraft: true,
  nftSuccessCraft: {
    asset_id: '',
    asset_image: '',
    asset_name: '',
    asset_type: '',
    message: '',
    status: false,
  },
  setIsLoadingCraft: (isLoadingCraft) => {
    set({ isLoadingCraft: isLoadingCraft });
  },
  //state handler functions
  setBlueprintDetail: (blueprintDetail: IBlueprintDetailDataProps) => {
    set({ blueprintDetail: blueprintDetail });
  },
  setModalVisibleCraft: (modalVisibleCraft: any) => {
    set({ modalVisibleCraft: modalVisibleCraft });
  },
  setIsShowCraftModal: (isShowCraftModal: boolean) => {
    set({ isShowCraftModal: isShowCraftModal });
  },
  setUserLRTAmount: (userLRTAmount: string) => {
    set({ userLRTAmount: userLRTAmount });
  },
  setUserVestedAmount: (userVestedAmount: string) => {
    set({ userVestedAmount: userVestedAmount });
  },
  setIsShowSuccessCraftModal: (isShowSuccessCraftModal: boolean) => {
    set({ isShowSuccessCraftModal: isShowSuccessCraftModal });
  },
  setNftSuccessCraft: (nftSuccessCraft: INFTSuccessCraftProps) => {
    set({ nftSuccessCraft: nftSuccessCraft });
  },
});
