import { gql, useQuery } from 'urql';

interface IVestedAmount {
  address: string;
}

export const VestedAmount = (props: IVestedAmount) => {
  const { address } = props;

  const getVestedAmount = gql`
  query MyQuery {
    users(where: {address: "${address}"}) {
      vestedAmount
    }
  }
  `;

  const shouldPause = address == undefined;
  const [result, reexecuteQuery] = useQuery({
    query: getVestedAmount,
    requestPolicy: 'network-only',
    variables: { address },
    pause: shouldPause,
  });

  return result;
};
