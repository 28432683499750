import * as React from 'react';

const DiscordSvg = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 12 9'
    {...props}
    onMouseEnter={(e) => (e.currentTarget.querySelector('path').style.fill = '#29D6DF')}
    onMouseLeave={(e) => (e.currentTarget.querySelector('path').style.fill = 'white')}
    style={{ transition: 'fill 0.3s ease' }} // Smooth transition
  >
    <path
      fillRule='evenodd'
      d='M10.16.75A10 10 0 0 0 7.7 0c-.12.2-.22.42-.32.64a9.3 9.3 0 0 0-2.75 0C4.54.42 4.42.2 4.32 0c-.86.14-1.7.4-2.48.75A9.82 9.82 0 0 0 .05 7.5C.97 8.16 2 8.67 3.1 9c.24-.33.46-.68.65-1.04-.36-.13-.7-.3-1.03-.48l.25-.19c1.93.9 4.15.9 6.08 0l.25.19c-.33.19-.67.35-1.03.48.19.37.4.71.65 1.04a9.96 9.96 0 0 0 3.04-1.5 9.82 9.82 0 0 0-1.8-6.76zM4.01 6.13c-.6 0-1.08-.53-1.08-1.18 0-.65.47-1.2 1.07-1.2.61 0 1.1.55 1.09 1.2C5.08 5.6 4.6 6.13 4 6.13zm3.98 0c-.59 0-1.08-.53-1.08-1.18 0-.65.48-1.2 1.08-1.2.61 0 1.1.55 1.08 1.2 0 .65-.47 1.18-1.08 1.18z'
      fill='white' // Initial fill color
    />
  </svg>
);

export default DiscordSvg;
