// @ts-nocheck
import { useEffect, useState, useRef } from 'react';
import useWebSocket from '../useWebSocket';
import jwt_decode from 'jwt-decode';
import { useSession } from 'next-auth/react';
import { useStore } from '@/zustand/store/store';
import { useRouter } from 'next/router';

const useCraftReward = () => {
  const { data, status } = useSession();
  const [userId, setUserId] = useState<string>('');
  const { setIsLoadingCraft } = useStore();
  const prevStateRef = useRef<string>('');
  const router = useRouter();

  useEffect(() => {
    if (data !== undefined && data !== null && status !== 'loading') {
      let decodedAccessToken: { exp: any } = jwt_decode(data.accessToken).token;
      setUserId(decodedAccessToken as unknown as string);
    }
  }, [data]);

  const { messages } = useWebSocket<Record<'message_type' | 'content' | 'state' | 'user_id', string>>('wss://gn.landrocker.io/ws');

  useEffect(() => {
    if (messages && messages?.user_id === userId) {
      const newState = messages?.state;

      if (messages?.message_type === 'craft' && newState === 'completed') {
        router.push('/inventory?tab=assets');

        setIsLoadingCraft(false);
      }

      prevStateRef.current = newState; // Update the ref to the new state
    }
  }, [messages, userId]);
};

export default useCraftReward;
