/* eslint-disable import/no-anonymous-default-export */
import { IExplore } from '@/types/DTO/marketplace';
import {
  IGetAllPlanetsDataData,
  IGetAllPlanetsProps,
  IGetAllRoversData,
  IGetAllUniversesData,
  IGetFinishMiningParams,
  IGetStartMiningParams,
} from '@/types/lite-game';
import { IStartMiningData } from '@/zustand/store/slices/lite-game/Interface';
import { axiosInstanceTwo } from '../serviceTest';

export default {
  getAllPlanets: (params: IGetAllPlanetsProps) =>
    axiosInstanceTwo.get<IGetAllPlanetsDataData>('/api/v1/explore/get/planets/', {
      baseURL: process.env.NEXT_PUBLIC_P2E_API,
      params,
    }),
  getAllUniverses: () =>
    axiosInstanceTwo.get<IGetAllUniversesData>('/api/v1/explore/get/universes/', {
      baseURL: process.env.NEXT_PUBLIC_P2E_API,
    }),
  getAllRovers: (params: { rover_id?: string; planet_id?: string }) =>
    axiosInstanceTwo.get<IGetAllRoversData>('/api/v1/explore/get/rovers/', {
      baseURL: process.env.NEXT_PUBLIC_P2E_API,
      params,
    }),
  setDataLite: (params: { explore_id: string; positions: Array<{ x: number; y: number; z: number }> }) =>
    axiosInstanceTwo.post<IExplore>('/api/v1/explore/set/data/lite', { ...params }, { baseURL: process.env.NEXT_PUBLIC_P2E_API }),
  getUserData2DGame: (token?: string) =>
    axiosInstanceTwo.get('/api/2dmining/get/user/data', {
      headers: {
        token,
      },
    }),
  getUserReferalData: () => axiosInstanceTwo.get('/api/user/referral/data'),
  authorizeUserPlayGame: () => axiosInstanceTwo.get('/api/mining/User/authorize/play/lite'),
  getStartMining: (params: IGetStartMiningParams) =>
    axiosInstanceTwo.post<IStartMiningData>('/api/v1/explore/start/mining/lite/new', { ...params }, { baseURL: process.env.NEXT_PUBLIC_P2E_API }),
  getFinishMining: (params: IGetFinishMiningParams) =>
    axiosInstanceTwo.post<IStartMiningData>('/api/v1/explore/finish/mining/lite/new', { ...params }, { baseURL: process.env.NEXT_PUBLIC_P2E_API }),
  explor: (params: { category?: string; offset: number; limit: number; district?: string | string[]; parcel?: string | string[] }) =>
    axiosInstanceTwo.get<IExplore>('/api/get/assets', { params }),
};
