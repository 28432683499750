import { FaqSvg, ForumSvg, HelpSvg, OnePagerSvg, TicketSvg, WhitePaperSvg } from '@/components/Svg';

export const HELP_LINKS = [
  {
    title: 'help',
    href: 'https://help.landrocker.io/',
    icon: HelpSvg,
  },
  {
    title: 'forum',
    href: 'https://forum.landrocker.io/',
    icon: ForumSvg,
  },
  {
    title: 'ticket',
    href: 'https://ticket.landrocker.io/#login',
    icon: TicketSvg,
  },
  {
    title: 'whitepaper',
    href: 'https://whitepaper.landrocker.io/?_gl=1*1ynnng7*_gcl_au*NTIzODI0MTcyLjE3MjM2MTc0MzUuMTMwMDY3NjkyNi4xNzI2Mzc4MzYzLjE3MjYzNzgzNjM.',
    icon: WhitePaperSvg,
  },
  {
    title: 'onePager',
    href: 'https://onepager.landrocker.io/?_gl=1*1ynnng7*_gcl_au*NTIzODI0MTcyLjE3MjM2MTc0MzUuMTMwMDY3NjkyNi4xNzI2Mzc4MzYzLjE3MjYzNzgzNjM.',
    icon: OnePagerSvg,
  },
  {
    title: 'faq',
    href: 'https://landrocker.io/faq',
    icon: FaqSvg,
  },
];
