import styles from './LoadingBalls.module.scss';

interface ILoadingBallsProps {}

const LoadingBalls = (props: ILoadingBallsProps) => {
  const {} = props;

  return (
    <div className='flex w-full h-full justify-center items-center mt-1'>
      <div className={styles.wrapper}>
        <div className={styles.ball} />
        <div className={styles.ball} />
        <div className={styles.ball} />
      </div>
    </div>
  );
};
export default LoadingBalls;
