import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { LinkedIn, TelegramSvg } from '@/components/Svg';
import DiscordSvg from '../../Svg/Discord';
import FacebookSvg from '../../Svg/Facebook';
import InstagramSvg from '../../Svg/Instagram';
import TwitterSvg from '../../Svg/twitter';
import YoutubeSvg from '../../Svg/youtube';
import { Isocial } from '../SocialMedia/SocialMedia';
import styles from './Footer.module.scss';
import FooterLogo from './FooterLogo';
import { useTranslation } from 'next-i18next';
import useTranslationStatus from '@/hooks/useTranslationStatus';

const Footer = () => {
  const { t } = useTranslation('footer');
  const router = useRouter();
  const { isReady } = useTranslationStatus();
  if (router.pathname === '/lite-game') {
    return;
  }
  const footerList = [
    // { id: 1, name: t('footer:kitblock'), href: '/kitblock?type=kitblocks' },
    { id: 2, name: t('footer:avatar_maker'), href: '/avatar-maker' },
    { id: 3, name: t('footer:whitepaper'), href: 'https://whitepaper.landrocker.io/' },
    { id: 4, name: t('footer:one_pager'), href: 'https://onepager.landrocker.io/' },
    { id: 5, name: t('footer:privacy_policy'), href: '/privacy-policy' },
    { id: 6, name: t('footer:end_user_agreement'), href: '/end-user-agreement' },
    { id: 7, name: t('footer:about_us'), href: '/about-us' },
    { id: 8, name: t('footer:contact_us'), href: '/contact-us' },
    { id: 9, name: t('footer:news'), href: '/news' },
    { id: 10, name: t('footer:blogs'), href: '/blogs' },
    { id: 11, name: t('footer:press'), href: '/press' },
    { id: 12, name: t('footer:roadmap'), href: '/roadmap' },
    { id: 13, name: t('footer:faq'), href: '/faq' },
    { id: 14, name: t('footer:support'), href: 'https://support.landrocker.io/' },
    // { id: 15, name: t('footer:verify'), href: '/official-verify' },
    { id: 15, name: t('footer:referral'), href: '/command-center?tab=Referral' },
  ];

  const social: Isocial[] = [
    {
      id: 1,
      href: 'https://t.me/landrockerchat',
      icon: <TelegramSvg fill='white' width={'20px'} height={'20px'} />,
    },
    {
      id: 1,
      href: 'https://twitter.com/landrocker_io',
      icon: <TwitterSvg fill='white' width={'20px'} height={'20px'} />,
    },
    {
      id: 1,
      href: 'https://www.youtube.com/channel/UCqpggRTh2iuaV8363gD_Nfw',
      icon: <YoutubeSvg fill='white' width={'20px'} height={'20px'} />,
    },
    {
      id: 1,
      href: 'https://discord.gg/landrocker',
      icon: <DiscordSvg fill='white' width={'20px'} height={'20px'} />,
    },
    {
      id: 1,
      href: 'https://Instagram.com/landrocker.io',
      icon: <InstagramSvg fill='white' width={'20px'} height={'20px'} />,
    },
    {
      id: 1,
      href: 'https://www.facebook.com/LandRockergame/',
      icon: <FacebookSvg fill='white' width={'20px'} height={'20px'} />,
    },
    {
      id: 1,
      href: 'https://www.linkedin.com/company/landrocker/',
      icon: <LinkedIn fill='white' width={'20px'} height={'20px'} />,
    },
    // {
    //   id: 1,
    //   href: 'https://www.reddit.com/r/LandRockerMetaverse/',
    //   icon: <ReddiSvg fill='white' width={'20px'} height={'20px'} />,
    // },
    // {
    //   id: 1,
    //   href: 'https://www.twitch.tv/landrocker',
    //   icon: (
    //     <TwitchSvg fill='white' width="20px" height="20px" style={{ marginLeft: '10px' }} />
    //   ),
    // },
  ];

  const splitItems = (items: any[]) => {
    const columns = 3;
    const rows = 6;
    const result = [];
    for (let i = 0; i < columns; i++) {
      result.push(items.slice(i * rows, i * rows + rows));
    }
    return result;
  };

  const itemColumns = splitItems(footerList);
  return (
    <>
      {isReady && (
        <div
          className={`flex justify-center w-full bg-black border-t border-[#474951] ${
            router.pathname === '/game' ? '-mt-[50px]' : 'xl:mt-48 lg:mt-24'
          }`} // Remove margin-top for '/game/download'
        >
          {' '}
          <div className={styles.newFooterBox}>
            <div className='max-w-[1920px] grid grid-cols-3 grid-rows-2 lg:grid-rows-1 lg:grid-cols-5 gap-1'>
              <div className='flex flex-col col-span-3 lg:col-span-2'>
                <div className='flex flex-col relative pt-[54px] w-[330px]'>
                  <div className={styles.shadowContainer}>
                    <div className={`${styles.shadow}`}></div>
                  </div>
                  <FooterLogo />
                </div>
                <div
                  className='flex flex-col mt-2 items-end justify-center pe-2 rounded-md w-[330px] sm:w-[346px]'
                  style={{ backgroundImage: 'url(/images/footer.png)', height: 117 }}
                >
                  <div className='flex flex-col items-center'>
                    <div className='text-xl font-bold uppercase'>Play game</div>
                    <Link href='/game'>
                      <a>
                        <div className='underline text-[#00FC22] cursor-pointer'>Download</div>
                      </a>
                    </Link>
                  </div>
                </div>
                <div className={styles.socialMediaContainer}>
                  {social.map((item, index) => (
                    <Link key={index} href={item.href} target='_blank'>
                      <a target='_blank' className='bg-[#2d3038] p-2 rounded-full'>
                        {item.icon}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
              {itemColumns.map((column, colIndex) => (
                <div key={colIndex} className='col-span-1 pt-[54px]'>
                  {column.map((item, index) => (
                    <div key={index} className='text-[#848484] text-[14px] lg:text-[16px] mb-2'>
                      <Link href={item.href}>
                        <a className='hover:underline' target='_blank'>
                          {item.name}
                        </a>
                      </Link>
                    </div>
                  ))}
                </div>
              ))}
              {router.pathname === '/about-us' && (
                <ul className={styles.addressContainer}>
                  <div>
                    <div className={styles.addressTitle}>Company Name:</div>
                    <div className='font-bold mt-[2%]'>INFINITE8 FZE</div>
                  </div>

                  <div>
                    <div className={styles.addressTitle}> Address: </div>
                    <div className='font-bold mt-[2%]'>149 - Techno Hub 1 - Dubai Silicon Oasis - Dubai - UAE</div>
                  </div>

                  <div>
                    <div className={styles.addressTitle}> Registration Number:</div>
                    <div className='font-bold mt-[2%]'>DSO-FZE-2665</div>
                  </div>
                </ul>
              )}
            </div>
            <div className='text-[#B2B2B2] lg:mt-11'>© 2024 LandRocker. All rights reserved.</div>
          </div>
        </div>
      )}
    </>
  );
};
export default Footer;
