import React from 'react';
import LoadingWithLogo from '@/utils/LoadingWithLogo';
import styles from './LayoutPage.module.scss';

const LoadingPage = () => (
  // const lottieRef = useRef(null);

  <div className={styles.container}>
    <LoadingWithLogo />
  </div>
);
export default LoadingPage;
