import { isMobile, isTablet } from 'react-device-detect';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Typography } from '@/components/Atoms';
import { CustomModal } from '@/components/Molecules';
import { numberSeparator } from '@/utils/numberSeparator';
import { useStore } from '@/zustand/store/store';

const LevelModal = () => {
  const { levelUpData, isShowLevelModal, setIsShowLevelModal } = useStore();
  const router = useRouter();

  // Calculate XP progress percentage
  const xpProgress = ((levelUpData?.new_xp - levelUpData?.cap_min) / (levelUpData?.cap_max - levelUpData?.cap_min)) * 100;

  return (
    <CustomModal centered visible={isShowLevelModal} showCloseIcon={false} bodyStyle={{ height: '100%' }} fullScreen={isMobile || isTablet}>
      <div className='flex flex-col items-center justify-between h-full w-full md:w-[700px] lg:w-[700px] xl:w-[700px]'>
        <div className='flex flex-col items-center w-full'>
          <Typography size='s40ToS24' className='text-[#F5AB30] font-bold mt-12 mb-2 lg:mb-4'>
            CONGRATULATIONS
          </Typography>
          <Typography size='s18ToS14' className='text-white text-center mb-4 lg:mb-8 px-2 md:px-4'>
            Your dedication and hard work in the past have not gone unnoticed. All your efforts have been converted to our new XP system. You have
            earned:
          </Typography>
          <div className='flex items-center gap-2.5 lg:mb-4'>
            <Typography size='s36ToS22' className='text-white font-bold'>
              Level:
            </Typography>
            <Typography size='s36ToS22' className='text-[#F5AB30] font-bold'>
              {numberSeparator(levelUpData?.new_level ?? 0)}
            </Typography>
          </div>
          <div className='lg:mb-5 mb-2'>
            <Image src={levelUpData?.badge_image} width={150} height={150} alt='rank-image' />
          </div>
          <Typography size='s36ToS22' className='lg:mb-9 mb-4 text-white font-bold'>
            {levelUpData?.new_rank_name}
          </Typography>
          <div className=' flex justify-start max-w-[700px] w-9/12'>
            <div className='flex items-center gap-2.5 lg:mb-4'>
              <Typography size='s20ToS16' className='text-white font-bold'>
                XP :
              </Typography>
              <Typography size='s20ToS16' className='text-[#F5AB30] font-bold'>
                {numberSeparator(levelUpData?.new_xp ?? 0)}/{numberSeparator(levelUpData?.cap_max ?? 0)}
              </Typography>
            </div>
          </div>
          <div className='bg-[#454444] rounded-full h-2.5 lg:mb-9 mb-4 w-9/12 max-w-[700px]'>
            <div className='bg-[#F5AB30] h-2.5 rounded-full ' style={{ width: xpProgress + '%' }}></div>
          </div>
        </div>
        <div className='w-full flex justify-center mt-3 lg:mt-0'>
          <button
            onClick={() => {
              setIsShowLevelModal(false);
              router.push('/command-center');
            }}
            className='bg-[#2E2E2E] outline-none border-[2px] border-solid border-white flex justify-center items-center w-44 h-14 rounded-md mb-4'
          >
            <Typography size='s20ToS16' className='text-white font-bold'>
              OK
            </Typography>
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default LevelModal;
