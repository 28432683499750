// eslint-disable-next-line import/no-extraneous-dependencies
import { waitForTransactionReceipt, writeContract } from '@wagmi/core';
import { ContractInterface } from 'ethers';
import { parseUnits } from 'viem';
import { contractLRTAbi } from '@/utils/contractLRTAbi';
import { contractLRTToken } from '@/utils/main_contracts';
import SmartContactError from '@/utils/SmartContractError/SmartContractError';

interface IHandleApproveProps {
  amountApprove: string;
  contractAddress: string;
  successFunc: any;
  errorFunc?: (item: any) => void;
  contractToken?: string;
  contractAbi?: ContractInterface;
  parseUnitDecimal?: number;
}
export const HandleApprove = async (props: IHandleApproveProps) => {
  const {
    amountApprove,
    contractAddress,
    successFunc,
    errorFunc,
    contractAbi = contractLRTAbi,
    contractToken = contractLRTToken,
    parseUnitDecimal = 18,
  } = props;
  const clientConfig = await import('@/utils/WagmiConfig').then((mod) => mod.clientConfig);

  await writeContract(clientConfig, {
    // @ts-ignore
    address: contractToken,
    // @ts-ignore
    abi: contractAbi,
    functionName: 'approve',
    args: [contractAddress, parseUnits(`${amountApprove}`, parseUnitDecimal)],
  })
    .then((res) => {
      waitForTransactionReceipt(clientConfig, { hash: res, timeout: 120_000 })
        .then((responsive) => {
          if (responsive.status === 'success') {
            successFunc();
          }
        })
        .catch((err) => {
          if (errorFunc) {
            errorFunc(err);
          }
          SmartContactError.catchError(err);
        });
    })
    .catch((error) => {
      if (errorFunc) {
        errorFunc(error);
      }
      SmartContactError.catchError(error);
    });
};
